.template-cpt-product {
	.site-main {
		& > .row {
			padding-bottom: $pad-xsmall;
			position: relative;
			z-index: 1;
		}

		.woocommerce-breadcrumb,
		.woocommerce-breadcrumb a {
			color: $dark;
		}
	}

	.product-gallery {
		width: 100%;
		max-width: 1300px;

		@include break(tablet) {
			width: 100%;
			display: none;
		}

		&.gallery-mobile {
			display: none;
			max-width: 90vw;
			max-height: 350px;

			.slider-product-gallery {
				height: 100%;

				picture,
				video,
				.variation-image,
				img {
					height: 100%;
					max-width: 90vw;
					object-fit: cover;
				}

				.main-image-mobile {
					@include break(tablet) {
						display: none;
					}
				}

				.swiper-button-next,
				.swiper-button-prev {
					width: 35px;
					height: 35px;
					padding: 5px;
					display: flex;
					align-items: center;
					justify-content: center;
					background: rgba(255, 255, 255, 0.6);
					border-radius: 50px;

					svg {
						max-width: 24px;
						max-height: 24px;
					}
				}

				.swiper-button-prev {
					content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.8359 4.77096L7.19915 12.4077L14.8359 20.0445L15.8541 19.0262L9.23562 12.4077L15.8541 5.7892L14.8359 4.77096Z' fill='%23333333'/%3E%3C/svg%3E");
				}

				.swiper-button-next {
					content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.07621 4.77096L16.713 12.4077L9.07621 20.0445L8.05797 19.0262L14.6765 12.4077L8.05797 5.78919L9.07621 4.77096Z' fill='%23333333'/%3E%3C/svg%3E");
				}
			}

			@include break(tablet) {
				width: 100%;
				display: block;
			}
		}

		.woocommerce-product-gallery {
			img {
				width: 100%;
			}
		}

		.gallery-product {
			margin-top: 20px;
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 20px;
			grid-auto-flow: row;
			grid-template-areas:
				"a b"
				"c c"
				"d e"
				"f f";

			&.gallery-small {
				grid-template-rows: 410px;
			}

			&.gallery-medium {
				grid-template-rows: 410px 410px;
			}

			&.gallery-large {
				grid-template-rows: 410px 410px 410px auto;
			}

			.a {
				grid-area: a;
			}

			.b {
				grid-area: b;
			}

			.c {
				grid-area: c;
			}

			.d {
				grid-area: d;
			}

			.e {
				grid-area: e;
			}

			.f {
				grid-area: f;
			}

			.g {
				grid-column: span 1;
			}

			.a,
			.b,
			.c,
			.d,
			.e,
			.f {
				border-radius: 2px;
			}

			.a,
			.b,
			.d,
			.e {
				height: 410px;
			}

			video,
			img {
				height: 100%;
				width: 100%;
				max-width: 100%;
				object-fit: cover;
			}
		}
	}

	.product-description,
	.product-description-mobile {
		padding-left: 80px;
		position: -webkit-sticky;
		position: sticky;
		top: 140px;
		height: 100%;
		z-index: 2;

		@include break(tablet) {
			padding-left: 0;
			padding-top: $pad-med_mobile;
		}

		h1.product_title {
			color: $primary-orange;
			@include rem(56px);
			line-height: 100%;
			padding-top: 10px;

			@include break(tablet) {
				padding-top: 0;
				@include rem(36px);
			}
		}

		.subtitle {
			color: $primary-yellow;
			@include rem(26px);
			line-height: 140%;
			margin-bottom: 58px;

			@include break(tablet) {
				@include rem(22px);
				margin-bottom: 18px;
			}
		}

		.price {
			display: flex;
			gap: 10px;
			color: $dark;
			@include rem(36px);
			line-height: 100%;
			margin-bottom: 12px;

			@include break(tablet) {
				margin-bottom: 0;
				@include rem(26px);
			}

			del {
				@include rem(16px);
			}

			ins {
				text-decoration: none;
			}
		}

		.single_variation_wrap {
			margin-top: $pad-large_mobile;

			@include break(tablet) {
				margin-top: $pad-med_mobile;
			}

			.woocommerce-variation {
				margin-bottom: $pad-xsmall;
			}

			.woocommerce-variation-price,
			.woocommerce-variation-availability {
				display: none;
			}
		}

		.description-wrapper {
			position: relative;
			margin-bottom: $pad-med;

			.description {
				margin-top: 28px;

				@include break(tablet) {
					margin-top: 0;
				}

				&.limited.long {
					margin-bottom: 0;
					overflow: hidden;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
				}
			}
			.read-more-btn {
				padding: 0;
				padding-top: $pad-xsmall;
				color: $dark;
				@include rem(16px);
				@include font-medium;
				line-height: 150%;
				border: none;
				background: none;

				.icon {
					display: flex;
					align-items: center;

					svg {
						transition: transform 0.25s ease;
					}
				}

				&.open {
					svg {
						transform: rotate(-180deg);
					}
				}
			}
		}

		.custom-quantity {
			display: none;
		}

		form.cart {
			margin-top: $pad-med;

			.variations select {
				color: $dark;
			}

			.single_add_to_cart_button.button.alt[disabled] {
				background-color: $secondary-grey !important;
				border: 3px solid $secondary-grey !important;
				padding: 15px 36px;
				opacity: 1;
			}
		}

		.product_meta {
			color: #c2c2c2;
			text-align: right;
			@include rem(13px);
			line-height: 150%;

			.tagged_as {
				display: none;
			}
		}

		.stock {
			display: none;
		}

		.wrapper-try-in-store {
			.popup-try-in-store {
				.close {
					position: fixed;
					top: 70px;
					right: 40px;
					z-index: 10;

					@include break(tablet) {
						top: 35px;
						right: 20px;
					}
				}
				.inner {
					background-color: $white;
					display: flex;
					flex-direction: column;
					overflow: scroll;
					height: 100vh;
					top: 50%;

					@include break(tablet) {
						// height: 80vh;
					}

					.module-12-dealer-locator {
						.title-h2 {
							.name-det {
								display: inline-flex;
							}
						}

						.wrapper-texte {
							margin-bottom: 30px;
						}

						#storepoint-container {
							.storepoint-btn,
							.storepoint-popup-website-btn {
								display: block;
							}

							.storepoint-btn,
							.storepoint-popup-email {
								display: none !important;
							}
						}
					}

					.container-title-form {
						.title-h2 {
							@include rem(28px);
							@include font-bold;
						}

						.subtitle {
							color: $dark;
							@include font-light;
						}
					}

					.form-try-in-store {
						width: 100%;
						max-width: 600px;

						@include break(tablet) {
							padding: 0 5%;
						}

						.title-h3 {
							@include rem(24px);
							margin-bottom: 20px;
						}

						.dealer-card {
							display: none;
							padding: 10px 20px 10px 20px;
							margin-bottom: 20px;
							color: $white;
							border-radius: 12px;
							border: 3px solid $primary-orange;
							background-color: $primary-orange;

							&.show {
								display: block;
							}

							&-title {
								@include rem(26px);
								@include font-bold;
							}

							&-address {
								@include rem(16px);
								@include font-light;
							}

							& > .row {
								align-items: center;
								justify-content: space-between;

								@include break(tablet) {
									flex-direction: column;
									align-items: flex-start;
								}
							}

							.wrapper-button {
								@include break(tablet) {
									margin-top: 12px;
								}

								a {
									display: inline-block;
									@include rem(16px);
									padding: 6px 10px;
									line-height: 100%;
									border: 2px solid $white;
									border-radius: 50px;
									background-color: $white;
									color: $primary-orange;
									transition: all 0.25s ease;

									&:hover {
										color: $white;
										background-color: $primary-orange;
									}
								}
							}

							&.unselected {
								color: $primary-orange;
								background-color: $white;

								.wrapper-button {
									a {
										border: 2px solid $primary-orange;
										border-radius: 50px;
										background-color: $primary-orange;
										color: $white;
										transition: all 0.25s ease;

										&:hover {
											color: $primary-orange;
											background-color: $white;
										}
									}
								}
							}
						}

						form {
							display: flex;
							flex-direction: column;

							& > p {
								order: 2;
							}

							& > .wpcf7-response-output {
								order: 1;
								margin: 2em 0;
								padding: 15px;
								background-color: $white;
								border-radius: 8px;
								@include rem(16px);
							}
						}
					}
				}
			}
			.btn-try-in-store {
				width: 100%;
				margin-top: 12px;
				color: $dark;

				&:hover {
					color: $primary-orange;
					border: 3px solid $primary-orange;
				}
			}
		}

		.wrapper-compatibility {
			display: flex;
			justify-content: center;
			margin-top: $pad-med_mobile;

			.popup-compatibility {
				.inner {
					height: 80vh;
					max-width: 95vw;

					@include break(tablet) {
						height: 75vh;
						max-width: 90vw;
					}
				}

				.close {
					top: 150px;
					right: 2.5vw;
					padding: 10px 15px;
					color: $white;
					background-color: $primary-orange;
					border-radius: 50px;
					z-index: 3;

					@include break(tablet) {
						top: 140px;
						right: 10px;
					}
				}
			}

			.link {
				background: none;
				border: none;
				color: $primary-orange;
				text-transform: capitalize;
				text-align: center;
				@include rem(16px);
				line-height: 140%;
				text-decoration-line: underline;
			}
		}

		.wrapper-features {
			display: flex;
			justify-content: space-between;
			margin-top: $pad-large_mobile;
			gap: 16px;

			.single-features-item {
				display: flex;
				align-items: flex-start;
				gap: 10px;

				@include break(tablet) {
					flex-direction: column;
					align-items: center;
					text-align: center;
				}

				&-infos {
				}

				&-title {
					@include rem(16px);
					line-height: 140%;
					margin-bottom: 2px;
				}

				&-value {
					@include rem(20px);
					line-height: 140%;

					@include break(tablet) {
						@include rem(18px);
					}
				}
			}

			.icon {
				max-width: 43px;
				max-height: 28px;

				img {
					width: 100%;
					height: 100%;
					max-width: 43px;
					max-height: 28px;
				}
			}
		}
	}

	.product-description-mobile {
		@include break(tablet) {
			padding-top: 0;
			margin-bottom: 18px;
		}
	}

	.content-area {
		.woocommerce-variation-add-to-cart {
			@include break(tablet) {
				height: 54px;
			}
		}
		button.button.single_add_to_cart_button {
			background-color: $primary-orange;
			display: inline-flex;
			padding: 15px 40px;
			justify-content: center;
			align-items: center;
			width: 100%;
			gap: 10px;
			@include font-bold;
			@include rem(20px);
			line-height: 150%;
			color: $white;
			border: 3px solid $primary-orange;
			border-radius: 40px;
			transition: opacity 0.25s ease-out transform 0.5s ease-out;
			-webkit-transition: opacity 0.25s ease-out,
				-webkit-transform 0.5s ease-out;

			@include break(tablet) {
				@include rem(18px);
				padding: 15px 36px;
				left: 50%;
				transform: translate(-50%, 0);
				-webkit-transform: translate(-50%, 0);

				&.sticky-prep {
					position: relative;
					width: 90%;
					opacity: 0;
					transform: translate(-50%, 100px);
					-webkit-transform: translate(-50%, 100px);
				}

				&.sticky-visible {
					position: fixed;
					left: 50%;
					bottom: 30px;
					width: 90%;
					border: 1px solid $white;
					background-color: $primary-orange;
					opacity: 1;
					transform: translate(-50%, 0);
					-webkit-transform: translate(-50%, 0);
				}
			}

			&:hover {
				color: $primary-orange;
				background-color: $white;
			}

			&.disabled,
			&.disabled:hover {
				background-color: $secondary-grey;
				border-color: $secondary-grey;
				opacity: 0.8;
			}
		}
	}

	&.template-single-bikes,
	&.template-single-bikes-de,
	&.template-single-velos {
		.product-description {
			.product_meta {
				position: relative;

				.sku_wrapper {
					position: absolute;
					top: 5px;
					right: 0;
				}
			}

			.available-colours-label {
				@include rem(20px);
				line-height: 140%;
				margin-bottom: 5px;

				@include break(tablet) {
					@include rem(18px);
				}
			}

			.variations {
				tr {
					&:last-child {
						display: block;
						margin-top: 10px;
					}
				}
			}

			.cfvsw-swatches-option {
				min-width: 38px !important;
				min-height: 38px !important;
				max-width: 38px !important;
				max-height: 38px !important;
				padding: 2px;

				&.cfvsw-label-option {
					min-width: initial !important;
					max-width: initial !important;
					display: inline-flex;
					padding: 8px 16px;
					justify-content: center;
					align-items: center;
					gap: 10px;
					@include font-bold;
					@include rem(16px);
					line-height: 150%;
					color: #4d2391;
					border: 3px solid #4d2391;
					background-color: $white;
					border-radius: 20px !important;
					cursor: pointer;
					transition: all 0.25s ease-out;

					&:hover {
						background-color: #4d2391;
						border: 3px solid #4d2391;
						color: #fecb00;
					}

					&.cfvsw-selected-swatch {
						background-color: #4d2391;
						border: 3px solid #4d2391 !important;
						color: #fecb00;
					}
				}
			}
		}
	}

	&.template-single-add-ons,
	&.template-single-carte-cadeau,
	&.template-single-add-ons-fr {
		.product-gallery {
			@include break(tablet) {
				display: block;
			}
		}

		.product-description {
			.description {
				margin-top: $pad-small;
				margin-bottom: 175px;

				&.variations-product {
					margin-bottom: 0;
				}
			}

			.product_meta {
				margin-bottom: $pad-med;
			}

			.cfvsw-swatches-option {
				min-width: 38px !important;
				min-height: 38px !important;
				max-width: 38px !important;
				max-height: 38px !important;
				padding: 2px;

				&.cfvsw-label-option {
					min-width: initial !important;
					max-width: initial !important;
					display: inline-flex;
					padding: 8px 16px;
					justify-content: center;
					align-items: center;
					gap: 10px;
					@include font-bold;
					@include rem(16px);
					line-height: 150%;
					color: #4d2391;
					border: 3px solid #4d2391;
					background-color: $white;
					border-radius: 20px !important;
					cursor: pointer;
					transition: all 0.25s ease-out;

					&:hover {
						background-color: #4d2391;
						border: 3px solid #4d2391;
						color: #fecb00;
					}

					&.cfvsw-selected-swatch {
						background-color: #4d2391;
						border: 3px solid #4d2391 !important;
						color: #fecb00;
					}

					.cfvsw-swatch-inner {
						line-height: 100%;
					}
				}
			}

			.stock {
				&.out-of-stock {
					display: block;
					background-color: $secondary-grey;
					display: inline-flex;
					padding: 15px 40px;
					justify-content: center;
					align-items: center;
					width: 100%;
					gap: 10px;
					@include font-bold;
					@include rem(20px);
					line-height: 150%;
					color: $white;
					border: 3px solid $secondary-grey;
					border-radius: 40px;
					opacity: 0.8;
					transition: opacity 0.25s ease-out transform 0.5s ease-out;
					-webkit-transition: opacity 0.25s ease-out,
						-webkit-transform 0.5s ease-out;

					@include break(tablet) {
						@include rem(18px);
						padding: 15px 36px;
						left: 50%;
						transform: translate(-50%, 0);
						-webkit-transform: translate(-50%, 0);
					}
				}
			}
		}

		.wrapper-features {
			display: none;
		}
	}

	&.template-single-spare-parts {
		.product-gallery {
			@include break(tablet) {
				display: block;
			}
		}

		.product_title {
			margin-bottom: 12px;
		}

		.wrapper-features,
		.subtitle {
			display: none;
		}
	}

	.module-21-featured-products {
		&.type-hotspots {
			@include break(tablet) {
				display: none;
			}
		}
	}
}

// Lightbox
.mfp-container {
	figure {
		padding: 40px 0 40px;

		.mfp-img {
			background-color: $white;
			padding: 0;
		}
	}
}
