.module-21-featured-products {
	padding: 120px 0;
	position: relative;
	background-color: #f8f6f5;

	@include break(tablet) {
		padding: 60px 0;
	}

	.row {
		flex-direction: column;
	}

	.bg {
		position: absolute;
		bottom: -7px;
		right: 0;
		display: block;

		@include break(tablet) {
			z-index: 0;
			right: -50%;
			transform: translateX(-50%);
		}
	}

	.title-h2 {
		color: $primary-orange;
		margin-bottom: $pad-section;

		@include break(tablet) {
			margin-bottom: $pad-med_mobile;
		}
	}

	&.type {
		&-recommended {
		}

		&-hotspots {
			background-color: $primary-yellow;

			.title-h2 {
				margin-bottom: $pad-med;

				@include break(tablet) {
					margin-bottom: $pad-med_mobile;
				}
			}

			.bg {
				top: 50%;
				left: -300%;
				bottom: unset;
				transform: translate(-50%, -50%);
				z-index: -1;

				@include break(tablet) {
					left: -50%;
				}

				svg {
					width: 200%;
				}
			}

			.slider-other-products {
				padding-bottom: $pad-large_mobile;

				.swiper-pagination {
					bottom: 0;
					text-align: center;
				}
			}

			.wrapper-media {
				border-radius: 4px;
				padding-right: 0;
				width: 100%;
				height: 729px;
				max-width: 978px;
				max-height: 729px;

				@include break(tablet) {
					width: 100%;
					height: 256px;
				}

				img {
					width: 100%;
					object-fit: contain;
				}
			}

			.wrapper-texte {
				padding-left: 5%;
				text-align: center;
				max-width: 30vw;

				display: flex;
				flex-direction: column;
				justify-content: center;

				@include break(tablet) {
					padding-left: 0;
					max-width: 90vw;
				}

				.card-other-product {
					border-radius: 40px;
					background-color: $white;
					overflow: hidden;

					@include break(desktop-only) {
						&:hover {
							.product-excerpt {
								transform: translateY(-44px);
							}

							.price-featured {
								transform: translateY(-44px);
							}
						}
					}

					.thumbnail {
						display: flex;
						align-items: center;
						justify-content: center;
						height: fit-content;
						max-height: 325px;

						@include break(tablet) {
							height: 255px;
						}

						img {
							width: 100%;
							height: 100%;
							object-fit: contain;
						}
					}

					.content {
						padding: 28px 40px;
					}

					.title-h4 {
						@include rem(26px);
						@include font-black;
						line-height: 150%;
					}

					.description-wrapper {
						position: relative;
						height: 44px;
						overflow: hidden;
						margin-bottom: 12px;
						transition: height 0.3s ease;

						@include break(tablet) {
							height: 100%;
							max-height: 90px;
						}
					}

					.product-excerpt,
					.price-featured {
						transform: translateY(0);
						transition: transform 0.4s ease;
					}

					.product-excerpt {
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						@include rem(18px);
						line-height: 140%;
						margin: 0 auto;

						@include break(desktop-only) {
							height: 44px;
							max-width: 320px;
						}

						@include break(tablet) {
							margin-bottom: 8px;
						}
					}

					.price-featured {
						@include font-bold;
						@include rem(20px);
						color: $primary-yellow;
						overflow: hidden;
						text-overflow: ellipsis;
						line-height: 140%;
						margin-bottom: 18px;

						ins {
							text-decoration: none;
						}
					}

					.btn-primary {
						padding: 10px 28px;
					}
				}
			}
		}
	}

	.recommended,
	.hotspots {
		display: flex;
		position: relative;
		z-index: 1;

		@include break(tablet) {
			flex-direction: column;
			gap: 20px;
		}
	}

	.hotspots {
		@include break(tablet) {
			gap: 40px;
		}

		.wrapper-media {
			position: relative;
			overflow: initial;

			picture {
				display: flex;
				border-radius: 4px;
				overflow: hidden;
			}
		}

		.wrapper-hotspots {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
			z-index: 2;

			.hotspots-item {
				.hotspots-item-btn {
					position: absolute;
					border: none;
					background: rgba($secondary-grey, 0.6);
					backdrop-filter: blur(3px);
					width: 41px;
					height: 41px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 50px;
					transition: transform 0.25s ease-out;

					.icon {
						display: flex;
						align-items: center;
						justify-content: center;
						transition: transform 0.25s ease-out;
					}

					&:hover {
						transform: scale(0.8);

						.icon {
							transform: rotate(90deg);
						}
					}
				}
			}

			.hotspots-item-popup {
				display: flex;
				gap: 20px;
				width: 345px;
				min-height: 123px;
				padding: 10px;
				padding-right: 15px;
				position: absolute;
				bottom: 22px;
				right: 22px;
				background-color: $white;
				box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
				border-radius: 8px;
				z-index: 5;
				transform: translateX(100px);
				opacity: 0;
				visibility: hidden;
				transition: transform 0.25s ease-out, opacity 0.25s ease-out,
					visibility 0.25s ease-out;

				@include break(tablet) {
					padding-right: 10px;
					bottom: 0;
					right: 0;
				}

				&.open {
					transform: translateX(0);
					opacity: 1;
					visibility: visible;
				}

				.wrapper-media {
					min-width: 103px;
					min-height: 103px;
					max-width: 103px;
					max-height: 103px;
					border-radius: 12px;
					overflow: hidden;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				.wrapper-text {
					height: fit-content;
				}

				.hotspots-item-title {
					@include font-black;
					@include rem(20px);
					line-height: 140%;
				}

				.hotspots-item-description {
					@include rem(13px);
					line-height: 140%;
					margin-bottom: 8px;
				}

				.btn-primary {
					padding: 8px 16px;
					@include rem(16px);
					line-height: 100%;
				}

				.hotspots-close-btn {
					position: absolute;
					top: 5px;
					right: 5px;
					background: none;
					border: none;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
	}

	.wrapper-media {
		border-radius: 60px;
		padding-right: 80px;
		overflow: hidden;

		@include break(tablet) {
			padding-right: 0;
			border-radius: 40px;
		}

		> * {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.wrapper-texte {
		.recommended-product-description {
			margin-bottom: 52px;

			@include break(tablet) {
				margin-bottom: 60px;
			}

			.title-h3 {
				@include font-black;
				color: $primary-orange;
				margin-bottom: 6px;

				@include break(tablet) {
					margin-bottom: 10px;
				}
			}

			.subtitle {
				color: $primary-yellow;
				@include rem(20px);
				margin-bottom: 12px;
				line-height: 140%;
			}

			.description {
				@include rem(16px);
				margin-bottom: 18px;
			}
		}

		.wrapper-other-products {
			display: flex;
			flex-direction: column;
			gap: 12px;

			.more-content {
				color: $primary-orange;
				@include font-bold;
				line-height: 140%;
				margin-bottom: 2px;

				@include break(tablet) {
					margin-bottom: 18px;
				}
			}

			.card-other-product {
				padding: 10px;
				border-radius: 18px;
				display: flex;
				align-items: center;
				gap: 20px;
				background-color: $white;
				max-width: 340px;

				@include break(tablet) {
					max-width: 100%;
				}

				.thumbnail {
					border-radius: 12px;
					display: flex;
					width: 100%;
					height: 100%;
					max-width: 103px;
					overflow: hidden;

					> * {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				.content {
					.title-h4 {
						@include rem(20px);
						@include font-black;
						line-height: 140%;
					}

					.description {
						@include rem(13px);
						margin-bottom: 8px;
					}

					.btn-primary {
						padding: 8px 16px;
						@include rem(16px);
						line-height: 100%;
					}
				}
			}
		}

		.text-title {
			color: $primary-orange;
			@include rem(36px);
			@include font-black;
			line-height: 100%;
			margin-bottom: 28px;

			@include break(tablet) {
				@include rem(26px);
			}
		}
	}
}
