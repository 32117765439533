.module-03-selling-points {
    position: relative;
    padding: 60px 0;

    @include break(tablet) {
        padding: 35px 0;
    }

    .wrapper-texte {
        margin-bottom: $pad-med;

        @include break(tablet) {
            margin-bottom: $pad-med_mobile;
        }

        .title-h2 {
            color: $primary-orange;
            margin-bottom: 12px;
        }

        .description {
            @include rem(20px);

            @include break(tablet) {
                @include rem(18px);
            }
        }
    }

    .wrapper-boxes {
        display: grid;
        gap: 25px;
        grid-template-columns: repeat(3, 1fr);
        
        @include break(tablet) {
            gap: 12px;
            grid-template-columns: 1fr;
        }

        &.full-boxes {
            grid-template-columns: repeat(4, 1fr);

            @include break(tablet) {
                grid-template-columns: 1fr;
            }
        }

        .box {
            color: $white;
            padding: 26px;
            border-radius: 4px;

            .icon {
                margin-bottom: $pad-small;
                max-width: 100px;

                @include break(tablet) {
                    margin-bottom: 22px;
                }
            }

            .title-h3 {
                @include rem(26px);
                margin-bottom: 6px;
                
                @include break(tablet) {
                    @include rem(22px);
                    margin-bottom: 11px;
                }
            }

            .text {
                @include rem(16px);
            }

            &.bg-grey {
                background-color: $secondary-grey;
            }

            &.bg-pink {
                background-color: $secondary-pink;
            }

            &.bg-purple {
                background-color: $secondary-purple;
            }

            &.bg-dark-purple {
                background-color: $secondary-dark-purple;
            }

            &.bg-plum {
                background-color: $secondary-plum;
            }

            &.bg-wine {
                background-color: $secondary-wine;
            }

            &.bg-red {
                background-color: $secondary-red;
            }

            &.bg-neon-orange {
                background-color: $secondary-neon-orange;
            }

            &.bg-lemon {
                background-color: $secondary-lemon;
            }

            &.bg-pastel-green {
                background-color: $secondary-pastel-green;
            }

            &.bg-green-lemon {
                background-color: $secondary-green-lemon;
            }

            &.bg-green {
                background-color: $secondary-green;
            }

            &.bg-dark-green {
                background-color: $secondary-dark-green;
            }

            &.bg-cyan {
                background-color: $secondary-cyan;
            }

            &.bg-blue {
                background-color: $secondary-blue;
            }
        }
    }

}