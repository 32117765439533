.swiper {
	.swiper-pagination {
		text-align: left;

		.swiper-pagination-bullet {
			background: $primary-orange;
			width: 10px;
			height: 10px;
			opacity: 1;
			margin: 0 6px !important;
			transition: all 0.25s ease-out;

			&-active {
				width: 32px;
				border-radius: 20px;
			}
		}
	}

	&.slider-blog-posts {
		@include break(tablet) {
			max-width: 90vw;
			padding-bottom: 88px;
		}

		.swiper-wrapper {
			@include break(desktop-and-tablet) {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				gap: 25px;
			}

			@include break(mobile) {
				max-width: 90vw;
			}

			.swiper-slide {
				border-radius: 60px;
				overflow: hidden;

				.btn-link {
					position: relative;
					cursor: pointer;
					pointer-events: none;
					width: fit-content;
					border: none;

					&::after {
						content: "";
						position: absolute;
						bottom: 5px;
						left: 0;
						display: block;
						width: 0;
						height: 2px;
						background-color: $dark;
						pointer-events: none;
						transition: width 0.25s ease-out;
					}
				}

				&:hover {
					.wrapper-text .btn {
						&::after {
							width: 100%;
						}
					}
				}

				.wrapper-media {
					@include break(mobile) {
						max-height: 250px;
					}

					img {
						object-fit: cover;
						width: 100%;
						height: 100%;
					}
				}

				.wrapper-text {
					position: relative;
					padding: 30px 40px;
					height: 100%;
				}

				.category {
					position: absolute;
					top: -25px;
					left: 20px;
					padding: 10px 20px;
					@include font-bold;
					border-radius: 50px;
					text-transform: capitalize;
				}

				&.cat-guides,
				&.cat-how {
					.wrapper-text,
					.category {
						background-color: $secondary-pink;
					}
				}

				&.cat-products,
				&.cat-produits,
				&.cat-produkte {
					.wrapper-text,
					.category {
						background-color: $primary-yellow;
					}
				}

				&.cat-community,
				&.cat-communauté {
					.wrapper-text,
					.category {
						background-color: $secondary-pastel-green;
					}
				}
			}
		}

		.swiper-pagination {
			@include break(tablet) {
				bottom: 50px;
				text-align: center;
			}
		}
	}

	&.slider-gallery {
		max-width: 200%;
		max-height: 576px;
		height: 100%;
		position: absolute;
		left: -50%;
		top: 0;

		.swiper-slide {
			opacity: 0.5;
			padding-bottom: 26px;

			&-active {
				opacity: 1;
			}

			legend {
				color: #999797;
				@include rem(13px);
				margin-top: 8px;
			}
		}
	}
}
