.module-17-tabs-bike {
	position: relative;
	padding: 60px 0;
	z-index: 1;

	@include break(tablet) {
		padding: 35px 0;
	}

	.title-h2 {
		color: $primary-orange;
	}

	.btn-primary {
		width: fit-content;
		margin: 0 auto;
	}

	.container-tabs-bike {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.tab_wrapper {
		padding-top: 56px;

		@include break(tablet) {
			padding-top: $pad-med_mobile;
		}

		.tab-name_container {
			@include break(tablet) {
				overflow-x: scroll;
				-ms-overflow-style: none;
				overflow: -moz-scrollbars-none;
				scrollbar-width: none;

				&::-webkit-scrollbar {
					display: none;
				}
			}

			&-inner {
				display: flex;
				margin-bottom: $pad-med;

				@include break(tablet) {
					margin-bottom: 28px;
				}

				.tab-name {
					flex: 1;
					padding: 10px;
					text-align: center;
					@include font-black;
					@include rem(26px);
					line-height: 140%;
					color: #c2c2c2;
					background-color: transparent;
					border: none;
					border-bottom: 3px solid $primary-orange;
					cursor: pointer;
					transition: background-color 0.3s ease;

					@include break(tablet) {
						@include rem(22px);
						padding: 8px 15px;
					}

					&.active {
						border-radius: 20px 20px 0px 0px;
						background-color: $primary-orange;
						color: $primary-yellow;
					}
				}
			}
		}

		.tab_container {
			.tab-content {
				display: none;

				&.active {
					display: block;
				}

				.description {
					display: flex;
					@include col-width(8);
					margin-bottom: $pad-med;
					@include rem(26px);
					max-width: 70%;
					padding-left: $pad-med;

					@include break(tablet) {
						@include rem(20px);
						margin-bottom: 28px;
						max-width: 100%;
						padding-left: 0;
					}
				}
			}

			.products-list {
				margin-bottom: 80px;

				.swiper-wrapper {
					@include break(desktop-only) {
						display: grid;
						grid-template-columns: repeat(3, 1fr);
						gap: 25px;
					}

					@include break(tablet) {
						padding-bottom: 30px;
					}
				}

				.product-item {
					border-radius: 60px;
					background-color: $secondary-light-grey;
					margin: 0;
					padding-bottom: $pad-med_mobile;
					overflow: hidden;

					@include break(tablet) {
						border-radius: 40px;
					}

					@include break(desktop-only) {
						&:hover {
							.product-excerpt {
								transform: translateY(-44px);
							}

							.product-price {
								transform: translateY(-44px);
							}

							.wrapper-hover {
								opacity: 1;
							}
						}
					}

					.wrapper-hover {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						max-height: 300px;
						background-color: $secondary-light-grey;
						opacity: 0;
						transition: all 0.25s ease-out;

						@include break(tablet) {
							display: none;
						}

						.hover-img {
							width: 100%;
							object-fit: cover;
							margin-bottom: 0;
						}

						.btn {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
						}
					}

					img {
						height: 338px;
						max-height: 338px;
						object-fit: contain;

						@include break(tablet) {
							height: 242px;
							max-height: 242px;
						}
					}

					.shadow {
						display: block;
						width: 60%;
						height: 20px;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						border-radius: 50%;
						background-color: $dark;
						-webkit-filter: blur(8px);
						-moz-filter: blur(8px);
						-o-filter: blur(8px);
						-ms-filter: blur(8px);
						filter: blur(8px);
						opacity: 0.3;

						@include break(tablet) {
							top: 41%;
						}
					}

					.wrapper-content {
						position: relative;
					}

					.title-h3 {
						padding: 26px 0 0 $pad-small;
						@include rem(26px);
						line-height: 140%;
						margin-bottom: 3px;

						@include break(tablet) {
							@include rem(22px);
							margin-bottom: 8px;
						}
					}

					.product-excerpt {
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						@include rem(16px);
						padding-left: $pad-small;
						line-height: 140%;
						max-width: 320px;

						@include break(desktop-only) {
							height: 44px;
						}

						@include break(tablet) {
							margin-bottom: 8px;
						}
					}

					.product-price {
						@include font-bold;
						@include rem(20px);
						color: $primary-yellow;
						overflow: hidden;
						text-overflow: ellipsis;
						line-height: 140%;
						padding-left: $pad-small;
						margin-bottom: 18px;

						ins {
							text-decoration: none;
						}
					}

					.description-wrapper {
						position: relative;
						height: 44px;
						overflow: hidden;
						transition: height 0.3s ease;

						@include break(tablet) {
							height: 100%;
							max-height: 90px;
						}
					}

					.product-variations {
						position: absolute;
						top: 26px;
						right: 32px;
						display: flex;
						gap: 10px;

						.color-circle {
							display: block;
							width: 25px;
							height: 25px;
							border-radius: 50%;

							@include break(tablet) {
								width: 20px;
								height: 20px;
							}

							&.bg-black {
								background-color: #000;
							}
	
							&.bg-blue-kombi {
								background-color: #1ab6e6;
							}
	
							&.bg-blue {
								background-color: #abc9c9;
							}
	
							&.bg-gold {
								background-color: #fdcb03;
							}
	
							&.bg-green {
								background-color: #a8e000;
							}
	
							&.bg-grey {
								background-color: #5d686a;
							}
	
							&.bg-hello-yellow {
								background-color: #fdba01;
							}
	
							&.bg-orange-supercargo {
								background-color: #fe9602;
							}
	
							&.bg-orange {
								background-color: #fd7a28;
							}
	
							&.bg-silver {
								background-color: #e3d9d1;
							}
	
							&.bg-tan {
								background-color: #c9aa7e;
							}
	
							&.bg-true-blue {
								background-color: #14577d;
							}
	
							&.bg-very-berry {
								background-color: #77165c;
							}
	
							&.bg-yellow {
								background-color: #fdd100;
							}
						}
					}

					.product-excerpt,
					.product-price {
						transform: translateY(0);
						transition: transform 0.4s ease;
					}
				}

				.swiper-pagination {
					bottom: 0;
					text-align: center;
				}
			}
		}
	}
}
