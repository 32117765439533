footer {
	width: 100%;

	.pre-footer {
		padding: 22px 50px;
		background-color: $primary-yellow;

		@include break(tablet) {
			padding: 35px 0;
		}

		.row {
			justify-content: space-between;

			@include break(tablet) {
				flex-direction: column;
				gap: 18px;
			}
		}

		.features {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;

			@include break(tablet) {
				flex-direction: row;
				gap: 14px;
				text-align: left;
			}

			.icon {
				max-width: 50px;
			}

			.text {
				@include rem(16px);
				max-width: 70%;

				@include break(tablet) {
					max-width: 100%;
				}
			}
		}
	}

	.footer-content {
		color: $white;
		background-color: $primary-orange;

		@include break(tablet) {
			display: none;
		}

		.title-h5 {
			margin-top: 0;
			margin-bottom: $pad-xsmall;
		}

		.btn-language {
			@include rem(14px);
			display: inline-flex;
			padding: 4px 10px;
			justify-content: center;
			align-items: center;
			gap: 10px;
			border-radius: 30px;
			border: 1px solid $white;

			&::before {
				content: '';
				opacity: 0;

				&:hover {
					opacity: 0;
				}
			}

			.wpml-ls-legacy-dropdown {
				width: 40px;
				z-index: 0;

				a {
					color: $white;
					border: none;
					background-color: transparent;
					padding: 0;
					@include rem(14px);
					line-height: 150%;
					text-align: left;

					&.wpml-ls-item-toggle {
						&:after {
							content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.8781 6.76923L8.46875 12.1786L3.05938 6.76923L3.78063 6.04798L8.46875 10.7361L13.1569 6.04798L13.8781 6.76923Z' fill='white'/%3E%3C/svg%3E");
							right: 0;
							top: 0;
							border: 0;
						}
					}
				}

				.wpml-ls-sub-menu {
					display: none;
				}
			}
		}

		.footer-content-left {
			padding: $pad-large $pad-large 22px 0;
			border-right: 1px solid $white;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.row-columns {
				padding-bottom: 22px;
				justify-content: space-between;

				.menu {
					display: flex;
					flex-direction: column;
					gap: 12px;
				}
			}

			.row-address {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;

				.address-title {
					@include rem(16px);
					margin-bottom: 15px;
				}

				.address-1,
				.address-2 {
					@include rem(13px);
				}
			}

			.wrapper-address {
				display: flex;
				justify-content: space-between;
				gap: 100px;
			}
		}

		.footer-content-right {
			padding: $pad-large 0 35px $pad-large;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.description {
				margin-bottom: 1rem;
			}

			.news-form {
				input[type="submit"] {
					margin-top: 12px;
					margin-bottom: 112px;
					padding: 8px 28px;
					font-size: 17px;
					line-height: 150%;
					color: $primary-orange;
					border: 3px solid $primary-orange;
					background-color: $white;
					
					&:hover,
					&:focus {
						color: $white;
						border: 3px solid $white;
						background-color: $primary-orange;
					}
				}
			}

			.social-list {
				display: flex;
				gap: 26px;
			}
		}

		.footer-content-bottom {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 35px 0;
			border-top: 1px solid $white;

			> p,
			.copyrights {
				@include rem(13px);
			}

			.copyrights {
				display: flex;
				gap: 10px;

				@include break(tablet) { 
					flex-direction: column;
				}

				a {
					color: $white;
				}
			}
		}
	}

	.footer-mobile {
		display: none;

		@include break(tablet) {
			display: block;

			.title-h5 {
				margin-bottom: 16px;
			}

			.footer-content-top {
				padding-top: 50px;
				display: flex;
				flex-direction: column;
				align-items: center;

				.logo {
					margin-bottom: $pad-large_mobile;
				}
			}

			.footer-content-left {
				padding: 50px 0;
				border-right: none;
				border-bottom: 1px solid $white;

				.row-columns {
					row-gap: 30px;
					padding-bottom: 0;

					.menu {
						gap: 15px;

						li {
							@include rem(16px);
						}
					}
				}

				.col-3 {
					flex: 50%;

					&:first-child {
						padding-right: 10px;
					}
				}
			}

			.footer-content-right {
				padding: 50px 0 22px 0;

				.news-form {
					input[type="submit"] {
						margin-bottom: 50px;
					}
				}

				.social-list {
					gap: 30px;
					margin-bottom: 50px;
				}

				.row-address {
					.wrapper-address {
						display: flex;
						justify-content: space-between;
					}

					.address-title {
						@include rem(16px);
						margin-bottom: 15px;
					}

					.address-1,
					.address-2 {
						@include rem(13px);
						width: 50%;
					}
				}
			}

			.footer-content-bottom {
				padding: 22px 0 75px 0;
				justify-content: center;
			}
		}
	}
}
