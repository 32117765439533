.woocommerce {
	#customer_login {
		.login-register-tabs {
			display: flex;
			margin-bottom: 20px;

			button {
				flex: 1;
				padding: 10px;
				text-align: center;
				@include font-bold;
				@include rem(20px);
				line-height: 140%;
				color: #c2c2c2;
				background-color: transparent;
				border: none;
				border-bottom: 3px solid $primary-orange;
				cursor: pointer;
				transition: background-color 0.3s ease;

				&.active {
					border-radius: 20px 20px 0px 0px;
					background-color: $primary-orange;
					color: $primary-yellow;
				}
			}
		}

		.form-content {
			display: none;

			&.active {
				display: block;
			}
		}

		#login-form {
			.woocommerce-form-login {
				padding: 0;
				border: none;
			}

			.form-password {
				position: relative;

				.lost_password {
					position: absolute;
					top: 10px;
					right: 3px;
					@include rem(13px);
					line-height: 140%;
					text-decoration-line: underline;
				}
			}

			.row-login {
				display: flex;
				flex-direction: column;

				button[type="submit"] {
					order: 1;
					margin-top: 18px;
					margin-bottom: 18px;
				}

				.woocommerce-form-login__rememberme {
					order: 2;
					display: flex;
					align-items: center;
				}
			}

			input,
			textarea,
			select {
				margin-top: 8px;
				appearance: none;
				border-radius: 12px;
				background: rgba(255, 255, 255, 0.11);
				color: $dark;
				width: 100%;
				padding: 15px 18px;
				margin-bottom: 20px;
				transition: all 0.4s;
				border: 1px solid $secondary-grey;

				&:focus {
					outline: none;
					border: 1px solid $primary-orange;
					box-shadow: 0px 0px 0px 4px rgba(255, 88, 0, 0.1);
				}

				&::placeholder {
					color: #c2c2c2;
				}

				&[type="checkbox"] {
					position: relative;
					width: 16px;
					height: 16px;
					min-width: 16px;
					min-height: 16px;
					margin-top: 0px;
					margin-bottom: 0;
					margin-right: 8px;
					padding: 0;
					border-radius: 4px;
					border: 1px solid $secondary-grey;

					@include break(tablet) {
						padding-right: 14px;
					}

					&::before {
						content: "";
						display: flex;
						width: 10px;
						height: 10px;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						background-color: $primary-orange;
						border-radius: 2px;
						opacity: 0;
						transition: all 0.25s ease-out;
					}

					&:checked {
						&::before {
							opacity: 1;
						}
					}
				}
			}
		}

		#register-form {
			.woocommerce-form-register {
				padding: 0;
				border: none;

				button[type="submit"] {
					display: inline-flex;
					justify-content: center;
					align-items: center;
					gap: 10px;
					width: 100%;
					padding: 10px 28px;
					margin-top: 20px;
					margin-bottom: 18px;
					@include font-bold;
					@include rem(20px);
					line-height: 150%;
					color: $white;
					background-color: $primary-orange;
					border: 3px solid $primary-orange;
					border-radius: 40px;

					@include break(tablet) {
						@include rem(18px);
						padding: 15px 36px;
					}

					&:hover {
						color: $primary-orange;
						background-color: $white;
					}
				}

				.woocommerce-privacy-policy-text {
					padding-top: 6px;
					@include rem(15px);
				}
			}

			input,
			textarea,
			select {
				margin-top: 8px;
				appearance: none;
				border-radius: 12px;
				background: rgba(255, 255, 255, 0.11);
				color: $dark;
				width: 100%;
				padding: 15px 18px;
				margin-bottom: 20px;
				transition: all 0.4s;
				border: 1px solid $secondary-grey;

				&:focus {
					outline: none;
					border: 1px solid $primary-orange;
					box-shadow: 0px 0px 0px 4px rgba(255, 88, 0, 0.1);
				}

				&::placeholder {
					color: #c2c2c2;
				}
			}
		}
	}

	.woocommerce-ResetPassword {
		p {
			width: 100%;
		}

		.btn-primary {
			display: inline-flex;
			padding: 15px 40px;
			justify-content: center;
			align-items: center;
			gap: 10px;
			@include font-bold;
			@include rem(20px);
			line-height: 150%;
			background-color: $primary-orange;
			color: $white;
			border-radius: 40px;
			margin-top: calc(48px - 12px);

			@include break(tablet) {
				@include rem(18px);
				padding: 15px 36px;
			}

			&:hover {
				color: $primary-orange;
				background-color: $white;
			}
		}
	}
}
