.module-01-hero {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	color: $white;

	&.layout {
		&-tallImg,
		&-video,
		&-slider,
		&-video {
			min-height: 100vh;
			max-height: 100vh;

			@include break(mobile) {
				align-items: flex-start;

				.content {
					padding-top: 195px;
				}
			}
		}

		&-noImg {
			padding: 190px 0 $pad-section 0;
			color: $dark;

			@include break(tablet) {
				padding: 150px 0 $pad-large_mobile 0;
			}

			.filter {
				display: none;
			}
		}

		&-shortImg {
			min-height: 628px;
			max-height: 628px;
			padding: $pad-section 0;
			align-items: flex-end;
		}

		&-video {
			min-height: 90vh;
			max-height: 90vh;
		}
	}

	.bg {
		position: absolute;
		top: 50%;
		left: 50%;
		height: 100%;
		width: 100%;
		transform: translate(-50%, -50%);
		z-index: 0;

		img,
		video {
			object-fit: cover;
			height: 100%;
			width: 100%;
		}

		&.video-container {
			overflow: hidden;
			
			.fallback-image {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				transition: opacity 0.5s ease;
				opacity: 1;
			}
			
			.video-element {
				z-index: 2;
				opacity: 0;
				transition: opacity 0.5s ease;
			}
		}
	}

	&.vimeo {
		overflow: hidden;
		
		.bg {
			position: absolute;
			height: 100vh;
			width: 100vw;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			overflow: hidden;
			transform: initial;
		
			iframe {
			  position: absolute;
			  top: 50%;
			  left: 50%;
			  transform: translate(-50%, -50%);
		
			  @media (min-aspect-ratio: 16/9) {
				height: 56.25vw;
				width: 100vw;
			  }
		
			  @media (max-aspect-ratio: 16/9) {
				width: 177.78vh;
				height: 100vh;
			  }
			}
		  }
	}

	

	.filter {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		height: 100%;
		width: 100%;
		background: linear-gradient(
			270deg,
			rgba(0, 0, 0, 0) 60%,
			rgba(0, 0, 0, 0.2) 100%
		);
		z-index: 1;
	}

	.content {
		position: relative;
		display: flex;
		flex-direction: column;
		z-index: 2;

		> * {
			max-width: 40%;

			@include break(tablet) {
				max-width: 100%;
			}
		}

		.wrapper-title {
			.subtitle {
				margin-top: 12px;
			}
		}

		.wrapper-content {
			.description {
				margin-top: 12px;
				@include font-medium;
				@include rem(20px);
				line-height: 140%;

				@include break(tablet) {
					@include rem(18px);
				}
			}

			.wrapper-cta {
				margin-top: 40px;
				display: flex;
				align-items: center;
				gap: 12px;

				@include break(mobile) {
					flex-direction: column;
					align-items: flex-start;
				}
			}
		}
	}

	&.text-white {
		.btn-primary,
		.btn-secondary {
			color: $white;
		}
	}

	&.text-orange {
		.btn-primary,
		.btn-secondary {
			color: $primary-orange;
		}
	}

	&.bg-white {
		.btn-primary {
			background-color: $white;

			&:hover {
				color: $white;
				background-color: $primary-orange;
			}
		}

		.btn-secondary {
			border: 3px solid $white;

			&:hover {
				border: 3px solid $primary-orange;
			}
		}
	}

	&.bg-orange {
		.btn-primary {
			background-color: $primary-orange;

			&:hover {
				color: $primary-orange;
				background-color: $white;
			}
		}

		.btn-secondary {
			border: 3px solid $primary-orange;

			&:hover {
				border: 3px solid $white;
			}
		}
	}
}
