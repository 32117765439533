.container-cart-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 70%;
    margin: 0 auto;
    padding-bottom: 160px;

    .icon {
        width: 138px;
        height: 138px;
        margin-bottom: 60px;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    .title-h2 {
        margin-bottom: 12px;
    }

    .description {
        text-align: center;
        @include rem(16px);
        line-height: 150%;
        margin-bottom: $pad-med_mobile;
    }

    .btn-primary {
        padding: 10px 28px;
    }
}