.anime-title {
    opacity: 0;
    .word {
        white-space: nowrap;
      }
    .letter {
        display: inline-block;
        opacity: 0;
        transform-origin: left bottom;
    }
}

.anime-texte {
    opacity: 0;
}

.anime-img {
    overflow: hidden;
    opacity: 0;
}

.anime-cards {
    &>* {
        opacity: 0;
    }
}
.anime-btns .btn {
    opacity: 0;
}