.module-13-events {
    padding: 60px 0;

    @include break(tablet) {
        padding: 35px 0;
    }

    .wrapper-events {
        display: flex;
        flex-direction: column;
        gap: 18px;
        width: 100%;
        
        @include break(tablet) {
            gap: 12px;
        }

        .event {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 80px;
            padding: 26px 40px 26px 75px;
            border-radius: 16px;
            background-color: $primary-yellow;
            
            @include break(tablet) {
                padding: 20px 24px;
                flex-direction: column;
                align-items: flex-start;
                gap: 0;
            }
        }

        .date {
            display: flex;
            flex-direction: column;
            align-items: center;

            @include break(desktop-only) {
                width: 62px;
            }

            @include break(tablet) {
                margin-bottom: $pad-xsmall;
            }

            span {
                @include font-black;
                @include rem(22px);
                line-height: 140%;

                @include break(tablet) {
                    @include rem(20px);
                }

                &:last-child {
                    @include rem(56px);
                    line-height: 100%;
                    
                    @include break(tablet) {
                        @include rem(36px);
                    }
                }
            }
        }

        .content {
            flex: 1;
        }

        .category {
            @include rem(20px);
            line-height: 140%;
            margin-bottom: 8px;
            
            @include break(tablet) {
                @include rem(18px);
            }
        }

        .title-h3 {
            margin-bottom: 18px;

            @include break(tablet) {
                margin-bottom: 38px;
            }
        }

        .info-event {
            display: flex;
            align-items: center;
            gap: 16px;
            
            @include break(tablet) {
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                margin-bottom: $pad-xsmall;
            }

            p {
                display: inline-flex;
                align-items: center;
                padding: 3px 14px 3px 6px;
                gap: 9px;
                line-height: 140%;
                border-radius: 30px;
                background: rgba(255, 255, 255, 0.40);
                @include rem(16px);

                &.hours {
                    padding-left: 14px;
                }

                .icon {
                    display: flex;
                    align-items: center;
                    height: 18px;
                    width: 18px;
                }
            }
        }
    }
}