.module-16-sidebar-tab {
	padding: 60px 0;

	@include break(tablet) {
		padding: 35px 0;
	}

	.menu-anchor {
		display: flex;
		flex-direction: column;

		@include break(tablet) {
			margin-bottom: $pad-med_mobile;
		}
	}

	.title-anchor {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 18px 20px;
		border-radius: 20px;

		.text {
			@include font-bold;
			@include rem(20px);
			line-height: 140%;
		}

		.icon {
			display: flex;
			align-items: center;
			max-width: 20px;
		}

		&.active {
			background: $primary-orange;

			.text {
				color: $primary-yellow;
			}

			.icon {
				path {
					fill: $primary-yellow;
				}
			}
		}

		@include break(desktop-only) {
			&:hover {
				background: $primary-orange;

				.text {
					color: $primary-yellow;
				}

				.icon {
					path {
						fill: $primary-yellow;
					}
				}
			}
		}
	}

	.tabs-item {
		height: 0;
		opacity: 0;
		visibility: hidden;
		transform: translateY(-10px);
		transition: height 0.25s ease-out, transform 0.25s ease-out,
		opacity 0.25s ease-out;
		
		.form {
			display: none
		}
		
		.title-h2 {
			@include font-bold;
			@include rem(36px);
			line-height: 100%;
			margin-bottom: 12px;

			@include break(tablet) {
				@include rem(26px);
			}
		}

		.description {
			margin-bottom: 48px;

			@include break(tablet) {
				margin-bottom: 36px;
			}

			& > * {
				margin-bottom: 12px;
			}
		}

		.cta-block {
			border-radius: 10px;
			background: $primary-orange;
			padding: 30px 40px;

			@include break(tablet) {
				padding: 15px 20px;
			}

			.row {
				justify-content: space-between;
				align-items: center;
			}

			.wrapper-texte {
				color: $white;
				display: flex;
				flex-direction: column;
				justify-content: center;

				.title-h3 {
					@include rem(22px);
					line-height: 140%;
				}

				.description {
					margin-bottom: 0;
					@include rem(16px);

					@include break(tablet) {
						margin-bottom: 12px;
					}

					p {
						margin-bottom: 0;
					}
				}
			}

			.wrapper-cta {
				.btn-primary {
					background-color: $white;
					color: $primary-orange;

					&:hover {
						background-color: $primary-orange;
						color: $white;
					}
				}
			}
		}
	}

	.tabs-item.active {
		opacity: 1;
		height: 100%;
		width: 100%;
		transform: translateY(0);
		visibility: visible;

		.form {
			display: block;
		}
	}
}
