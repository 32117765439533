.module-10-blog-articles-carousel {
    padding: 60px 0;
    background-color: $light-grey;

    @include break(tablet) {
        padding: 35px 0;
    }

    .title-h2 {
        color: $primary-orange;
        margin-bottom: 12px;
    }

    .subtitle {
        color: $primary-yellow;
        @include rem(26px);
        margin-bottom: $pad-med;

        @include break(tablet) {
            @include rem(22px);
        }
    }

    .wrapper-cta {
        display: flex;
        justify-content: center;
        margin-top: 60px;
        
        @include break(tablet) {
            margin-top: 0;
        }
    }
}