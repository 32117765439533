.container-dashboard {
	padding-bottom: 100px;

	.woocommerce-MyAccount-navigation {
		margin-right: auto;
		border-right: 1px solid $secondary-grey;
		@include break(tablet) {
			margin-bottom: $pad-med_mobile;
		}

		.title-h3 {
			@include rem(22px);
			text-transform: uppercase;
			line-height: 140%;
			margin-bottom: 18px;
		}

		.menu-anchor {
			margin: 0;
			padding: 0;
			padding-right: 48px;
			list-style: none;

			display: flex;
			flex-direction: column;

			@include break(tablet) {
				margin-bottom: $pad-med_mobile;
			}

			.is-active {
				.title-anchor {
					background: $primary-orange;

					.text {
						color: $primary-yellow;
					}

					.icon {
						path {
							fill: $primary-yellow;
						}
					}
				}
			}

			.title-anchor {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 18px 20px;
				border-radius: 20px;
				cursor: pointer;

				.text {
					@include font-bold;
					@include rem(20px);
					line-height: 140%;
				}

				.icon {
					display: flex;
					align-items: center;
					max-width: 20px;
				}

				&:hover {
					background: $primary-orange;

					.text {
						color: $primary-yellow;
					}

					.icon {
						path {
							fill: $primary-yellow;
						}
					}
				}
			}
		}

		.logout-link {
			color: $primary-orange;
			@include rem(16px);
			line-height: 140%;
			text-decoration-line: underline;
			padding-top: 40px;

			@include break(tablet) {
				padding-top: 8px;
			}
		}
	}

	.woocommerce-MyAccount-content {
		height: fit-content;

		.title-h2 {
			@include font-bold;
			@include rem(36px);
			line-height: 100%;
			margin-bottom: 12px;

			@include break(tablet) {
				@include rem(26px);
			}
		}

		.description {
			@include rem(16px);
			line-height: 140%;
		}
	}

	.dashboard-navigation-menu {
		width: 100%;
		height: 100%;
		padding-top: $pad-large_mobile;
		display: flex;

		ul {
			list-style: none;
			display: flex;
			flex-wrap: wrap;
			gap: 25px;
			margin: 0;
			padding: 0;
			width: 100%;

			@include break(tablet) {
				flex-direction: column;
			}

			li {
				display: flex;
				width: calc((100% / 3) - 20px);
				height: 160px;

				@include break(tablet) {
					width: 100%;
				}
			}

			.dashboard-navigation-menu-item {
				background-color: $secondary-light-grey;
				height: 100%;
				width: 100%;
				padding: 23px 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				border-radius: 30px;
				flex: 1;

				&:hover {
					.text {
						color: $primary-orange;
					}
				}

				.icon {
					margin-bottom: 10px;
					max-width: 66px;
					max-height: 66px;
				}

				.text {
					@include font-bold;
					@include rem(26px);
					text-align: center;
					line-height: 140%;
                    transition: color .25s ease-out;
				}
			}
		}
	}
}
