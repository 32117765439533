.module-11-FAQ {
	padding: 60px 0;

	@include break(tablet) {
		padding: 35px 0;
	}

	.title-h2 {
		color: $primary-orange;
		margin-bottom: $pad-xsmall;

		@include break(tablet) {
			margin-bottom: 12px;
		}
	}

	.description {
		@include break(tablet) {
			margin-bottom: $pad-small;
		}
	}

	.wrapper-texte {
		> * {
			max-width: 50%;

			@include break(tablet) {
				max-width: 100%;
			}
		}
	}

	.faqs {
		.faq-item {
			padding: 24px 0;
			border-bottom: 1px solid $secondary-grey;
			
			&:first-child {
				border-top: 1px solid $secondary-grey;
			}
		}

		.question {
            color: $dark;
			position: relative;
			@include font-bold;
			@include rem(22px);
			text-align: left;
			padding-left: 40px;
			line-height: 140%;
			background: none;
			border: none;
			cursor: pointer;
			transition: all 0.25s ease-out;

			@include break(tablet) {
				@include rem(20px);
			}

			.icon {
				position: absolute;
				top: 4px;
				left: 0;
				display: block;
				height: 20px;
				width: 20px;
				transition: transform 0.25s ease-out;

				@include break(tablet) {
					height: 18px;
					width: 18px;
				}
			}

			&.open {
				color: $primary-orange;
			}

			@include break(desktop-only) {
				&:hover {
					color: $primary-orange;
				}
			}

			&.open {
				.icon {
					transform: rotate(135deg);
				}
			}
		}

		.answer {
			height: 0;
			opacity: 0;
			overflow: hidden;
			padding-top: 0;
			padding-left: 40px;
			transform: translateY(-10px);
			transition: height 0.25s ease-out, transform 0.25s ease-out 0.25s,
				opacity 0.25s ease-out 0.25s;

			> * {
				margin-top: 0;
			}
		}

		.answer.show {
			height: 100%;
			opacity: 1;
			padding-top: 12px;
			transform: translateY(0);
		}
	}
}
