.woocommerce {

    .dashboard-orders {
        display: flex;
        flex-direction: column;

        .title-h2 {
            @include font-bold;
            @include rem(36px);
            line-height: 100%;
            margin-bottom: 40px;
            
            @include break(tablet) {
                @include rem(26px);
            }
        }

        .shop-button {
            margin-top: 40px;
            width: fit-content;
        }

        .view-item-btn {
            padding: 8px 0;
            color: $primary-orange;
            text-decoration: underline;
        }

        table.shop_table {
            border: 0;
        }
    }

    .woocommerce-order-details {
        margin-top: 20px;
        padding: 40px 36px;
        border-radius: 30px;
        background-color: $secondary-light-grey;

        @include break(tablet) {
            padding: 40px 20px;
        }

        .title-h2 {
            @include rem(36px);
            @include font-bold;
            line-height: 100%;
            margin-bottom: $pad-small;

            @include break(tablet) {
                @include rem(20px);
            }
        }

        .order-again a {
            display: inline-flex;
            padding: 10px 28px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            @include font-bold;
            @include rem(20px);
            line-height: 150%;
            color: $white;
            background-color: $primary-orange;
            border-radius: 40px;
            margin-top: $pad-med_mobile;

            @include break(tablet) {
                @include rem(18px);
                padding: 15px 36px;
            }

            &:hover {
                color: $primary-orange;
                background-color: $white;
            }
        }

        .order_details-items,
        .order_details-footer .order-total {
            display: flex;
            justify-content: space-between;
        }

        .order_details-items {
            flex-direction: column;
            gap: 18px;
            padding-bottom: 36px;
            border-bottom: 1px solid $dark;

            .product-item {
                display: flex;
                gap: 18px;
                width: 100%;

                .product-image {
                    width: 83px;
                    height: 83px;
                    border-radius: 24px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .product-details {
                    flex: 1;
                    display: flex;
                    flex-direction: column;

                    a {
                        @include font-bold;

                        @include break(tablet) {
                            @include rem(16px);
                        }
                    }

                    .product-sku {
                        @include font-light;
                        @include rem(13px);
                        line-height: 140%;
                        flex: 1;
                    }

                    .product-quantity {
                        @include rem(16px);
                        line-height: 140%;
                    }
                }

                .amount {
                    @include break(tablet) {
                        @include rem(16px);
                    }
                }
            }
        }

        .order_details-footer {
            padding-top: $pad-small;

            .order-total {

                &:not(:last-child) {
                    margin-bottom: 7px;

                    @include break(tablet) {
                        margin-bottom: 10px;
                        @include rem(16px);
                    }
                }

                &:last-child {
                    @include rem(26px);
                    line-height: 140%;

                    @include break(tablet) {
                        @include rem(20px);
                    }
                }
            }
        }
    }
}