.module-14-teams {
    padding: 60px 0;

    @include break(tablet) {
        padding: 35px 0;
    }

    .wrapper-teams {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 80px;

        @include break(tablet) {
            grid-template-columns: 1fr;
            gap: $pad-med_mobile;
        }
    }

    .team {
        display: flex;
        flex-direction: column;
        gap: 18px;

        @include break(tablet) {
            gap: 16px;
        }

        .team-wrapper {
            display: flex;
            gap: 23px;
        }

        .profil {
            min-width: 228px;
            max-height: 247px;

            @include break(tablet) {
                min-width: 141px;
                max-width: 141px;
                max-height: 153px;
            }

            img {
                object-fit: cover;
                width: 100%;
            }
        }

        .info-team {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .title-h3 {
            color: $primary-orange;
            @include rem(56px);
            line-height: 100%;

            @include break(tablet) {
                @include rem(36px);
            }
        }

        .job,
        .localisation {
            display: flex;
            align-items: center;
            gap: 10px;
            @include rem(16px);
            line-height: 140%;

            .icon {
                width: 18px;
                height: 18px;
                display: flex;
                align-items: center;
            }
        }

        .job {
            align-items: flex-start;
        }

        .description {
            @include rem(16px);
        }
    }

}