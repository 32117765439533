.module-04-banner-text {
	background-color: $primary-orange;

	.description {
		line-height: 140%;
	}

	&.layout-brand {
		padding: 120px 15%;

		@include break(tablet) {
			padding: 80px 0;
		}

		.description {
			color: $primary-yellow;
			@include font-black;
			@include rem(60px);

			@include break(tablet) {
				@include rem(32px);
			}
		}
	}

	&.layout-product {
		position: relative;
		padding: 140px 0;

		@include break(desktop-only) {
			min-height: 380px;
			display: flex;
			align-items: center;
		}

		@include break(tablet) {
			padding: 60px 0 200px 0;
		}

		.icon {
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			max-width: 277px;
			overflow: hidden;

			@include break(desktop-only) {
				display: flex;
				align-items: center;
			}

			@include break(small-screen) {
				max-width: 225px;
			}

			@include break(tablet) {
				top: unset;
				right: unset;
				bottom: -35px;
				left: 50%;
				transform: translateX(-50%) rotate(90deg);
				max-width: 180px;
				max-height: 251px;
			}

			svg {
				width: 100%;
				height: 100%;
			}
		}

		.description {
			color: $white;
			@include font-medium;
			@include rem(36px);
			max-width: 80%;

			@include break(tablet) {
				@include rem(26px);
				max-width: 100%;
			}

			a {
				position: relative;
				color: $white;
				text-decoration: none;

				&::after {
					content: "";
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					display: block;
					height: 3px;
					opacity: 1;
					background-color: $white;
					transition: opacity 0.3s ease;
				}

				&:hover {
					&::after {
						opacity: 0;
					}
				}
			}
		}
	}
	& + .module-02-content {
		@include break(tablet) {
			margin-top: $pad-med_mobile;
		}
	}
}
