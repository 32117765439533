.template-cpt-blog {
	&.cat {
		&-community,
		&-communauté {
			.header_article {
				background: $secondary-pastel-green;
			}
		}

		&-guides,
		&-how {
			.header_article {
				background: $secondary-pink;
			}
		}

		&-products,
		&-produits,
		&-produkte {
			.header_article {
				background: $primary-yellow;
			}
		}
	}

	.header_article {
		padding-top: 180px;
		padding-bottom: 50px;

		.wrapper-text {
			padding-right: 80px;
			display: flex;
			flex-direction: column;

			@include break(tablet) {
				order: 2;
			}
		}

		.text-bloc {
			display: flex;
			flex-direction: column;
			justify-content: center;
			flex: 1;

			@include break(tablet) {
				margin-bottom: 44px;
			}
		}

		.wrapper-media {
			width: 100%;
			height: 627px;
			border-radius: 4px;
			overflow: hidden;

			@include break(tablet) {
				order: 1;
				height: 295px;
				margin-bottom: $pad-med_mobile;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.category {
			padding: 9px 20px;
			@include font-bold;
			@include rem(20px);
			border-radius: 50px;
			border: 3px solid $dark;
			width: fit-content;
			line-height: 100%;
			margin-bottom: 24px;

			@include break(tablet) {
				@include rem(18px);
			}
		}

		.title-h1 {
			@include font-bold;
			@include rem(56px);
			line-height: 100%;

			@include break(tablet) {
				@include font-bold;
				@include rem(36px);
			}
		}

		.date {
			@include rem(13px);
			line-height: 140%;
		}
	}

	.page-container {
		max-width: 50%;
		margin: 0 auto;
		padding: 80px 0 60px 0;

		@include break(tablet) {
			max-width: 90%;
		}

		.content-editor {
			section:last-child {
				@include break(tablet) {
					margin-bottom: $pad-large_mobile;
				}
			}
		}

		& + section {
            @include break(tablet) {
                padding-top: 0;
            }
		}
	}
}
