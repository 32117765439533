.module-06-banner-full-image {
    position: relative;
    background-color: $primary-yellow;

    .wrapper-media {

        img,
        video {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    .wrapper-texte {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 95px 5% 95px 65px;

        @include break(tablet) {
            padding: 35px 5%;
        }
    }

    .title-h2 {
        color: $primary-orange;
        margin-bottom: $pad-xsmall;

        @include break(tablet) {
            margin-bottom: 18px;
        }
    }

    .subtitle {
        color: $primary-yellow;
        @include rem(26px);
        margin-bottom: $pad-xsmall;

        @include break(tablet) {
            @include rem(22px);
            margin-bottom: 18px;
        }
    }

    .wrapper-cta {
        margin-top: $pad-section;
        display: flex;
        align-items: center;
        gap: 12px;

        @include break(mobile) {
            margin-top: 18px;
            flex-direction: column;
            align-items: flex-start;
        }

        .btn-secondary {
            color: $primary-orange;
        }
    }

    &.layout {
        &-imgTxt {
            .wrapper-media {
                order: 1;
            }

            .wrapper-texte {
                order: 2;
            }
        }

        &-txtImg {
            .wrapper-media {
                order: 2;

                @include break(tablet) {
                    order: 1;
                }
            }

            .wrapper-texte {
                order: 1;
                padding-left: 5%;
                padding-right: 100px;

                @include break(tablet) {
                    order: 2;
                }
            }
        }
    }
}