.module-07-banner-rounded-angles {
	padding: 60px 0;

	@include break(tablet) {
		padding: 35px 0;
	}

	.wrapper-media {
		img {
			border-radius: 60px 0px 0px 60px;
			overflow: hidden;

			@include break(tablet) {
				border-radius: 40px 40px 0px 0px;
			}
		}

		img,
		video {
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}

	.wrapper-texte {
		display: flex;
		flex-direction: column;
		min-height: 596px;
		padding: 50px 60px;
		color: $white;
		background-color: $primary-orange;
		border-radius: 0px 60px 60px 0px;

		@include break(tablet) {
			min-height: unset;
			padding: 30px;
			border-radius: 0px 0px 40px 40px;
		}

		.wrapper-title {
			flex: 1;
		}

		.title-h2 {
			max-width: 60%;
			margin-bottom: $pad-xsmall;

			@include break(small-screen) {
				max-width: 80%;
			}

			@include break(tablet) {
				margin-bottom: 12px;
				max-width: 100%;
			}
		}

		.description {
			@include rem(20px);
			line-height: 140%;
		}

		.wrapper-cta {
			display: flex;
			align-items: center;
			gap: 12px;

			@include break(tablet) {
				margin-top: 40px;
				flex-direction: column;
				align-items: flex-start;
			}

			.btn-primary {
				border: 3px solid $white;
				background-color: $white;
				color: $primary-orange;

				&:hover {
					background-color: $primary-orange;
					color: $white;
				}
			}

			.btn-secondary {
				border: 3px solid $white;

				&:hover {
					border: 3px solid $primary-orange;
				}
			}
		}

		p a {
			color: $white;
		}
	}

	.news-form {
		max-width: 70%;

		@include break(tablet) {
			max-width: 100%;
		}
	}

	&.type {
		&-noImg {
			.wrapper-media {
				display: none;
			}

			.wrapper-texte {
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				min-height: unset;
				border-radius: 60px;
				padding: 65px 60px;

				@include break(tablet) {
					padding: 32px;
					border-radius: 40px;
					flex-direction: column;
					align-items: flex-start;
				}

				.wrapper-title {
					display: flex;
					flex-direction: column;
				}

				.title-h2 {
					@include font-bold;
					@include rem(36px);
					line-height: 100%;
					margin-bottom: 16px;

					@include break(tablet) {
						@include rem(26px);
						margin-bottom: 12px;
					}
				}

				.wrapper-cta {
					@include break(tablet) {
						margin-top: 20px;
					}
				}
			}
		}

		&-form {
			.wrapper-texte {
				background-color: $secondary-dark-purple;
			}

			.description {
				margin-bottom: $pad-small;
			}
		}
	}

	&.layout {
		&-imgTxt {
			.wrapper-media {
				order: 1;
			}

			.wrapper-texte {
				order: 2;
			}
		}

		&-txtImg {
			.wrapper-media {
				order: 2;

				img {
					border-radius: 0px 60px 60px 0px;

					@include break(tablet) {
						border-radius: 40px 40px 0px 0px;
					}
				}

				@include break(tablet) {
					order: 1;
				}
			}

			.wrapper-texte {
				order: 1;
				border-radius: 60px 0px 0px 60px;

				@include break(tablet) {
					order: 2;
					border-radius: 0px 0px 40px 40px;
				}
			}
		}
	}
}
