// Boutons / Link
button {
	cursor: pointer;
}

.btn,
.woocommerce button.button {
	display: inline-flex;
	padding: 15px 40px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	@include font-bold;
	@include rem(20px);
	line-height: 150%;
	color: $white;
	border-radius: 40px;
	border: 3px solid $primary-orange;
	cursor: pointer;
	transition: all 0.25s ease-out;

	@include break(tablet) {
		@include rem(18px);
		padding: 15px 36px;
	}

	&.btn-primary {
		background-color: $primary-orange;

		&:hover {
			color: $primary-orange;
			background-color: $white;

			.arrow {
				path {
					fill: $primary-orange;
				}
			}
		}

		&.german-link {
			width: 100%;
			padding: 15px 35px;
			text-align: center;

			@include break(tablet) {
				padding: 15px 25px;
			}
		}
	}

	&.btn-secondary {
		background: transparent;
		border: 3px solid $primary-orange;

		&:hover {
			border: 3px solid $white;
		}
	}

	&.btn-link {
		color: $dark;
		padding: 10px 0;
		display: inline-flex;
		align-items: center;

		.icon {
			height: 18px;
			width: 18px;
			margin-top: -1px;
		}
	}

	&.btn-share {
		padding: 8px 16px;
		line-height: 120%;
		background-color: $primary-orange;
		border: none;

		& > span {
			display: inline-flex;
			gap: 10px;
		}

		.icon {
			display: flex;
			align-items: center;

			svg {
				width: 100%;
				max-width: 18px;
			}
		}

		.hidden {
			display: none;
		}

		&:hover {
			background-color: rgba($color: $primary-orange, $alpha: 0.9);
		}
	}

	.arrow {
		display: flex;
		align-items: center;
		width: 22px;
		height: 22px;
	}
}

a.link {
	color: $primary-orange;
	display: flex;
	font-size: 14px;
	text-transform: uppercase;
	align-items: center;
	transition: all 0.3s;

	svg {
		margin-left: 12px;

		path {
			transition: all 0.3s;
		}
	}
}

.btn-language,
.btn-search,
.btn-account,
.btn-cart {
	border: none;
	background: none;
	color: $white;
	padding: 20px 0;
	position: relative;

	& > * {
		position: relative;
		z-index: 1;
	}

	&::before {
		content: "";
		display: block;
		width: 45px;
		height: 45px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: #fe742a;
		border-radius: 50px;
		z-index: 0;
		opacity: 0;
		transition: opacity 0.25s ease;
	}

	&:hover,
	&.active {
		&::before {
			opacity: 1;
		}
	}
}

.btn-search,
.btn-account,
.btn-account .icon,
.btn-cart,
.btn-cart .icon {
	display: flex;
	align-items: center;
	justify-content: center;
}

.btn-cart {
	position: relative;
	.items {
		position: absolute;
		bottom: 15px;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 4px;
		height: 18px;
		width: 18px;
		@include rem(13px);
		color: $dark;
		background-color: $secondary-light-grey;
		border-radius: 50%;
	}
}

.page-nav {
	display: flex;
	justify-content: center;
	padding-top: 80px;
	padding-bottom: 120px;

	@include break(tablet) {
		padding-top: 52px;
		padding-bottom: 80px;
	}

	.btn {
		color: $dark;
	}
}

.reset_variations {
	display: none !important;
}

.woocommerce #payment #place_order,
.woocommerce-page #payment #place_order {
	background-color: $primary-orange;
	border: 2px solid $primary-orange;
	color: $white;

	&:hover {
		color: $primary-orange;
		background-color: $white;
	}
}
