.module-blog-03-medias {
    margin-bottom: $pad-section;
    position: relative;

    picture,
    video {
        max-height: 550px;
        width: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &.layout-gallery {
        height: 550px;
    }
}