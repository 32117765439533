.template-checkout {
	.woocommerce {
		width: 100%;
		padding-bottom: $pad-large_mobile;
		position: relative;

		.col-customer-details {
			@include break(tablet) {
				margin-bottom: $pad-med_mobile;
			}
		}

		.woocommerce-form-coupon {
			display: block !important;
		}

		.woocommerce-checkout {
			> .row {
				justify-content: space-between;
			}

			h3 {
				margin-bottom: $pad-med_mobile;

				&#ship-to-different-address {
					margin-left: 3px;
				}
			}

			input,
			textarea,
			select {
				&::placeholder {
					color: rgba(0, 0, 0, 0.6);
				}
			}

			.woocommerce-checkout-review-order {
				position: -webkit-sticky;
				position: sticky;
				top: 140px;
				margin-left: auto;
				padding: 40px 36px;
				border-radius: 30px;
				background-color: $secondary-light-grey;

				@include break(tablet) {
					margin-left: 0;
					padding: 40px 20px;
				}

				.wrapper-title {
					display: flex;
					align-items: flex-start;
					justify-content: space-between;

					.link {
						@include rem(16px);
						line-height: 140%;
						text-transform: capitalize;
						text-decoration: underline;
					}
				}

				.shop_table {
					.products-section {
						.products-section-list-item {
							display: flex;
							flex-direction: column;
							gap: 18px;
							padding-bottom: 36px;
						}

						.cart_item {
							display: flex;
							justify-content: space-between;
							gap: 18px;

							.product-thumbnail {
								width: 83px;
								height: 83px;
								border-radius: 14px;
								overflow: hidden;

								img {
									width: 100%;
									height: 100%;
									object-fit: cover;
								}
							}

							.product-name {
								display: flex;
								flex-direction: column;
								justify-content: space-between;
								flex: 1;

								&-head {
									@include font-bold;
									@include rem(18px);
									line-height: 140%;
									margin-bottom: 4px;

									@include break(tablet) {
										@include rem(16px);
									}
								}

								&-sku {
									@include rem(13px);
									@include font-light;
									line-height: 140%;
									flex: 1;
								}

								&-quantity {
									@include rem(16px);
									line-height: 140%;
								}
							}

							.product-total {
								&-price {
									text-align: right;
									@include rem(18px);
									line-height: 140%;

									@include break(tablet) {
										@include rem(16px);
									}
								}
							}
						}
					}
				}

				.coupon {
					@include rem(18px);
					line-height: 140%;
					padding-bottom: 18px;
					border-bottom: 1px solid $dark;

					@include break(tablet) {
						padding-bottom: 22px;
					}

					.input-coupon {
						position: relative;
						padding: 10px 0;
					}

					.input-text {
						color: rgba($dark, 0.4);
						background: $white;
						border: 1px solid $secondary-grey;
						padding: 12px 26px;
						margin: 0;

						&::placeholder {
							color: rgba($dark, 0.4);
						}

						@include break(tablet) {
							padding: 13px 20px;
						}
					}

					button[type="submit"] {
						display: inline-flex;
						align-items: center;
						max-height: 27px;
						padding: 8px 16px;
						position: absolute;
						right: 7px;
						top: 50%;
						transform: translateY(-50%);
						@include rem(16px);
						line-height: 150%;
						border-radius: 40px;
						background-color: $primary-orange;
						border: 2px solid $primary-orange;
						color: $white;
						transition: all 0.25s ease-out;

						&:hover {
							color: $primary-orange;
							background-color: $white;
						}

						@include break(tablet) {
							right: 9px;
						}
					}
				}

				.products-footer {
					padding-top: $pad-med_mobile;
					@include rem(18px);
					line-height: 140%;

					@include break(tablet) {
						@include rem(16px);
					}

					.products-taxes {
						display: flex;
						flex-direction: column;
						gap: 7px;
						margin-bottom: 25px;

						@include break(tablet) {
							margin-bottom: 16px;
						}
					}

					.cart-subtotal,
					.order-total,
					.woocommerce-shipping-totals .woocommerce-shipping-totals-wrapper {
						display: flex;
						justify-content: space-between;
					}

					.woocommerce-shipping-totals-items label {
						font-size: 1rem;
					}

					.woocommerce-shipping-methods {
						input[type="radio"] {
							padding: 0;
						}

						li {
							margin-bottom: 0 !important;
						}
					}

					.order-total {
						@include rem(26px);
						line-height: 140%;

						@include break(tablet) {
							@include rem(20px);
						}
					}
				}

				.add_gift_card_form {
					display: none;
				}

				#payment {
					margin-top: 25px;

					@include break(tablet) {
						margin-top: 16px;
					}
				}
			}

			.col-customer-details {
				.woocommerce-shipping-fields {
					margin-top: 10px;

					#ship-to-different-address {
						label {
							display: flex;
							align-items: center;
						}
					}
				}
			}
		}

		.banner-thankyou-order {
			position: relative;
			margin-bottom: 80px;

			@include break(tablet) {
				margin-bottom: $pad-large_mobile;
			}

			&::before {
				content: "";
				display: block;
				width: 400%;
				height: 200%;
				position: absolute;
				left: -200%;
				top: -100%;
				background-color: $primary-yellow;
				z-index: 0;

				@include break(tablet) {
				}
			}

			.icon {
				position: relative;
				padding-top: 80px;
				padding-bottom: 110px;
				z-index: 1;
				width: 70%;
				margin: 0 auto;

				@include break(tablet) {
					padding-top: $pad-large_mobile;
					padding-bottom: 80px;
					width: 100%;
					height: auto;
				}

				svg {
					width: 100%;
					height: 100%;
				}
			}
		}

		.woocommerce-order {
			width: 70%;
			margin: 0 auto;
			padding-bottom: 200px;

			@include break(tablet) {
				width: 100%;
				padding-bottom: 100px;
			}

			.woocommerce-thankyou-order-received {
				color: $primary-orange;
				text-align: center;
				margin-bottom: 20px;
			}

			.description {
				@include rem(20px);
				line-height: 140%;
				text-align: center;
				margin-bottom: 20px;
			}

			.woocommerce-thankyou-order-details {
				background-color: $secondary-light-grey;
				padding: 40px 60px;
				border-radius: 20px;

				@include break(tablet) {
					padding: 40px 20px;
				}

				> .row:first-child {
					margin-bottom: 26px;
				}

				.col-6 {
					@include break(tablet) {
						flex: 0 0 calc(#{100% / 12 * 6});
					}
				}

				.order-overview-title,
				.order-overview-value {
					@include rem(16px);
					line-height: 140%;
				}

				.order-overview-title {
					color: #8e8e8e;
					margin-bottom: 2px;
				}
			}

			.woocommerce-order-details {
				margin-bottom: 0;
				margin-top: $pad-med_mobile;
			}

			.woocommerce-customer-details {
				display: none;
			}
		}

		.woocommerce-form-login {
			.form-row-checkbox {
				display: flex;
				flex-direction: column;
				width: fit-content;
			}
		}

		.wc-backward {
			display: inline-flex;
			padding: 10px 26px;
			margin-top: $pad-med_mobile;
			justify-content: center;
			align-items: center;
			gap: 10px;
			@include font-bold;
			@include rem(20px);
			line-height: 150%;
			color: $dark;
			background: transparent;
			border-radius: 40px;
			border: 3px solid $primary-orange;
			cursor: pointer;
			transition: all 0.25s ease-out;

			&:hover {
				color: $primary-orange;
			}

			@include break(tablet) {
				@include rem(18px);
			}
		}
	}

	.module-18-recommended-products {
		background-color: $secondary-light-grey;

		@include break(tablet) {
			display: none;
		}

		.products-list .product-item {
			background-color: $white;
		}
	}

	#payment .payment_methods li.payment_method_payplug img.payplug-payment-icon,
	#payment
		.payment_methods
		li.payment_method_bancontact
		img.payplug-payment-icon,
	#payment
		.payment_methods
		li.payment_method_american_express
		img.payplug-payment-icon,
	#payment
		.payment_methods
		li.payment_method_oney_x3_with_fees
		img.payplug-payment-icon,
	#payment
		.payment_methods
		li.payment_method_oney_x4_with_fees
		img.payplug-payment-icon,
	#payment
		.payment_methods
		li.payment_method_oney_x3_without_fees
		img.payplug-payment-icon,
	#payment
		.payment_methods
		li.payment_method_oney_x4_without_fees
		img.payplug-payment-icon {
		width: 140px;
	}
}
