.woocommerce-notices-wrapper {
	position: fixed;
	bottom: 20px;
	z-index: 999;
	right: 2.5%;
	max-height: fit-content;

	@include break(large-desktop-only) {
		position: absolute;
		top: 70vh;
		right: 0;
	}

	@include break(tablet) {
		right: 5%;
		left: 5%;
	}

	.woocommerce-message,
	.woocommerce-error {
		position: relative;
		display: flex;
		flex-direction: column;
		padding: 10px 45px;
		border-top: none;
		background-color: $white;
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
		border-radius: 8px;
		margin-bottom: 2em;
		color: $dark;

		&::before {
			top: 10px;
			left: 15px;
			color: $primary-orange;
		}

		.button.wc-forward {
			width: fit-content;
			padding: 8px 16px;
			margin-top: 10px;
			@include font-bold;
			@include rem(16px);
			line-height: 100%;
			color: $white;
			border-radius: 40px;
			border: 3px solide $primary-orange;
			background-color: $primary-orange;

			&:hover {
				color: $primary-orange;
				background-color: transparent;
			}
		}
	}
	.notice-close {
		position: absolute;
		top: 0px;
		right: 0px;
		background: transparent;
		border: none;
		width: 30px;
		padding: 8px;
		height: 30px;
		display: flex;
		align-items: center;

		svg {
			width: 100%;
			height: 100%;
		}
	}
}

.woocommerce-form-login-toggle {
	bottom: 0;
	right: 2.5%;

	@include break(large-desktop-only) {
		top: 70vh;
		right: 0;
	}

	@include break(tablet) {
		right: 5%;
		left: 5%;
	}

	.woocommerce-info {
		position: relative;
		display: flex;
		gap: 5px;
		padding: 10px 0;
		border-top: none;
		background-color: transparent;
		color: $dark;

		&::before {
			display: none;
		}
	}

	.notice-close {
		display: none;
	}

	.showlogin {
		text-decoration: underline;
		transition: color 0.2s ease;

		&:hover {
			color: $primary-orange;
		}
	}
	&+ .woocommerce-form-login {
		padding: 0 !important;
		border: none !important;

		button[type="submit"] {
			display: inline-flex;
			padding: 8px 28px;
			justify-content: center;
			align-items: center;
			gap: 10px;
			margin: 10px 0;
			color: $white;
			@include font-bold;
			@include rem(20px);
			line-height: 150%;
			border-radius: 40px;
			border: 3px solid $primary-orange;
			background-color: $primary-orange;
			cursor: pointer;
			transition: all 0.25s ease-out;
	
			&:hover,
			&:focus {
				color: $primary-orange;
				background-color: $white;
			}
		}
	}
}

.woocommerce-NoticeGroup {
	.woocommerce-error {
		display: flex;
		flex-direction: column;
		gap: 10px;
		margin-bottom: $pad-med;
		border-top-color: $secondary-red;
		background-color: $secondary-light-grey;
		@include rem(18px);

		&::before {
			color: $secondary-red;
		}

		li {
			padding-left: 60px !important;
			padding-right: 15px;

			&:first-child {
				padding-top: 1em;
			}

			strong {
				@include font-bold;
			}

			.notice-close {
				display: none;
			}
		}
	}
}
