.template-cart {
	.woocommerce {
		display: flex;
		gap: 10px;
		width: 100%;
		padding-bottom: $pad-large_mobile;
		position: relative;

		@include break(tablet) {
			flex-direction: column;
		}

		.woocommerce-notices-wrapper {
			right: 0;
			top: -80px;

			@include break(tablet) {
				position: absolute;
				max-height: 40px;
			}
		}

		.woocommerce-cart-form {
			width: 70%;

			@include break(tablet) {
				width: 100%;
				padding-bottom: $pad-med_mobile;
			}

			.cart-items {
				padding-top: 40px;
				margin-right: 150px;

				.cart-item-wrapper {
					display: flex;
					flex-direction: column;
					gap: 16px;

					.cart_item {
						display: flex;
						justify-content: space-between;
						gap: 14px;
					}

					.product-infos {
						flex: 1;
					}

					.product-thumbnail {
						width: 114px;
						height: 114px;
						border-radius: 24px;
						overflow: hidden;

						@include break(tablet) {
							width: 80px;
							height: 80px;
							overflow: initial;
						}

						a {
							@include break(tablet) {
								display: flex;
								width: 80px;
								height: 80px;
								border-radius: 12px;
								overflow: hidden;
							}
						}

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					.product-name {
						@include font-black;
						@include rem(18px);
						line-height: 140%;
						margin-bottom: 15px;

						.variation {
							@include font-medium;
						}
					}

					.product-stocks {
						display: flex;
						align-items: center;
						gap: 16px;
					}

					.product-quantity {
						.custom-quantity {
							display: flex;
							border: 1px solid $dark;
							border-radius: 40px;
							padding: 5px 11px;
							max-width: 130px;
							min-width: 130px;

							input[type="number"] {
								color: $dark;
								padding: 0;
								margin: 0;
								text-align: center;
								border-radius: 0;
								-moz-appearance: textfield;

								&::-webkit-outer-spin-button,
								&::-webkit-inner-spin-button {
									-webkit-appearance: none;
									margin: 0;
								}
							}

							button.decrement,
							button.increment {
								background: transparent;
								width: 50%;
								max-height: 26px;
								border: none;

								.icon {
									display: flex;
									align-items: center;
									justify-content: center;
									position: relative;
								}

								span {
									position: absolute;
									top: 50%;
									left: 50%;
									height: 2px;
									width: 16px;
									background-color: $dark;
									display: block;

									transform: translate(-50%, -50%);

									&:nth-child(2) {
										transform: translate(-50%, -50%) rotate(90deg);
									}
								}
							}
						}
					}

					.product-remove {
						width: fit-content;

						a.remove {
							width: 100%;
							height: 100%;
							color: $dark !important;
							@include font-medium;
							@include rem(13px);
							line-height: 140%;
							position: relative;

							&::after {
								content: "";
								display: block;
								width: 0;
								height: 1px;
								position: absolute;
								bottom: 0;
								left: 0;
								background-color: $dark;
								transition: width 0.25s ease-out;
							}

							&:hover {
								background: transparent;

								&::after {
									width: 100%;
								}
							}
						}
					}

					.product-subtotal {
						@include break(tablet) {
							margin-left: -15px;
						}
					}
				}

				.cart-items-update {
					.btn-secondary {
						margin-top: $pad-med_mobile;
						border-radius: 40px;
						color: $dark;

						&:hover {
							border-color: $primary-orange;
							background-color: $primary-orange;
							color: $white;
						}
					}
				}
			}
		}

		.cart-collaterals {
			width: 30%;

			@include break(tablet) {
				width: 100%;
			}

			.cart_totals {
				position: -webkit-sticky;
				position: sticky;
				top: 140px;
				width: 100%;
				padding: 40px 36px;
				border-radius: 30px;
				background-color: $secondary-light-grey;

				.title-h2 {
					@include rem(22px);
					@include font-bold;
					line-height: 140%;
					margin-bottom: $pad-med_mobile;
				}

				.cart-subtotal,
				.woocommerce-shipping-totals-wrapper,
				.order-total {
					display: flex;
					justify-content: space-between;
					@include rem(18px);
					line-height: 140%;
				}

				.woocommerce-shipping-destination {
					@include rem(18px);
					line-height: 140%;
				}

				.cart-subtotal {
					margin-bottom: 7px;
				}

				.order-total {
					padding-top: 18px;
					@include rem(26px);
					line-height: 140%;

					@include break(tablet) {
						@include rem(20px);
					}
				}

				.wc-proceed-to-checkout {
					padding-bottom: 0;
					padding-top: 12px;

					.checkout-button {
						display: inline-flex;
						width: 100%;
						padding: 10px 40px;
						justify-content: center;
						align-items: center;
						gap: 10px;
						@include font-bold;
						@include rem(20px);
						line-height: 150%;
						color: $white;
						border-radius: 40px;
						margin-bottom: 0;

						@include break(tablet) {
							@include rem(18px);
							padding: 15px 36px;
						}

						background-color: $primary-orange;

						&:hover {
							color: $primary-orange;
							background-color: $white;
						}
					}
				}
			}
		}

		.wrapper-return {
			text-align: center;
			margin: 0 auto;
			padding-bottom: 120px;

			@include break(tablet) {
				padding-bottom: $pad-large_mobile;
			}

			.icon {
				margin: 0 auto;
				max-width: 138px;
				max-height: 138px;
				margin-bottom: $pad-section;

				@include break(tablet) {
					max-width: 80px;
					max-height: 80px;
					margin-bottom: 18px;
				}

				svg {
					width: 100%;
					height: 100%;
				}
			}

			.title-h2 {
				margin-bottom: 12px;
			}

			.description {
				margin-bottom: $pad-small;
			}
		}
	}

	.module-18-recommended-products {
		background-color: $secondary-light-grey;

		.products-list .product-item {
			background-color: $white;
		}
	}

	.woocommerce-notices-wrapper {
        bottom: 5vh;

        .woocommerce-error {
            position: relative;
            display: flex;
            flex-direction: column;
            padding: 10px 45px;
            border-top: none;
            background-color: $white;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
            border-radius: 8px;
            @include rem(18px);
    
            &::before {
                top: 10px;
                left: 15px;
                color: $primary-orange;
            }

            a {
                text-decoration: underline;
            }
        }
    }
}
