.module-02-content {
	position: relative;

	&.type {
		&-txtMedia {
			.wrapper-texte {
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding-left: 100px;
				padding-right: 5%;

				@include break(tablet) {
					padding: 35px 5%;
				}
			}
		}

		&-txt,
		&-iframe {
			width: 90%;
			margin-left: auto;
			margin-right: auto;
			max-width: $container;
		}

		&-iframe {
			padding: 60px 0;

			@include break(tablet) {
				padding: 35px 0;
			}
		}

		&-txt {
			&.flex-block_first {
				padding-top: 193px;

				.wrapper-texte {
					padding-top: 0;
                    padding-bottom: 120px;

					.title-h1 {
						color: $primary-orange;
						line-height: 90%;
                        margin-bottom: 12px;
					}
				}
			}
		}
	}

	&.layout {
		&-imgTxt {
			.wrapper-media {
				order: 1;
			}

			.wrapper-texte {
				order: 2;
			}
		}

		&-txtImg {
			.wrapper-media {
				order: 2;

				@include break(tablet) {
					order: 1;
				}
			}

			.wrapper-texte {
				order: 1;
				padding-left: 5%;
				padding-right: 100px;

				@include break(tablet) {
					order: 2;
					padding: 35px 5%;
				}
			}
		}
	}

	.wrapper-media {
		img,
		video {
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}

	.wrapper-texte {
		padding-top: 60px;
		padding-bottom: 60px;

		.title-h2 {
			color: $primary-orange;
			margin-bottom: $pad-xsmall;

			@include break(tablet) {
				margin-bottom: 18px;
			}
		}

		.subtitle {
			color: $primary-yellow;
			@include rem(26px);
			margin-bottom: $pad-xsmall;

			@include break(tablet) {
				@include rem(22px);
				margin-bottom: 18px;
			}
		}

		.wrapper-cta {
			margin-top: $pad-xsmall;
			display: flex;
			align-items: center;
			gap: 12px;

			@include break(mobile) {
				margin-top: 18px;
				flex-direction: column;
				align-items: flex-start;
			}

			.btn-secondary {
				color: $dark;
				
				&:hover {
					border: 3px solid $primary-orange;
					color: $primary-orange;
				}
			}
		}
	}
}
