.woocommerce {
    .edit-account {
        .profil-container {
            padding: $pad-med 0;

            @include break(tablet) {
                padding: $pad-med_mobile 0;
            }

            span em {
                @include rem(16px);
            }
        }

        .title-h3 {
            @include rem(22px);
            line-height: 140%;
            margin-bottom: 20px;

            @include break(tablet) {
                @include rem(20px);
            }
        }
    }
}