.module-18-recommended-products {
	position: relative;
	padding: 60px 0;
	background-color: $primary-yellow;
	z-index: 1;

	@include break(tablet) {
		padding: 35px 0;
		z-index: 0;
	}

	.title-h2 {
		color: $primary-orange;
		margin-bottom: $pad-section;

		@include break(tablet) {
			margin-bottom: $pad-med_mobile;
		}
	}

	.btn-primary {
		width: fit-content;
		margin: 0 auto;
	}

	.container-recommended-products {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.products-list {
		margin-bottom: 80px;

		.swiper-wrapper {
			@include break(desktop-only) {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				gap: 25px;
			}

			@include break(tablet) {
				padding-bottom: 30px;
				max-width: 90vw;
			}
		}

		.product-item {
			border-radius: 60px;
			background-color: $secondary-light-grey;
			margin: 0;
			padding-bottom: $pad-med_mobile;
			overflow: hidden;

			@include break(tablet) {
				border-radius: 40px;
				height: 410px;
			}

			@include break(desktop-only) {
				&:hover {
					.product-excerpt {
						transform: translateY(-44px);
					}

					.product-price {
						transform: translateY(-44px);
					}
				}
			}

			.wrapper-media {
				position: relative;

				.shadow {
					display: block;
					width: 70%;
					height: 20px;
					position: absolute;
					top: 70%;
					left: 50%;
					transform: translate(-50%, -50%);
					border-radius: 50%;
					background-color: $dark;
					-webkit-filter: blur(8px);
					-moz-filter: blur(8px);
					-o-filter: blur(8px);
					-ms-filter: blur(8px);
					filter: blur(8px);
					opacity: 0.3;

					@include break(tablet) {
						top: 70%;
					}
				}
			}

			img {
				height: 338px;
				max-height: 338px;
				object-fit: contain;

				@include break(tablet) {
					height: 242px;
					max-height: 242px;
				}
			}

			.title-h3 {
				padding: 26px 0 0 $pad-small;
				@include rem(26px);
				line-height: 140%;
				margin-bottom: 3px;

				@include break(tablet) {
					@include rem(22px);
					margin-bottom: 8px;
				}
			}

			.product-excerpt {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				@include rem(16px);
				padding-left: $pad-small;
				line-height: 140%;
				max-width: 320px;

				@include break(desktop-only) {
					height: 44px;
				}

				@include break(tablet) {
					margin-bottom: 8px;
				}
			}

			.product-price {
				@include font-bold;
				@include rem(20px);
				color: $primary-yellow;
				overflow: hidden;
				text-overflow: ellipsis;
				line-height: 140%;
				padding-left: $pad-small;
				margin-bottom: 18px;

				ins {
					text-decoration: none;
				}
			}

			.description-wrapper {
				position: relative;
				height: 44px;
				padding: 0 10px;
				overflow: hidden;
				transition: height 0.3s ease;

				@include break(tablet) {
					height: 100%;
					max-height: 90px;
				}
			}

			.product-excerpt,
			.product-price {
				transform: translateY(0);
				transition: transform 0.4s ease;
			}

			.container-item {
				position: relative;

				.product-variations {
					position: absolute;
					top: 26px;
					right: 32px;
					display: flex;
					gap: 10px;

					.color-circle {
						display: block;
						width: 25px;
						height: 25px;
						border-radius: 50%;

						@include break(tablet) {
							width: 20px;
							height: 20px;
						}
					

						&.bg-black {
							background-color: #000;
						}

						&.bg-blue-kombi {
							background-color: #1ab6e6;
						}

						&.bg-blue {
							background-color: #abc9c9;
						}

						&.bg-gold {
							background-color: #fdcb03;
						}

						&.bg-green {
							background-color: #a8e000;
						}

						&.bg-grey {
							background-color: #5d686a;
						}

						&.bg-hello-yellow {
							background-color: #fdba01;
						}

						&.bg-orange-supercargo {
							background-color: #fe9602;
						}

						&.bg-orange {
							background-color: #fd7a28;
						}

						&.bg-silver {
							background-color: #e3d9d1;
						}

						&.bg-tan {
							background-color: #c9aa7e;
						}

						&.bg-true-blue {
							background-color: #14577d;
						}

						&.bg-very-berry {
							background-color: #77165c;
						}

						&.bg-yellow {
							background-color: #fdd100;
						}
					}
				}
			}
		}

		.swiper-pagination {
			bottom: 0;
			text-align: center;
		}

		&.products-list-addons {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			gap: 25px;

			@include break(tablet) {
				grid-template-columns: 1fr;
				gap: 12px;
			}

			.product-item {
				text-align: center;
				border-radius: 40px;

				@include break(tablet) {
					display: flex;
					align-items: center;
					padding: 10px;
					border-radius: 18px;
					gap: 20px;
					text-align: left;
					height: fit-content
				}

				.title-h3 {
					@include font-black;

					@include break(tablet) {
						@include rem(20px);
						padding: 0;
						margin-bottom: 0;
					}
				}

				h3,
				.product-excerpt {
					padding-left: 0;
				}

				img {
					height: 262px;
					object-fit: cover;

					@include break(tablet) {
						width: 103px;
						min-width: 103px;
						height: 103px;
						border-radius: 12px;
					}
				}
				&:hover {
					.product-excerpt,
					.product-price {
						transform: none;
					}
				}

				.description-wrapper {
					height: auto;
				}

				.product-excerpt {
					margin-bottom: $pad-spacing;
					max-width: 100%;

					@include break(tablet) {
						margin-bottom: 8px;
					}
				}

				.shop-now {
					padding: 10px 20px;

					@include break(tablet) {
						padding: 6px 16px;
						@include rem(16px);
					}
				}
			}
		}
	}
}
