.module-08-social-media {
    padding: 60px 0;

    @include break(tablet) {
        padding: 35px 0;
    }
    .wrapper-texte {
        display: flex;
        justify-content: space-between;
        width: 100%;

        @include break(tablet) {
            flex-direction: column;
        }
    }

    .title-h2 {
        color: $primary-orange;
        max-width: 500px;

        @include break(tablet) {
            margin-bottom: $pad-med_mobile;
            max-width: unset;
        }
    }

    .socials {
        display: flex;
        gap: 20px;

        @include break(tablet) {
            justify-content: space-between;
        }

        .social-media {
            max-width: 70px;
        }
    }
}