/* --- PADDING SECTION --- */

@mixin padding-section {
	padding-top: 70px;
	padding-bottom: 70px;

	@include break(tablet) {
		padding-top: 40px;
		padding-bottom: 40px;
	}

	.title_top {
		padding-bottom: $pad-med;

		@include break(tablet) {
			padding-bottom: $pad-med_mobile;
		}
	}
}

@mixin padding-section_big {
	padding-top: 120px;
	padding-bottom: 120px;

	@include break(tablet) {
		padding-top: 80px;
		padding-bottom: 80px;
	}
}


/*  --- FONT FALL BACK ---  */

@mixin font-fallback($font-family, $type) {
	@if $type ==sans-serif {
		font-family: $font-family, Helvetica, Arial, sans-serif;
	}

	@else if $type ==serif {
		font-family: $font-family, "Times New Roman", Times, serif;
	}

	@else if $type ==monospace {
		font-family: $font-family, "Courier New", Courier, monospace;
	}
}

// Calcul de la font en rem
@mixin rem($size) {
	font-size: #{$size / $font-size}rem;
}

/* -- Center Element -- */
@mixin align-center {
	margin-left: auto;
	margin-right: auto;
	display: block;
}

@mixin flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

/* -- Responsive -- */
@mixin responsive {
	width: 100%;
	height: auto;
}

@mixin col-width($columns) {
	flex: 0 0 calc(#{100% / 12 * $columns});
	
	@include break(tablet) {
		flex: 100%;
	}
}


/* -- Center Element in Parent with a position relative -- */
@mixin absolute-center($position) {
	position: absolute;

	@if $position ==center {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	@if $position ==vertical-center {
		top: 50%;
		left: 0;
		transform: translate(0, -50%);
	}

	@if $position ==horizontal-center {
		top: 0;
		left: 50%;
		transform: translate(-50%, 0);
	}
}

@mixin bg-img {
	width: 100%;
	height: 100%;
	@include absolute-center(center);
	object-fit: cover;
}



/* -- Hidden/Visible -- */
@mixin visible($state) {
	@if $state ==true {
		display: initial;
		visibility: visible;
		opacity: 1;
	}

	@else if $state ==false {
		display: none;
		visibility: hidden;
		opacity: 0;
		padding: 0;
	}
}

/* -- Shapes -- */

/* Circle */
@mixin circle() {
	border-radius: 50em;
}

/* Triangle */
@mixin arrow ($direction, $size, $color) {
	@if $direction ==up {
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-bottom: $size solid $color;
	}

	@else if $direction ==down {
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-top: $size solid $color;
	}

	@else if $direction ==right {
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-left: $size solid $color;
	}

	@else if $direction ==left {
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-right: $size solid $color;
	}
}


/* -- Image Filter Effect -- */
/*
// grayscale      ex: filter: grayscale(100%);
// sepia          ex: filter: sepia(100%);
// saturate       ex: filter: saturate(0%);
// hue-rotate     ex: filter: hue-rotate(45deg);
// invert         ex: filter: invert(100%);
// brightness     ex: filter: brightness(15%);
// contrast       ex: filter: contrast(200%);
// blur           ex: filter: blur(2px);
*/

@mixin filter($filter-type, $filter-amount) {
	-webkit-filter: $filter-type+unquote('(#{$filter-amount})');
	-moz-filter: $filter-type+unquote('(#{$filter-amount})');
	-ms-filter: $filter-type+unquote('(#{$filter-amount})');
	-o-filter: $filter-type+unquote('(#{$filter-amount})');
	filter: $filter-type+unquote('(#{$filter-amount})');
}

/* Quick Black and White Effect*/
@mixin img-black-and-white() {
	@include filter(grayscale, 100%);
}

@mixin img-color() {
	@include filter(grayscale, 100%);
}

/* Zoom */
@mixin img-zoom($type) {
	@if $type ==in {
		transform: scale(1.05);
		transition-duration: 0.5s;
	}

	@if $type ==out {
		transform: scale(0.95);
		transition-duration: 0.5s;
	}

	@if $type ==initial {
		transform: scale(1);
		transition-duration: 0.5s;
	}
}



/* -- Box-shadow -- */
@mixin box-shadow($horizontal, $vertical, $blur, $spread, $color, $opacity) {
	-webkit-box-shadow: $horizontal $vertical $blur $spread rgba($color, $opacity);
	-moz-box-shadow: $horizontal $vertical $blur $spread rgba($color, $opacity);
	box-shadow: $horizontal $vertical $blur $spread rgba($color, $opacity);
}


/* -- Gradient -- */
@mixin gradient($color1, $opacity1, $color2, $opacity2) {
	background: rgba($color1, $opacity1);
	background: -moz-linear-gradient(left, rgba($color1, $opacity1) 0%, rgba($color2, $opacity2) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba($color2, $opacity2)), color-stop(100%, rgba(138, 39, 230, 1)));
	background: -webkit-linear-gradient(left, rgba($color1, $opacity1) 0%, rgba($color2, $opacity2) 100%);
	background: -o-linear-gradient(left, rgba($color1, $opacity1) 0%, rgba($color2, $opacity2) 100%);
	background: -ms-linear-gradient(left, rgba($color1, $opacity1) 0%, rgba($color2, $opacity2) 100%);
	background: linear-gradient(to right, rgba($color1, $opacity1) 0%, rgba($color2, $opacity2) 100%);
}

/* -- Placeholder -- */

@mixin placeholder-color($p-color) {
	::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: $p-color;
	}

	::-moz-placeholder {
		/* Firefox 19+ */
		color: $p-color;
	}

	:-ms-input-placeholder {
		/* IE 10+ */
		color: $p-color;
	}

	:-moz-placeholder {
		/* Firefox 18- */
		color: $p-color;
	}
}

/* -- Fluid Font -- */
// $min_width: 320px;
// $max_width: 1200px;
// $min_font: 16px;
// $max_font: 24px;
// @include fluid-type($min_width, $max_width, $min_font, $max_font);
@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
	$u1: unit($min-vw);
	$u2: unit($max-vw);
	$u3: unit($min-font-size);
	$u4: unit($max-font-size);

	@if $u1 ==$u2 and $u1 ==$u3 and $u1 ==$u4 {
		& {
			font-size: $min-font-size;

			@media screen and (min-width: $min-vw) {
				font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
			}

			@media screen and (min-width: $max-vw) {
				font-size: $max-font-size;
			}
		}
	}
}