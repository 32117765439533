.woocommerce {
    .warranty {
        .btn-primary {
            margin-top: $pad-med;
            padding: 10px 28px;

            @include break(tablet) {
                margin-top: $pad-med_mobile;
            }

            .icon {
                display: flex;
                align-items: center;
            }

            &:hover {
                .icon {
                    path {
                        fill: $primary-orange;
                    }
                }
            }
        }
    }
}