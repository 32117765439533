html {
	overflow-x: hidden;
	overflow-y: scroll;

	&.overflow {
		overflow: hidden;
	}

	background-color: $light-grey;
	color: $dark;

	&::-webkit-scrollbar {
		width: 0 !important;
	}

	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
	min-height: -webkit-fill-available;
}

body {
	&.overflow {
		overflow: hidden;
	}

	background-color: $white;
	color: $dark;
	font-size: $font-size;

	@include break(mobile) {
		font-size: 16px;
		overflow-x: hidden;
		overflow-y: scroll;
	}

	@include font-medium;
	font-style: normal;
	min-height: 100vh;
	min-height: -webkit-fill-available;
}

// .loader {
// position: fixed;
// top: 0;
// left: 0;
// right: 0;
// height: 100vh;
// z-index: 9999;
// z-index: 9999999999;
// background-color: #ffffff;
// text-align: center;
// }

.flex {
	display: flex;
	justify-content: space-between;

	> * {
		width: 50%;
	}
}

.mobile {
	display: block;

	@include break(mobile) {
		display: none;
	}

	&-display {
		display: none;

		@include break(mobile) {
			display: block;
		}
	}
}

.desktop {
	@include break(mobile) {
		display: none;
	}
}

.grecaptcha-badge {
	visibility: hidden;
}

// Background
.bg-white {
	background-color: $white;
}

.bg-dark {
	background-color: $dark;
}

// .bg-orange {
//   background-color: $primary-orange;
// }

// Padding
.sec-padding_grand {
	@include padding-section_big;
}

.sec-padding_petit {
	@include padding-section;
}

// POPUP
.popup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: none;
	z-index: 999999;
	align-items: center;
	justify-content: center;

	.inner {
		position: absolute;
		width: 100%;
		height: 100vh;
		transform-origin: center;
		display: flex;
		align-items: center;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;

		@include break(tablet) {
			height: 80vh;
		}
	}

	.video_wrap {
		pointer-events: all;
		width: 100%;
		position: relative;
		padding-bottom: 56.25%;
		/* 16:9 */
		height: 0;

		iframe,
		video {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.close {
		position: absolute;
		top: 20px;
		right: 20px;
		cursor: pointer;
		z-index: 2;
		display: flex;
		align-items: center;
		background: none;
		border: none;
		transition: all 0.4s;

		span {
			font-style: italic;
		}

		svg path,
		svg rect {
			transition: all 0.4s;
		}

		&:hover {
			color: $primary-orange;

			svg path,
			svg rect {
				fill: $primary-orange;
				stroke: $primary-orange;
			}
		}
	}

	.bg {
		background: rgba($dark, 0.9);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}
}

// iFrame reponsive
.embed-container {
	position: relative;
	// padding-bottom: 56.25%;
	overflow: hidden;
	max-width: 100%;
	height: auto;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.relative {
	position: relative;
}

.hide-mobile {
	@include break(tablet) {
		display: none;
	}
}
