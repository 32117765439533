.woocommerce-form-login,
.woocommerce-form-register,
.woocommerce-ResetPassword,
.template-account form {
    input {
        @include font-medium;
        @include rem(16px);
        line-height: 140%;
        color: $dark;
        background: $white;
        border-radius: 12px;
        border: 1px solid $secondary-grey;
        padding: 14px 20px;

        &::placeholder {
            color: #C2C2C2;
        }

        &:focus {
            border: 1px solid $primary-orange;
        }

        &[type="checkbox"] {
            padding: 0;
        }
    }

    input[type="submit"],
    button[type="submit"] {
        display: inline-flex;
        padding: 10px 28px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        @include font-bold;
        @include rem(20px);
        line-height: 150%;
        color: $white;
        background-color: $primary-orange;
        border-radius: 40px;
        margin-top: $pad-med_mobile;

        @include break(tablet) {
            @include rem(18px);
            padding: 15px 36px;
        }

        &:hover {
            color: $primary-orange;
            background-color: $white;
        }
    }

    label {
        .required {
            color: $dark !important;
        }

        @include rem(16px);
        line-height: 140%;
    }
}

.template-account form {
    h2 {
        @include rem(36px);
        @include font-bold;
        line-height: 100%;
        margin-bottom: 12px;

        @include break(tablet) {
            @include rem(26px);
        }
    }
}

.woocommerce_gc_giftcard_form {
    input, textarea {
        color: $dark;
        border: 1px solid $secondary-grey;
        
        &:focus {
            border: 1px solid $secondary-grey;
        }
    }
}