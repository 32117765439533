.quatre-cent-quatre {
	.container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		padding-top: 230px;
		padding-bottom: 110px;

		@include break(tablet) {
			padding-top: 160px;
			padding-bottom: 110px;
		}
	}

	h1 {
		color: $primary-orange;
		@include rem(96px);
		line-height: 90%;
		margin-bottom: $pad-small;

		@include break(tablet) {
			@include rem(70px);
		}
	}

	.description {
		@include rem(20px);
		line-height: 150%;
		max-width: 50%;
		margin-bottom: 80px;

		@include break(tablet) {
			max-width: 100%;
			margin-bottom: $pad-large_mobile;
		}
	}

	.btn-primary {
		width: fit-content;
	}

	.animations {
		max-width: 30%;
		margin-bottom: $pad-med;
    
		@include break(tablet) {
			max-width: 100%;
			margin-bottom: $pad-med_mobile;
		}

		canvas {
			width: 100%;
		}
	}
}
