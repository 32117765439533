.post-type-archive-docs {
	// ARCHIVE
	.betterdocs-wrapper.betterdocs-docs-archive-wrapper.betterdocs-category-layout-1
		.betterdocs-single-category-wrapper
		.betterdocs-single-category-inner
		.betterdocs-category-title
		a,
	.betterdocs-docs-archive-wrapper.betterdocs-category-layout-1
		.betterdocs-content-wrapper
		.betterdocs-body
		.betterdocs-articles-list
		li
		a:hover {
		color: $primary-orange;
	}

	.betterdocs-wrapper.betterdocs-docs-archive-wrapper.betterdocs-category-layout-1
		.betterdocs-category-grid-inner-wrapper
		.betterdocs-category-header
		.betterdocs-category-header-inner {
		border-bottom: 2px solid $dark;
	}

	.betterdocs-wrapper.betterdocs-docs-archive-wrapper.betterdocs-category-layout-1
		.betterdocs-body
		.betterdocs-articles-list
		li
		a {
		color: $dark;
	}
}

.single-docs {
	// SINGLE
	.betterdocs-single-wrapper.betterdocs-single-layout-1
		.docs-single-title
		.betterdocs-entry-title,
	.betterdocs-sidebar.betterdocs-sidebar-layout-1
		.betterdocs-sidebar-content.betterdocs-category-sidebar
		.betterdocs-single-category-wrapper.active
		.betterdocs-single-category-inner
		.betterdocs-category-header
		.betterdocs-category-header-inner
		.betterdocs-category-title
		a,
	.betterdocs-sidebar.betterdocs-sidebar-layout-1
		.betterdocs-sidebar-content.betterdocs-category-sidebar
		.betterdocs-single-category-wrapper.active
		.betterdocs-single-category-inner
		.betterdocs-category-header
		.betterdocs-category-header-inner
		.betterdocs-category-title:not(a),
	.betterdocs-sidebar.betterdocs-sidebar-layout-1
		.betterdocs-single-category-wrapper
		.betterdocs-single-category-inner
		.betterdocs-articles-list
		li
		a.active,
	.betterdocs-sidebar.betterdocs-sidebar-layout-1
		.betterdocs-single-category-wrapper
		.betterdocs-single-category-inner
		.betterdocs-articles-list
		li
		a:hover,
	.betterdocs-sidebar.betterdocs-sidebar-layout-1
		.betterdocs-sidebar-content
		.betterdocs-single-category-wrapper
		.betterdocs-single-category-inner
		.betterdocs-category-header
		.betterdocs-category-header-inner
		.betterdocs-category-title
		a,
	.betterdocs-sidebar.betterdocs-sidebar-layout-1
		.betterdocs-sidebar-content
		.betterdocs-single-category-wrapper
		.betterdocs-single-category-inner
		.betterdocs-category-header
		.betterdocs-category-header-inner
		.betterdocs-category-title:not(a):hover {
		color: $primary-orange;
	}

	.betterdocs-sidebar.betterdocs-sidebar-layout-1
		.betterdocs-single-category-wrapper
		.betterdocs-single-category-inner
		.betterdocs-articles-list
		li
		a,
	.betterdocs-sidebar.betterdocs-sidebar-layout-1
		.betterdocs-sidebar-content
		.betterdocs-single-category-wrapper
		.betterdocs-single-category-inner
		.betterdocs-category-header
		.betterdocs-category-header-inner
		.betterdocs-category-title
		a,
	.betterdocs-sidebar.betterdocs-sidebar-layout-1
		.betterdocs-sidebar-content
		.betterdocs-single-category-wrapper
		.betterdocs-single-category-inner
		.betterdocs-category-header
		.betterdocs-category-header-inner
		.betterdocs-category-title:not(a),
	.betterdocs-single-wrapper.betterdocs-single-layout-1
		.betterdocs-social-share
		.betterdocs-social-share-heading
		h5,
	.betterdocs-single-wrapper.betterdocs-single-layout-1
		.betterdocs-article-reactions
		.betterdocs-article-reactions-heading
		h5,
	.betterdocs-single-wrapper.betterdocs-single-layout-1 .docs-navigation a,
	.betterdocs-single-wrapper.betterdocs-single-layout-1
		.docs-navigation
		a:hover {
		color: $dark;
	}

	.betterdocs-sidebar.betterdocs-sidebar-layout-1
		.betterdocs-sidebar-content
		.betterdocs-single-category-wrapper.active
		.betterdocs-single-category-inner
		.betterdocs-category-header {
		background-color: $secondary-light-grey;
		border-color: $secondary-grey;
	}

	.betterdocs-single-wrapper.betterdocs-single-layout-1
		.docs-single-title
		.betterdocs-entry-title {
		text-transform: initial;
	}
}

.betterdocs-search-form-wrapper {
	position: relative;
	z-index: 1;
}

.archive.tax-doc_category {
	.betterdocs-content-area {
		padding: 0;
	}
	.betterdocs-wrapper.betterdocs-taxonomy-wrapper
		.betterdocs-content-area
		.betterdocs-content-inner-area {
		padding: 0;
	}
}
