.template-shop {
	.filters {
		padding-bottom: 12px;
		margin-bottom: $pad-med;
		align-items: center;
		@include font-bold;
		@include rem(22px);
		line-height: 140%;
		border-bottom: 3px solid $dark;

		@include break(tablet) {
			padding: 25px 0;
		}

		.col-filters {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.filters-text {
			display: flex;
			align-items: center;

			@include break(tablet) {
				display: none;
			}
		}

		.filters-results {
			color: $primary-orange;

			@include break(tablet) {
				@include rem(20px);
			}
		}

		.woocommerce-ordering {
			position: relative;
			margin-bottom: 0;

			select {
				padding: 4px 30px 4px 16px;
				@include rem(16px);
				color: $dark;
				border: 3px solid $dark;
				font-size: 16px;
				line-height: 150%;
				border-radius: 40px;
				margin-bottom: 0;
				margin-top: 0;
			}

			&::before {
				content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M19.5938 9.55668L11.957 17.1934L4.32028 9.55668L5.33851 8.53844L11.957 15.157L18.5756 8.53844L19.5938 9.55668Z' fill='%23333333' stroke='%23333333'/%3E%3C/svg%3E");
				position: absolute;
				top: 50%;
				right: 10px;
				transform: translateY(-50%);
				pointer-events: none;
				height: 24px;
				width: 24px;
			}
		}
	}

	.filter-desktop {
		display: flex;

		@include break(tablet) {
			display: none;
		}
	}

	.filter-mobile {
		.mobile-filters-container {
			display: none;

			@include break(tablet) {
				display: flex;
				width: 100%;

				&.open {
					.filters-sidebar {
						display: block;
						z-index: 1002;
					}

					.filters-header {
						display: none;
					}
				}
			}

			.sidebar {
				display: flex;
				flex-direction: column;
				padding: 0 16px;

				.sidebar-header {
					width: 100%;
					position: relative;
					text-align: center;
					margin-top: 20px;
					@include rem(20px);
					@include font-black;
					line-height: 140%;
					border-bottom: 3px solid $dark;
					padding-bottom: 16px;

					.btn_close {
						position: absolute;
						top: 0;
						right: 21px;
						display: flex;
						align-items: center;
						margin-left: auto;
					}
				}
			}

			.filters-sidebar {
				position: fixed;
				bottom: 0;
				left: 0;
				right: 0;
				border-radius: 40px 40px 0px 0px;
				background-color: white;
				z-index: 1000;
				display: none;
				overflow: auto;
				height: calc(100vh - 37px);

				.title-h3 {
					@include rem(18px);
					line-height: 140%;
					margin-bottom: 6px;

					&.title-sorting {
						margin-bottom: 16px;
					}
				}

				#bike-filters {
					padding-right: 0;
				}
			}

			.catalog-ordering {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				margin: $pad-med_mobile 0;
			}

			.filters-header {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				padding: 15px;
				text-align: center;
				z-index: 1001;
				
				@include break(tablet) {
					padding: 0;
				}
			}

			.toggle-filters {
				display: flex;
				align-items: center;
				gap: 10px;
				padding: 4px 16px;
				@include rem(16px);
				color: $dark;
				border: 3px solid $dark;
				font-size: 16px;
				line-height: 150%;
				border-radius: 40px;
				margin-bottom: 0;
				margin-top: 0;
				cursor: pointer;

				.icon {
					display: flex;
					align-items: center;
				}
			}
		}
	}

	.sidebar {
		@include break(tablet) {
			display: none;
		}
	}

	.content-area {
		ul.products {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 25px;
			margin-bottom: 0;

			@include break(tablet) {
				grid-template-columns: 1fr !important;
				gap: 12px;
			}

			&::before {
				display: none;
			}

			li.product {
				@include break(tablet) {
					width: 100% !important;
					margin-bottom: 0 !important;
				}
			}
		}
	}

	&.template-bikes,
	&.template-add-ons,
	&.template-spare-parts {
		.row-products {
			padding-top: $pad-med;
			padding-bottom: 60px;

			@include break(tablet) {
				padding: 25px 0;
			}

			& > .row {
				width: 100%;
			}

			.col-products {
				.lmp_products_loading {
					display: none;
				}
				
				.lmp_load_more_button {
					.lmp_button {
						display: inline-flex;
						display: -webkit-inline-box;
						display: -ms-inline-flexbox;
						padding: 15px 40px !important;
						-webkit-box-pack: center;
						-ms-flex-pack: center;
						justify-content: center;
						-webkit-box-align: center;
						-ms-flex-align: center;
						align-items: center;
						gap: 10px;
						@include font-bold;
						font-size: 18px !important;
						line-height: 150%;
						background: transparent !important;
						border-radius: 40px !important;
						border: 3px solid $primary-orange !important;
						-webkit-transition: all 0.25s ease-out;
						transition: all 0.25s ease-out;
						color: $dark;
						margin-top: 80px;
			
						@include break(tablet) {
							margin-top: 50px;
						}
			
						&:hover {
							background: transparent !important;
							color: $primary-orange !important;
						}
					}
				}
			}
		}

		.products {
			.product {
				border-radius: 60px;
				background-color: $secondary-light-grey;
				margin: 0;
				padding-bottom: $pad-med_mobile;
				overflow: hidden;

				@include break(tablet) {
					border-radius: 40px;
				}

				@include break(desktop-only) {
					&:hover {
						.wrapper-hover {
							opacity: 1;
						}

						h2 {
							color: $primary-orange;
							transition: all 0.3s ease;
						}

						.product-excerpt {
							transform: translateY(-44px);
						}

						.price {
							transform: translateY(-44px);
						}
					}
				}

				h2.woocommerce-loop-product__title {
					padding: 0;
					@include rem(26px);
					line-height: 140%;
					margin-bottom: 3px;

					@include break(tablet) {
						@include rem(22px);
						margin-bottom: 8px;
					}
				}

				.wrapper-hover {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					max-height: 300px;
					background-color: $secondary-light-grey;
					opacity: 0;
					transition: all 0.25s ease-out;

					@include break(tablet) {
						display: none;
					}

					.hover-img {
						width: 100%;
						object-fit: cover;
						margin-bottom: 0;
					}

					.btn {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
				}

				.product-excerpt {
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					@include rem(16px);
					line-height: 140%;

					@include break(desktop-only) {
						height: 44px;
						max-width: 320px;
					}

					@include break(tablet) {
						margin-bottom: 8px;
					}
				}

				.price {
					@include font-bold;
					@include rem(20px);
					color: $primary-yellow;
					overflow: hidden;
					text-overflow: ellipsis;
					line-height: 140%;
					margin-bottom: 18px;

					ins {
						text-decoration: none;
					}
				}

				.description-wrapper {
					position: relative;
					height: 44px;
					overflow: hidden;
					transition: height 0.3s ease;

					@include break(tablet) {
						height: fit-content;
						max-height: 90px;
					}
				}
				.variations_form {
					position: absolute;
					bottom: 65px;
					right: 24px;

					@include break(tablet) {
						bottom: 125px;
					}

					.cfvsw-swatches-container {
						display: none;

						&[swatches-attr="attribute_pa_colors"] {
							display: flex;

							@include break(tablet) {
								grid-gap: 2px;
							}
						}
					}

					.cfvsw-swatches-option {
						@include break(tablet) {
							min-width: 24px !important;
							min-height: 24px !important;
							max-height: 24px !important;
							max-width: 24px !important;
						}
					}
				}

				a.added_to_cart {
					display: none;
				}

				.product-excerpt,
				.price {
					transform: translateY(0);
					transition: transform 0.4s ease;
				}

				del {
					color: $dark !important;
					@include rem(15px);
				}

				.button {
					display: inline-flex;
					padding: 10px 28px;
					justify-content: center;
					align-items: center;
					gap: 10px;
					@include font-bold;
					@include rem(20px);
					line-height: 150%;
					color: $white;
					background-color: $primary-orange;
					border-radius: 40px;

					@include break(tablet) {
						@include rem(18px);
						padding: 15px 36px;
					}

					&:hover {
						color: $primary-orange;
						background-color: $white;
					}
				}

				.btn-product-link {
					border: 3px solid $primary-orange;
					padding: 7px 28px;
				}
			}
		}
	}

	&.template-bikes {
		.products {
			.product {
				img {
					height: 100%;
					width: 600px;
					max-height: 300px;
					object-fit: contain;
					margin-bottom: 26px;

					@include break(tablet) {
						max-height: 225px;
					}
				}

				h2.woocommerce-loop-product__title,
				.product-excerpt,
				.price {
					padding-left: $pad-med_mobile;
				}

				.cfvsw-swatches-option {
					min-width: 27px !important;
					min-height: 27px !important;
					max-width: 27px !important;
					max-height: 27px !important;
					padding: 2px;
				}

				.shadow {
					display: block;
					width: 60%;
					height: 20px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					border-radius: 50%;
					background-color: $dark;
					-webkit-filter: blur(8px);
					-moz-filter: blur(8px);
					-o-filter: blur(8px);
					-ms-filter: blur(8px);
					filter: blur(8px);
					opacity: 0.3;

					@include break(tablet) {
						top: 41%;
					}
				}
			}
		}
	}

	&.template-add-ons {
		ul.products {
			grid-template-columns: repeat(3, 1fr);

			.product {
				border-radius: 40px;
				text-align: center;

				img {
					height: 100%;
					width: 400px;
					max-height: 262px;
					object-fit: cover;
					margin-bottom: 16px;

					@include break(tablet) {
						max-height: 242px;
					}
				}

				.wrapper-hover {
					max-height: 262px;
				}

				h2.woocommerce-loop-product__title,
				.product-excerpt,
				.price {
					padding: 0 $pad-med_mobile;
				}

				.product-excerpt {
					margin: 0 auto;
				}

				.add_to_cart_button {
					display: inline-flex;
					padding: 10px 28px;
					justify-content: center;
					align-items: center;
					gap: 10px;
					@include font-bold;
					@include rem(20px);
					line-height: 150%;
					background-color: $primary-orange;
					color: $white;
					border-radius: 40px;
					cursor: pointer;
					transition: all 0.25s ease-out;

					@include break(tablet) {
						@include rem(18px);
						padding: 15px 36px;
					}

					&:hover {
						color: $primary-orange;
						background-color: $white;
					}
				}
			}
		}
	}

	.woocommerce-pagination {
		display: none;
	}
}
