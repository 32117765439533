.module-blog-02-quote {
    margin-bottom: $pad-section;
    text-align: center;

    .quote {
        margin-bottom: $pad-xsmall;
        
        svg {
            max-width: 43px;
        }
    }

    .quote-text {
        @include rem(36px);
        @include font-light;
        line-height: 120%;
        margin-bottom: $pad-xsmall;
    }

    .quote-author {
        @include rem(16px);
        line-height: 140%;
    }
}