form {
	label {
		@include rem(16px);
		line-height: 140%;
	}

	input,
	textarea,
	select {
		margin-top: 8px;
		appearance: none;
		border: none;
		border-radius: 12px;
		background: rgba(255, 255, 255, 0.11);
		color: rgba(255, 255, 255, 0.6);
		width: 100%;
		padding: 15px 18px;
		margin-bottom: 20px;
		transition: all 0.4s;
		border: 1px solid rgba(255, 255, 255, 0.3);

		&:focus {
			outline: none;
			border: 2px solid rgba(255, 255, 255, 0.3);
		}

		&::placeholder {
			color: rgba(255, 255, 255, 0.6);
		}

		&[type="submit"] {
			position: relative;
			display: inline-block;
			width: auto;
			margin-top: $pad-small;
			padding: 17px 40px;
			color: $dark;
			font-size: 16px;
			@include font-bold;
			text-decoration: none;
			text-align: center;
			line-height: 120%;
			border: none;
			border-radius: 40px;
			background-color: $primary-yellow;
			cursor: pointer;

			&:hover,
			&:focus {
				color: $primary-yellow;
				background-color: $dark;
			}

			@include break(tablet) {
				margin-top: 12px;
			}
		}

		&[type="checkbox"] {
			position: relative;
			width: 16px;
			height: 16px;
			min-width: 16px;
			min-height: 16px;
			margin-top: 0px;
			margin-bottom: 0;
			margin-right: 8px;
			border-radius: 4px;
			border: 1px solid $secondary-grey;

			@include break(tablet) {
				padding-right: 14px;
			}

			&::before {
				content: "";
				display: flex;
				width: 10px;
				height: 10px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				background-color: $primary-orange;
				border-radius: 2px;
				opacity: 0;
				transition: all 0.25s ease-out;
			}

			&:checked {
				&::before {
					opacity: 1;
				}
			}
		}

		&[type="radio"] {
			position: relative;
			width: 16px;
			height: 16px;
			border-radius: 50px;
			margin-top: 0px;
			padding: 0;
			margin-right: 10px;
			margin-bottom: 0;

			&::before {
				content: "";
				display: flex;
				width: 10px;
				height: 10px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				background-color: $primary-orange;
				border-radius: 50%;
				opacity: 0;
				transition: all 0.25s ease-out;
			}

			&:focus,
			&:checked {
				&::before {
					opacity: 1;
				}
			}
		}
	}

	.wpcf7-form-control-wrap[data-name="select-country"],
	.wpcf7-form-control-wrap[data-name="question"],
	.wpcf7-form-control-wrap[data-name="country"] {
		position: relative;

		&::before {
			content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.77047 9.1641L12.4072 16.8009L20.044 9.1641L19.0257 8.14586L12.4072 14.7644L5.78871 8.14586L4.77047 9.1641Z' fill='white'/%3E%3C/svg%3E");
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translateY(-50%);
			pointer-events: none;
			height: 24px;
			width: 24px;
		}
	}

	.wpcf7-form-control-wrap[data-name="question"],
	.wpcf7-form-control-wrap[data-name="country"] {
		&::before {
			content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.77047 9.1641L12.4072 16.8009L20.044 9.1641L19.0257 8.14586L12.4072 14.7644L5.78871 8.14586L4.77047 9.1641Z' fill='%23333333'/%3E%3C/svg%3E");
		}
	}

	.wpcf7-acceptance {
		label {
			display: flex;
			align-items: center;
		}
	}
}

/* ===== Contact form ==== */
.contact-form {
	width: 100%;

	label {
		@include rem(16px);
		line-height: 140%;
	}

	input,
	textarea,
	select {
		color: $dark;
		border: 1px solid $dark;
		background: $white;

		&:focus {
			outline: none;
			border: 1px solid $primary-orange;
			box-shadow: 0px 0px 0px 4px rgba(255, 88, 0, 0.1);
		}

		&::placeholder {
			color: #c2c2c2;
		}

		&[type="submit"] {
			color: $white;
			@include font-bold;
			border: 3px solid $primary-orange;
			background-color: $primary-orange;

			&:hover,
			&:focus {
				color: $primary-orange;
				background-color: $white;
			}
		}
	}
}

/* ===== Filters products bikes ==== */
#bike-filters {
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding-right: 100px;

	.title-h4 {
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include font-medium;
		@include rem(16px);
		line-height: 140%;

		@include break(tablet) {
			@include rem(15px);
		}
	}

	.title-h4:after {
		content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.77145 9.16312L12.4082 16.7999L20.045 9.16312L19.0267 8.14489L12.4082 14.7634L5.78968 8.14489L4.77145 9.16312Z' fill='%23333333'/%3E%3C/svg%3E");
		transition: transform 0.3s ease;
	}

	.title-h4.active:after {
		transform: rotate(180deg);
	}

	.filter-group {
		padding: 12px 16px;
		border-radius: 10px;
		border: 3px solid $secondary-grey;
		transition: all 0.3s ease-out;

		&.open {
			border: 3px solid $primary-orange;
		}
	}

	.filter-options {
		max-height: 0;
		height: 0;
		overflow: hidden;
		@include font-medium;
		@include rem(16px);
		line-height: 140%;
		transition: max-height 0.3s ease-out;
		display: flex;
		flex-direction: column;
		gap: 8px;

		&.active {
			height: 100%;
			max-height: 100%;
			padding-top: 14px;
		}
	}

	.filter-others {
		margin-top: 14px;
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	label {
		display: flex;
		align-items: center;
		cursor: pointer;
		transition: color 0.25s ease-out;

		&:hover,
		&:focus {
			color: $primary-orange;
		}
	}

	input[type="checkbox"] {
		border-radius: 4px;
	}
}

/* ===== Woocommerce - form ==== */
.woocommerce form .form-row.woocommerce-validated .select2-container,
.woocommerce form .form-row.woocommerce-validated input.input-text,
.woocommerce form .form-row.woocommerce-validated select {
	border-color: $secondary-green !important;
}

.woocommerce form .form-row.woocommerce-invalid .select2-container,
.woocommerce form .form-row.woocommerce-invalid input.input-text,
.woocommerce form .form-row.woocommerce-invalid select {
	border-color: $secondary-red !important;
}

.woocommerce form .form-row.woocommerce-invalid label {
	color: $secondary-red !important;
}

/* ===== Woocommerce - Cart ==== */
.woocommerce-cart-form {
}

/* ===== Woocommerce - Checkout ==== */
.woocommerce .woocommerce-checkout {

	h3 {
		@include rem(22px);
		margin-bottom: 22px;

		@include break(tablet) {
			@include rem(20px);
		}
	}

	.form-row {
		margin: 0 0 18px;
		@include break(tablet) {
			width: 100%;
		}

		input,
		textarea,
		select,
		.input-text {
			font-size: 1rem;
			color: rgba(0, 0, 0, 0.6);
			background: $secondary-light-grey;
			border-radius: 60px;
			padding: 15px 30px;
			transition: all 0.4s;
			border: 1px solid rgba(0, 0, 0, 0.3);
	
			@include break(tablet) {
				padding: 15px 30px 15px 20px;
			}
	
			&[type="checkbox"] {
				display: inline-block !important;
				padding: 0;
				border-radius: 4px;
				width: 10px;
				height: 10px
			}
		}

		label {
			line-height: 1;
			margin-bottom: 8px;
		}

		.required {
			color: $dark;
		}

		textarea,
		textarea.input-text {
			height: 10em;
			border-radius: 45px;
			@include break(tablet) {
				height: 6em;
				border-radius: 30px;
			}
		}
	}
}

/* ===== Woocommerce - Reset password ==== */
.woocommerce-ResetPassword {
	button[type="submit"] {
		margin-top: 30px !important;
		border: 3px solid $primary-orange !important;
		&:disabled,
		&[disabled] {
			padding: 15px 40px !important;
			color: $white !important;

			@include break(tablet) {
				padding: 15px 36px !important;
			}
		}
	}
}

/* ===== Try in store ==== */
.form-try-in-store {
	form {
		input,
		textarea,
		select {
			color: $dark;
			border: 1px solid $secondary-grey;

			&:focus {
				outline: none;
				border-radius: 12px;
				border: 1px solid $primary-orange;
				background: $white;
				box-shadow: 0px 0px 0px 4px rgba(255, 88, 0, 0.1);
			}

			&::placeholder {
				color: #c2c2c2;
			}

			&[type="submit"] {
				color: $white;
				border: 2px solid $primary-orange;
				background-color: $primary-orange;

				&:hover,
				&:focus {
					color: $primary-orange;
					background-color: $white;
				}
			}
		}
	}
}

.news-form ._form-content {
	input,
	textarea,
	select {
		margin-top: 8px;
		appearance: none !important;
		border: none !important;
		border-radius: 12px !important;
		background: rgba(255, 255, 255, 0.11) !important;
		color: rgba(255, 255, 255, 0.6) !important;
		width: 100%;
		padding: 15px 18px !important;
		margin-bottom: 20px;
		transition: all 0.4s;
		border: 1px solid rgba(255, 255, 255, 0.3) !important;

		&:focus {
			outline: none !important;
			border: 2px solid rgba(255, 255, 255, 0.3) !important;
		}

		&::placeholder {
			color: rgba(255, 255, 255, 0.6) !important;
		}
	}

	button {
		&[type="submit"] {
			position: relative;
			display: inline-block;
			width: auto;
			margin-top: $pad-small;
			margin-bottom: 114px;
			padding: 10px 28px !important;
			color: $primary-orange;
			font-size: 16px !important;
			@include font-bold;
			text-decoration: none;
			text-align: center;
			line-height: 120%;
			border: 2px solid $white;
			border-radius: 40px;
			background-color: $white;
			cursor: pointer;

			&:hover,
			&:focus {
				color: $white;
				background-color: transparent;
			}

			@include break(tablet) {
				margin-top: $pad-med_mobile;
				margin-bottom: 52px;
			}
		}
	}

	._form_element select {
		margin-bottom: 0;
	}
}

/* ===== Active Campaing - Contact ==== */
div[class^="_form_"],
div[class^="_form_"] * {
	@include rem(16px);

	._form-fieldset {
		border: none;
		padding: 0;
		margin-bottom: 20px;

		legend {
			margin-bottom: 8px;
		}
	}

	button._submit {
		position: relative;
		display: inline-block;
		width: auto;
		margin-top: $pad-small;
		padding: 17px 40px;
		color: $white;
		font-size: 16px;
		@include font-bold;
		text-decoration: none;
		text-align: center;
		line-height: 120%;
		border: 3px solid $primary-orange;
		border-radius: 40px;
		background-color: $primary-orange;
		cursor: pointer;
		transition: all 0.25s ease;

		&:hover,
		&:focus {
			color: $primary-orange;
			background-color: transparent;
		}

		@include break(tablet) {
			margin-top: 12px;
		}
	}
}
