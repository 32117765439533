.woocommerce .sup-view,
.template-checkout {
	.woocommerce-breadcrumb {
		width: 90%;
		margin-left: auto;
		margin-right: auto;
		max-width: 1485px;
		position: absolute;
		top: 143px;
		left: 50%;
		transform: translateX(-50%);
		padding-left: $pad-large_mobile;
		margin-bottom: 11px;
		color: $white;
		@include rem(16px);
		line-height: 140%;
		z-index: 10;

		a {
			color: $white;
			transform: color .2s ease;

			&:hover {
				color: $primary-orange;
			}
		}

		@include break(tablet) {
			@include rem(13px);
			top: 95px;
			padding-left: 0;
		}
	}
}

.template-checkout {
	.woocommerce-breadcrumb {
		color: $dark;

		a {
			color: $dark;
		}
	}
}

.breadcrumb {
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	max-width: 1485px;
	position: absolute;
	top: 143px;
	left: 50%;
	transform: translateX(-50%);
	padding-left: $pad-large_mobile;
	margin-bottom: 11px;
	color: $white;
	@include rem(16px);
	line-height: 140%;
	z-index: 10;

	a {
		color: $white;
		text-decoration: none;

		&:hover {
			color: $primary-orange;
		}
	}

	@include break(tablet) {
		@include rem(13px);
		top: 95px;
		padding-left: 0;
	}

	&.dark {
		color: $dark;

		a {
			color: $dark;
		}
	}
}
