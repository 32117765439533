.module-05-rolling-number {
    color: $white;
    background-color: $primary-orange;
    padding: 120px 0;

    @include break(tablet) {
        padding: 80px 0;
    }

    .wrapper-texte {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include font-black;

        @include break(tablet) {
            flex-direction: column;
            text-align: center;
        }

        .text-left {
            font-size: clamp(1rem, 3vw, 52px);
            line-height: 100%;
            max-width: 28%;

            @include break(large-desktop-only) {
                max-width: 30%;
            }

            @include break(tablet) {
                @include rem(32px);
                max-width: 100%;
            }
        }

        .number {
            position: relative;
            @include rem(125px);
            color: $primary-yellow;
            text-align: center;
            line-height: 100%;
            max-width: 55%;
            display: flex;
            gap: 3px;
            
            @include break(small-screen) {
                @include rem(76px);
                max-width: 100%;
                margin: 30px auto;
                gap: 2px;
            }

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                display: block;
                width: 100%;
                height: 3px;
                background-color: $primary-orange;
            }

            span {
                padding: 22px 18px;
                background-color: $white;
                border-radius: 20px;
                
                @include break(tablet) {
                    padding: 15px 10px;
                    border-radius: 11px;
                }
            }
        }

        .text-right {
            font-size: clamp(1rem, 2.8vw, 52px);
            line-height: 100%;
            max-width: 15%;

            @include break(small-screen) {
                max-width: 18%;
            }

            @include break(tablet) {
                @include rem(32px);
                max-width: 100%;
            }
        }
    }
}