.woocommerce {

    .dashboard-addresses {
        .title-h2 {
            @include rem(36px);
            @include font-bold;
            line-height: 100%;
            margin-bottom: 12px;

            @include break(tablet) {
                @include rem(26px);
            }
        }

        .btn-primary {
            margin-top: $pad-med_mobile;
            padding: 10px 28px;
        }

        .addresses {
            padding-top: $pad-section;

            @include break(tablet) {
                padding-top: $pad-med_mobile;
                gap: $pad-med_mobile;
            }

            .title-h3 {
                @include rem(22px);
                line-height: 140%;
                margin-bottom: 3px;

                @include break(tablet) {
                    @include rem(20px);
                }
            }

            address {
                font-style: normal;
                @include rem(16px);
            }
        }
    }

    .woocommerce-customer-details {

        .addresses>.row {
            gap: 2em;

            @include break(tablet) {
                flex-direction: column;
            }
        }

        .col-billing-address,
        .col-shipping-address {
            padding: 40px 36px;
            border-radius: 30px;
            background-color: $secondary-light-grey;
            max-width: calc(50% - 1em);

            @include break(tablet) {
                max-width: 100%;
            }

            .title-h2 {
                @include rem(36px);
                @include font-bold;
                line-height: 100%;
                margin-bottom: $pad-small;

                @include break(tablet) {
                    @include rem(20px);
                }
            }

            address {
                padding: 0;
                border: none;
            }
        }
    }
}