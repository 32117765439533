.template-blog {
	.title-h1 {
		color: $primary-orange;
		@include font-black;
		@include rem(96px);
		line-height: 90%;
		margin-bottom: 20px;

		@include break(tablet) {
			@include rem(52px);
		}
	}

	.description {
		@include rem(20px);
		line-height: 140%;
		margin-bottom: 100px;

		@include break(tablet) {
			@include rem(18px);
			margin-bottom: $pad-large_mobile;
		}
	}

	.filter-block {
		margin-bottom: 70px;

		@include break(tablet) {
			overflow-x: auto;
			margin-bottom: $pad-large_mobile;
		}

		.button-group {
			display: flex;
			gap: 10px;
		}

		.btn-filter {
			border-radius: 10px;
			border: 3px solid $secondary-grey;
			background: transparent;
			color: $dark;
			@include rem(16px);
			line-height: 140%;
			padding: 12px 20px;
			cursor: pointer;

			@include break(tablet) {
				text-wrap: nowrap;
			}

			&.cat {
				&-all-categories {
					border: 3px solid $primary-orange;
					background: $primary-orange;
					color: $primary-yellow;
				}
			}

			&.active,
			&:hover {
				&.cat {
					&-community,
					&-communauté {
						border: 3px solid $secondary-pastel-green;
						background: $secondary-pastel-green;
					}

					&-guides,
					&-how {
						border: 3px solid $secondary-pink;
						background: $secondary-pink;
					}
					
					&-products,
					&-produits,
					&-produkte {
						border: 3px solid $primary-yellow;
						background: $primary-yellow;
					}
				}
			}
		}
	}

	.last_post {
		margin-bottom: $pad-section;

		@include break(tablet) {
			margin-bottom: $pad-xlarge_mobile;
		}

		.card-post_highlight {
			display: flex;
			justify-content: space-between;
			width: 100%;
			border-radius: 40px;
			overflow: hidden;

			@include break(tablet) {
				flex-direction: column;
			}

			.wrapper-text {
				padding: 87.5px 80px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				gap: 24px;

				@include break(tablet) {
					padding: $pad-med_mobile $pad-xsmall;
				}

				.category {
					padding: 9px 20px;
					@include font-bold;
					@include rem(20px);
					border-radius: 50px;
					border: 3px solid $dark;
					width: fit-content;
					line-height: 100%;
				}

				.title-h3 {
					line-height: 100%;
					margin-bottom: 8px;
				}

				.description {
					margin-bottom: 0;
				}

				.btn-read-more {
					color: $dark;
					justify-content: flex-start;
					padding: 10px 0;
					position: relative;
					border: none;
					cursor: pointer;
					pointer-events: none;
					width: fit-content;

					&::after {
						content: "";
						position: absolute;
						bottom: 5px;
						left: 0;
						display: block;
						width: 0;
						height: 2px;
						background-color: $dark;
						pointer-events: none;
						transition: width 0.25s ease-out;
					}

					.icon {
						display: flex;
						align-items: center;
					}
				}
			}

			&:hover {
				.btn-read-more {
					&::after {
						width: 100%;
					}
				}
			}

			.wrapper-media {
				height: 500px;

				@include break(tablet) {
					height: 250px;
				}

				.image {
					max-height: 500px;
					height: 100%;
					width: 100%;
					object-fit: cover;

					@include break(tablet) {
						height: 250px;
						max-height: 250px;
					}
				}
			}

			&.cat {
				&-community,
				&-communauté {
					background-color: $secondary-pastel-green;
				}

				&-guides,
				&-how {
					background-color: $secondary-pink;
				}

				&-products,
				&-produits,
				&-produkte {
					background-color: $primary-yellow;
				}
			}
		}
	}

	.archive {
		.grid-blog {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 25px;
			padding-bottom: 80px;

			@include break(tablet) {
				padding-bottom: 50px;
				grid-template-columns: 1fr;
				gap: 12px;
			}

			.card-post {
				border-radius: 60px;
				overflow: hidden;

				@include break(tablet) {
					border-radius: 40px;
				}

				.btn-read-more {
					padding: 10px 0;
					@include rem(20px);
					color: $dark;
					line-height: 150%;
					position: relative;
					border: none;
					cursor: pointer;
					pointer-events: none;
					width: fit-content;

					&::after {
						content: "";
						position: absolute;
						bottom: 5px;
						left: 0;
						display: block;
						width: 0;
						height: 2px;
						background-color: $dark;
						pointer-events: none;
						transition: width 0.25s ease-out;
					}

					.icon {
						display: flex;
						align-items: center;
					}
				}

				&:hover {
					.btn-read-more {
						&::after {
							width: 100%;
						}
					}
				}

				&.cat {
					&-community,
					&-communauté {
						background: $secondary-pastel-green;

						.wrapper-text,
						.category {
							background-color: $secondary-pastel-green;
						}
					}

					&-guides,
					&-how {
						background: $secondary-pink;

						.wrapper-text,
						.category {
							background-color: $secondary-pink;
						}
					}

					&-products,
					&-produits,
					&-produkte {
						background: $primary-yellow;

						.wrapper-text,
						.category {
							background-color: $primary-yellow;
						}
					}
				}

				.wrapper-media {
					@include break(mobile) {
						max-height: 250px;
					}

					img {
						object-fit: cover;
						width: 100%;
						height: 360px;

						@include break(mobile) {
							height: 250px;
						}
					}
				}

				.content {
					position: relative;
				}

				.wrapper-text {
					position: relative;
					padding: 30px 40px;
					height: 100%;
					min-height: 275px;
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: space-between;

					@include break(tablet) {
						padding: 16px 30px;
						min-height: 150px;
					}

					.title-h3 {
						margin-bottom: 8px;
					}
				}

				.category {
					position: absolute;
					top: -25px;
					left: 20px;
					padding: 10px 20px;
					@include font-bold;
					border-radius: 50px;
					z-index: 1;
					text-transform: capitalize;

					@include break(tablet) {
						left: 10px;
						padding: 8px 18px;
					}
				}
			}
		}

		.load_more {
			display: flex;
			justify-content: center;

			.btn-load-more {
				color: $dark;
				margin-bottom: 80px;

				@include break(tablet) {
					margin-bottom: 40px;
				}
			}
		}
	}
}
