.module-09-quotes-carousel {
	position: relative;
	overflow: hidden;
	padding: 60px 0;

	@include break(tablet) {
		padding: 35px 0 215px 0;
	}

	.bg {
		position: absolute;
		bottom: -7px;
		right: 0;
		width: 500px;

		@include break(small-screen) {
			width: 250px;
		}

		@include break(tablet) {
			width: 100%;
			bottom: -100px;
			right: unset;
			left: 5%;
		}

		svg {
			width: 100%;
			overflow: hidden;
		}
	}

	.title-h2 {
		color: $primary-orange;
		margin-bottom: 60px;
		max-width: 600px;

		@include break(tablet) {
			margin-bottom: $pad-med_mobile;
			max-width: unset;
		}
	}

	.slider-quotes {
		max-width: 650px;

		@include break(tablet) {
			max-width: 90vw;
			z-index: 0;
		}

		.swiper-wrapper {
			padding-bottom: 50px;
		}

		.quote {
			color: $dark;
			@include font-light;
			@include rem(36px);
			line-height: 120%;

			@include break(tablet) {
				@include rem(26px);
			}
		}

		.image {
			margin-top: $pad-large_mobile;
		}

		.image {
			display: flex;
			justify-content: flex-end;
		}

		.swiper-pagination {
			display: flex;
			height: 10px;
			align-items: center;
			transition: all 0.25s ease-out;
		}
	}
}
