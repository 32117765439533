/*  --- Variable ---  */
$font-size: 18px;
// $header-h: 120px;
// $header-h_mobile: 112px;

/*  --- Padding ---  */
$pad-spacing: 16px;
$pad-xsmall: 20px;
$pad-small: 32px;
$pad-med: 40px;
$pad-large: 80px;
$pad-xlarge: 120px;

$pad-med_mobile: 32px;
$pad-large_mobile: 40px;
$pad-xlarge_mobile: 40px;

$pad-section: 60px;

//COLOR
$dark: #333333;
$white: #FFFFFF;
$grey: #F1F1F1;
$light-grey: #FDFDFD;

$primary-orange: #FF5800;
$primary-yellow: #FECB00;

$secondary-grey: #D8D9D3;
$secondary-light-grey: #F8F6F5;
$secondary-pink: #EDA2CD;
$secondary-purple: #855CB2;
$secondary-dark-purple: #47228A;
$secondary-plum: #B3196D;
$secondary-wine: #580039;
$secondary-red: #F00000;
$secondary-neon-orange: #FF3E00;
$secondary-lemon: #FFB700;
$secondary-pastel-green: #D2EB97;
$secondary-green-lemon: #9EC200;
$secondary-green: #00A028;
$secondary-dark-green: #008239;
$secondary-cyan: #00CBE2;
$secondary-blue: #004CA1;
