.module-20-tech-specs {
	padding: 120px 0;
	background-color: $secondary-light-grey;

	@include break(tablet) {
		padding: 60px 0;
	}

	.row {
		flex-direction: column;
	}

	.title-h2 {
		color: $primary-orange;
		margin-bottom: $pad-section;

		@include break(tablet) {
			margin-bottom: $pad-med_mobile;
		}
	}

	.wrapper-specs {
		position: relative;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 24px;
		height: 100%;
		max-height: 210px;
		overflow: hidden;
		transition: max-height 0.25s ease-out;

		@include break(tablet) {
			max-height: 440px;
			display: flex;
			flex-direction: column;
			gap: 15px;
		}

		.field {
			.field-title {
				@include rem(20px);
				margin-bottom: 7px;

				@include break(tablet) {
					@include rem(18px);
				}
			}

			.field-value {
				color: #5a5959;

				@include break(tablet) {
					@include rem(16px);
				}
			}
		}

		.asterisk {
			position: relative;
			grid-column: span 3;
			z-index: 2;

			@include rem(18px);
			@include break(tablet) {
				@include rem(16px);
			}
		}

		.filter {
			position: absolute;
			bottom: 0;
			left: 0;
			display: block;
			height: 75px;
			width: 100%;
			background: linear-gradient(
				180deg,
				rgba(248, 246, 245, 0) 0%,
				$secondary-light-grey 43.5%
			);
			z-index: 1;
			opacity: 1;
			transition: opacity 0.25s ease-out;
		}

		&.show {
			max-height: 100%;

			.filter {
				opacity: 0;
			}
		}
	}

	.btn-read-more {
		color: $dark;
		@include rem(16px);
		@include font-medium;
		background: none;
		border: none;
		border-radius: 0;
		line-height: 150%;
		cursor: pointer;

		.icon {
			display: flex;
			align-items: center;
			transition: all 0.25s ease-out;
		}

		&.show {
			.icon {
				transform: rotate(-180deg);
			}
		}
	}
}
