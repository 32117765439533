@mixin font-face-global($font-name, $file-name, $font-weight, $font-style: normal) {
  @font-face {
    font-family: quote($font-name);
    src: url("../fonts/#{$file-name}.eot");
    src: url("../fonts/#{$file-name}.eot?#iefix") format("embedded-opentype"),
         url("../fonts/#{$file-name}.woff2") format("woff2"),
         url("../fonts/#{$file-name}.woff") format("woff"),
         url("../fonts/#{$file-name}.ttf") format("truetype"),
         url("../fonts/#{$file-name}.svg##{$file-name}") format("svg");
    font-weight: $font-weight;
    font-style: $font-style;
    font-display: swap;
  }
}

@include font-face-global('Campton Black', 'Campton-Black', 900);
@include font-face-global('Campton Bold', 'Campton-Bold', 700, normal);
@include font-face-global('Campton Medium', 'Campton-Medium', 500, normal);
@include font-face-global('Campton Medium Italic', 'Campton-MediumItalic', 500, italic);
@include font-face-global('Campton Light', 'Campton-Light', 300, normal);
@include font-face-global('Campton Light Italic', 'Campton-LightItalic', 300, italic);
@include font-face-global('Campton ExtraLight', 'Campton-ExtraLight', 250, normal);
@include font-face-global('Campton ExtraLight Italic', 'Campton-ExtraLightItalic', 250, italic);

//FONT
@mixin font-black {
  font-family: 'Campton Black', sans-serif;
  font-weight: 900;
}

@mixin font-bold {
  font-family: 'Campton Bold', sans-serif;
  font-weight: 700;
}

@mixin font-medium {
  font-family: 'Campton Medium', sans-serif;
  font-weight: 500;
}

@mixin font-medium-italic {
  font-family: 'Campton Medium Italic', sans-serif;
  font-weight: 500;
  font-style: italic;
}

@mixin font-light {
  font-family: 'Campton Light', sans-serif;
  font-weight: 300;
}

@mixin font-light-italic {
  font-family: 'Campton Light Italic', sans-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin font-extralight {
  font-family: 'Campton ExtraLight', sans-serif;
  font-weight: 250;
  font-style: normal;
}

@mixin font-extralight-italic {
  font-family: 'Campton ExtraLight Italic', sans-serif;
  font-weight: 250;
  font-style: italic;
}