.module-blog-01-content {
    margin-bottom: $pad-section;

    >*:not(:last-child) {
        margin-bottom: $pad-xsmall;
    }

    p {
        font-size: 19px;
    }
}