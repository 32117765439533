@import 'partials/mixin';
@import 'partials/function';
@import "partials/variable";
@import 'partials/breakpoint';

@import "./base/wordpress";
@import "./base/reset.scss";
@import "./base/anim-class";
@import './base/normalize';
@import './base/easing';
@import './base/fonts';
@import './base/editorial';
@import "./base/general";
@import "./base/woocommerce";

@import './components/form';
@import './components/slider';
@import './components/buttons';
@import './components/breadcrumb';
@import './components/alert';
@import './components/mini-cart';
@import './components/popup';

@import './layout/layout';
@import './layout/header';
@import './layout/footer';

@import './modules/module-01-hero';
@import './modules/module-02-content';
@import './modules/module-03-selling-points';
@import './modules/module-04-banner-text';
@import './modules/module-05-rolling-number';
@import './modules/module-06-banner-full-image';
@import './modules/module-07-banner-rounded-angles';
@import './modules/module-08-social-media';
@import './modules/module-09-quotes-carousel';
@import './modules/module-10-blog-articles-carousel';
@import './modules/module-11-FAQ';
@import './modules/module-12-dealer-locator';
@import './modules/module-13-events';
@import './modules/module-14-teams';
@import './modules/module-15-contact-form';
@import './modules/module-16-sidebar-tab';
@import './modules/module-17-tabs-bike';
@import './modules/module-18-recommended-products';
@import './modules/module-19-key-features';
@import './modules/module-20-tech-specs';
@import './modules/module-21-featured-products';
@import './modules/cpt/module-blog-01-content';
@import './modules/cpt/module-blog-02-quote';
@import './modules/cpt/module-blog-03-medias';

@import './pages/home';
@import './pages/template-blog';
@import './pages/template-shop';
@import './pages/template-cart';
@import './pages/template-checkout';
@import './pages/template-account';
@import './pages/template-search';
@import './pages/template-faqs';
@import './pages/quatrecentquatre';
@import './pages/cpt/single-blog';
@import './pages/cpt/single-product';

@import './woocommerce/form';
@import './woocommerce/login';
@import './woocommerce/dashboard';
@import './woocommerce/orders';
@import './woocommerce/cart';
@import './woocommerce/addresses';
@import './woocommerce/edit-account';
@import './woocommerce/warranty';
@import './woocommerce/giftcards';