.template-search-result {
	.title-h1 {
		text-align: center;

		.result-number {
			color: $primary-orange;
		}
	}

	.search-bar {
		padding-top: 193px;
		padding-bottom: 80px;
		background-color: $primary-yellow;

		@include break(tablet) {
			padding-top: 150px;
			padding-bottom: $pad-large_mobile;
		}

		.seach_container {
			width: 100%;
			max-width: 970px;
			margin-left: auto;
			margin-right: auto;
			margin-top: 52px;

			h4 {
				margin-bottom: 0;
				padding-bottom: 24px;
			}

			.search-bar {
				margin-top: 24px;
				margin-bottom: 50px;
				width: 100%;
			}

			form > div {
				display: flex;
				align-items: baseline;
				width: 100%;
				position: relative;

				input[type="text"] {
					height: 74px;
					width: 100%;
					padding-left: 32px;
					padding-right: 220px;
					margin-top: 0;
					margin-bottom: 0;
					color: $dark;
					background-color: $secondary-light-grey;
					border: 1px solid $secondary-grey;
					border-radius: 60px;

					@include break(tablet) {
						height: 64px;
						padding-left: 20px;
						padding-right: 20px;
						margin-right: 0;
					}

					&::placeholder {
						color: $dark;
					}
				}

				#searchsubmit {
					height: 62px;
					width: auto;
					position: absolute;
					top: 50%;
					margin: 0;
					right: 6px;
					text-align: left;
					padding-right: 60px;
					transform: translateY(-50%);
					color: $white;
					background-color: $primary-orange;
					@include font-bold;
					@include rem(20px);

					@include break(tablet) {
						color: $primary-orange;
						padding: 0;
						width: 62px;
						height: 54px;
					}
				}

				.relevanssi-live-search-results-showing {
					top: 0 !important;
					left: 20px !important;
					right: 20px !important;
					border-color: $primary-orange !important;
					width: auto !important;
					margin-top: -5px;

					a {
						text-decoration: none;
						color: $primary-orange;

						&:hover {
							color: $primary-orange;
						}
					}
				}

				.relevanssi-live-search-result-status {
					display: none !important;
				}
			}

			.form_wrap {
				position: relative;

				.icon {
					position: absolute;
					right: 35px;
					top: 50%;
					transform: translateY(-50%);
					z-index: 3;
					pointer-events: none;

					@include break(tablet) {
						top: 50%;
						right: 25px;
					}
				}
			}

			.tags-container {
				max-width: 30%;
			}

			.title-h4 {
				padding-top: $pad-section;
				padding-bottom: 18px;
				@include font-medium;
				@include rem(16px);
				line-height: 140%;
			}

			.tag_wrap {
				display: flex;
				flex-direction: column;
				gap: 10px;

				.tag {
					padding: 5px 14px;
					border-radius: 30px;
					border: 1px solid $secondary-grey;
					background: $white;
					@include rem(18px);
					line-height: 140%;
					text-decoration: none;
					width: fit-content;
					transition: all 0.25s ease-out;

					&:hover {
						background: $secondary-light-grey;
						border: 1px solid $secondary-light-grey;
						color: $primary-orange;
					}
				}
			}
		}
	}

	.row-products {
		padding-top: 90px;

		@include break(tablet) {
			padding-top: 0;
		}
	}

	.sidebar {
		@include break(tablet) {
			display: block;
		}

		.title-h4 {
			@include font-medium;
			@include rem(18px);
			line-height: 140%;
			margin-bottom: 24px;
		}

		#search-filters,
		#search-filters-mobile {
			display: flex;
			flex-direction: column;
			gap: 10px;
			padding-right: 100px;

			.filter-others {
				margin-top: 0;
				display: flex;
				flex-direction: column;
				gap: 10px;

				label {
					display: flex;
					align-items: center;
					padding: 12px 16px;
					border-radius: 10px;
					border: 3px solid $secondary-grey;
					transition: all 0.3s ease-out;

					&:focus,
					&:hover {
						border: 3px solid $primary-orange;
					}
				}
			}
		}

		#search-filters-mobile {
			@include break(tablet) {
				margin-top: $pad-med_mobile;
				padding-right: 0;
			}
		}

		.filters {
			@include break(desktop-only) {
				border-bottom: 0;
				@include font-medium;
			}
		}

		.filter-desktop {
			display: block;

			@include break(tablet) {
				display: none;
			}
		}

		.filter-mobile {
			display: none;

			@include break(tablet) {
				display: block;
			}

			.filters-header {
				z-index: 1;
			}

			.sidebar {
				display: flex;
				flex-direction: column;
				padding: 0 16px;

				.sidebar-header {
					width: 100%;
					position: relative;
					text-align: center;
					margin-top: 20px;
					@include rem(20px);
					@include font-black;
					line-height: 140%;
					border-bottom: 3px solid $dark;
					padding-bottom: 16px;

					.title-h4 {
						@include font-bold;
						@include rem(20px);
						line-height: 140%;
						margin-bottom: 0;
					}
				}
			}
		}
	}

	.col-products {
		.card-row {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 25px;
			margin-bottom: 0;

			@include break(tablet) {
				grid-template-columns: 1fr;
				gap: 12px;
			}

			.card-post {
				border-radius: 40px;
				overflow: hidden;

				.wrapper-media {
					position: relative;
					height: 262px;

					@include break(mobile) {
						max-height: 250px;
					}

					img {
						object-fit: cover;
						width: 100%;
						height: 100%;
					}
				}

				.wrapper-text {
					padding: 16px 28px;
					height: 193px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: space-between;
					background-color: $secondary-light-grey;

					@include break(tablet) {
						padding: 16px 30px;
						min-height: 180px;
					}
				}

				.title-h6 {
					margin: 0;
					text-align: center;
					text-overflow: ellipsis;
					@include rem(22px);
					@include font-bold;
					line-height: 120%;

					@include break(tablet) {
						@include rem(22px);
					}
				}

				.description {
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 3;
					overflow: hidden;
					text-overflow: ellipsis;
					text-align: center;
				}

				strong {
					color: $primary-orange;
				}

				.category {
					position: absolute;
					bottom: -12px;
					left: 20px;
					padding: 8px 20px;
					@include rem(15px);
					@include font-bold;
					border-radius: 50px;
					line-height: 100%;
					z-index: 1;

					@include break(tablet) {
						left: 10px;
						padding: 8px 18px;
					}
				}

				.btn-read-more {
					padding: 10px 28px;
					@include rem(20px);
					color: $primary-orange;
					line-height: 150%;

					.icon {
						display: flex;
						align-items: center;

						svg {
							path {
								fill: $primary-orange;
							}
						}
					}
				}

				&.type-post {
					&.cat {
						&-community,
						&-communauté {
							.wrapper-media {
								border-bottom: 12px solid $secondary-pastel-green;
							}

							.category {
								background-color: $secondary-pastel-green;
							}
						}

						&-guides,
						&-how {
							.wrapper-media {
								border-bottom: 12px solid $secondary-pink;
							}

							.category {
								background-color: $secondary-pink;
							}
						}

						&-products,
						&-produits,
						&-produkte {
							.wrapper-media {
								border-bottom: 12px solid $primary-yellow;
							}

							.category {
								background-color: $primary-yellow;
							}
						}
					}
				}

				&.type-docs {
					.wrapper-media {
						background-color: $primary-yellow;
						display: flex;
						align-items: center;
						padding: 45px;
					}

					.wrapper-text {
						padding: 16px 45px;
						overflow: hidden;
						text-overflow: ellipsis;
						@include rem(18px);
						line-height: 140%;
					}
				}
				&.type-product {
					.description {
						@include rem(16px);
					}
				}
			}
		}
	}

	.no_result {
		padding: 120px 0;
		text-align: center;
	}
}

.results-container {
	.result-item {
		@include break(tablet) {
			display: flex;
			gap: 20px;
		}

		.wrapper-media {
			border-radius: 24px;
			height: 130px;
			width: 100%;
			overflow: hidden;
			margin-bottom: 10px;

			@include break(tablet) {
				margin-bottom: 0;
				width: 103px;
				height: 103px;
				background-color: $secondary-light-grey;
			}
		}

		.wrapper-text {
			.title-h6 {
				color: $primary-orange;
				@include rem(16px);
				@include font-black;
				line-height: 150%;
				margin: 0;
			}
			.sku {
				color: #c2c2c2;
				@include rem(13px);
				line-height: 150%;
			}

			.wrapper-info {
				margin-bottom: 10px;
			}

			.price {
				color: $primary-yellow;
				@include rem(16px);
				@include font-bold;
				line-height: 150%;
			}
		}

		&.type-docs {
			.wrapper-media {
				background-color: $primary-yellow;
				padding: 10px;
				display: flex;
				align-items: center;

				.title-h6 {
					@include rem(13px);
					text-align: center;
					margin: 0;
				}
			}
		}

		&.type-post {
			.wrapper-media {
				position: relative;
				margin-bottom: 0;
				overflow: initial;

				picture {
					display: flex;
					height: 100%;
					width: 100%;
					border-radius: 24px 24px 0 0;
					overflow: hidden;

					img {
						height: 100%;
						width: 100%;
						object-fit: cover;
					}
				}

				.category {
					position: absolute;
					bottom: -10px;
					left: 10px;
					padding: 1px 5px;
					border-radius: 50px;
					width: fit-content;
					line-height: 140%;
					z-index: 1;
				}
			}

			.wrapper-text {
				border-radius: 0 0 24px 24px;
			}

			.wrapper-info {
				margin-bottom: 0;
				padding: 10px 15px;
			}

			.title-h6 {
				color: $dark;
				@include font-bold;
				line-height: 140%;
				margin: 0;
			}
			.category {
				@include rem(13px);
			}
			&.cat {
				&-community,
				&-communauté {
					.wrapper-text,
					.category {
						background-color: $secondary-pastel-green;
					}
				}

				&-guides,
				&-how {
					.wrapper-text,
					.category {
						background-color: $secondary-pink;
					}
				}

				&-products,
				&-produits,
				&-produkte {
					.wrapper-text,
					.category {
						background-color: $primary-yellow;
					}
				}
			}
		}
	}
}
