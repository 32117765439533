/* ==== Layout ==== */
.container {
	width: 95%;
	margin-left: auto;
	margin-right: auto;
	max-width: $container;
	
	@include break(tablet) {
		width: 90%;
	}
}

.row {
	display: flex;
	flex-wrap: wrap;
}

.col {
	flex: 1;

	&.col-1 {
		@include col-width(1);
	}

	&.col-2 {
		@include col-width(2);
	}

	&.col-3 {
		@include col-width(3);
	}

	&.col-4 {
		@include col-width(4);
	}

	&.col-5 {
		@include col-width(5);
	}

	&.col-6 {
		@include col-width(6);
	}

	&.col-7 {
		@include col-width(7);
	}

	&.col-8 {
		@include col-width(8);
	}

	&.col-9 {
		@include col-width(9);
	}

	&.col-10 {
		@include col-width(10);
	}

	&.col-11 {
		@include col-width(11);
	}

	&.col-12 {
		@include col-width(12);
	}
}

/* ==== WP WYSIWYG Editor Styles ==== */
.entry-content img {
	margin: 0 0 1.5em 0;
}

.alignleft,
img.alignleft {
	margin-right: 1.5em;
	display: inline;
	float: left;
}

.alignright,
img.alignright {
	margin-left: 1.5em;
	display: inline;
	float: right;
}

.aligncenter,
img.aligncenter {
	margin-right: auto;
	margin-left: auto;
	display: block;
	clear: both;
}

.wp-caption {
	margin-bottom: 1.5em;
	text-align: center;
	padding-top: 5px;
}

.wp-caption img {
	border: 0 none;
	padding: 0;
	margin: 0;
}

.wp-caption p.wp-caption-text {
	line-height: 1.5;
	font-size: 10px;
	margin: 0;
}

.wp-smiley {
	margin: 0 !important;
	max-height: 1em;
}

blockquote.left {
	margin-right: 20px;
	text-align: right;
	margin-left: 0;
	width: 33%;
	float: left;
}

blockquote.right {
	margin-left: 20px;
	text-align: left;
	margin-right: 0;
	width: 33%;
	float: right;
}

.template-archive:not(.template-bikes):not(.template-add-ons),
.betterdocs-search-form-wrapper {
	padding-top: 220px !important;

	@include break (tablet) {
		padding-top: 160px !important;
	}
}

.template-cart,
.template-checkout,
.container-dashboard {
	padding-top: 193px;
	
	@include break (tablet) {
		padding-top: 160px;
	}
}

.template-cpt-product {
	padding-top: 190px !important;

	@include break (tablet) {
		padding-top: 160px !important;
	}
}