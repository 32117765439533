.site-header {
	position: fixed;
	top: 45px;
	left: 0;
	width: 100%;
	z-index: 1002;
	transition: transform 0.3s ease-in-out;

	@include break(tablet) {
		top: 20px;
	}

	.wrapper-nav {
		color: $white;
		background-color: $primary-orange;
		border-radius: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 40px;
		max-height: 84px;
		position: relative;

		@include break(tablet) {
			display: none;
		}

		.filter {
			position: fixed;
			opacity: 0.4;
			pointer-events: all;
			z-index: 0;
			display: none;
		}
	}

	.wrapper-nav-mobile {
		display: none;

		@include break(tablet) {
			display: flex;
			color: $white;
			background-color: $primary-orange;
			border-radius: 12px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 16px;
			max-height: 60px;
		}

		.site-logo {
			margin: 0 10px;

			a {
				display: block;
				width: 127px;
			}

			svg {
				max-width: 100%;
				width: 100%;
			}
		}

		.btn-search,
		.btn-cart {
			padding: 0;
		}
	}

	nav {
		.primary-menu {
			display: inline-flex;
			@include rem(16px);
		}

		li {
			&.top-title {
				@include break(desktop-only) {
					cursor: pointer;
					padding: 30px 12px;
					position: relative;

					&::after {
						content: "";
						height: 10px;
						width: 100%;
						position: absolute;
						top: 100%;
						left: 0;
					}
				}
			}

			.top-title-link {
				position: relative;
				cursor: pointer;
				pointer-events: none;

				&::after {
					content: "";
					position: absolute;
					bottom: -8px;
					left: 0;
					display: block;
					width: 0;
					height: 2px;
					background-color: $white;
					pointer-events: none;
					transition: width 0.25s ease-out;
				}

				&:hover {
					&::after {
						width: 100%;
					}
				}
			}

			&.active {
				.top-title-link {
					&::after {
						width: 100%;
					}
				}
			}
		}

		&.nav-mobile {
			display: none;
			padding-top: 80px;

			&.active {
				display: flex;
				flex-direction: column;
				position: absolute;
				top: 0;
				left: 0;
				height: 100vh;
				width: 100%;
				background-color: $white;
				color: $dark;
				z-index: -1;
			}

			.title-h3,
			.primary-menu {
				width: 90%;
				margin-left: auto;
				margin-right: auto;
				max-width: 1485px;
			}

			.title-h3 {
				@include font-black;
				@include rem(26px);
				line-height: 100%;
				margin-bottom: $pad-section;
			}

			.primary-menu {
				flex-direction: column;
			}
		}
	}

	.wrapper-btn {
		display: flex;
		justify-content: space-between;

		.btn-language {
			@include rem(14px);
			margin-right: 10px;

			.wpml-ls-legacy-dropdown {
				width: 35px;

				a {
					color: $white;
					border: none;
					background-color: transparent;
					padding: 0;
					@include rem(14px);
					line-height: 150%;
					text-align: left;

					&.wpml-ls-item-toggle {
						&:after {
							content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.8781 6.76923L8.46875 12.1786L3.05938 6.76923L3.78063 6.04798L8.46875 10.7361L13.1569 6.04798L13.8781 6.76923Z' fill='white'/%3E%3C/svg%3E");
							right: 0;
							top: 0;
							border: 0;
						}
					}
				}

				.wpml-ls-sub-menu {
					display: none;
				}
			}
		}

		.btn-search,
		.btn-account,
		.btn-cart {
			svg {
				pointer-events: none;
				padding: 1px;
			}
		}

		.btn-bloc {
			display: flex;
			gap: 10px;
		}
	}

	// NAV DESKTOP
	.nav_desktop {
		background-color: $white;
		position: absolute;
		z-index: 999;
		top: calc(100% - 15px);
		left: 0;
		right: 0;
		border-radius: 0px 0px 20px 20px;
		display: none;
		overflow: hidden;
		height: auto;
		opacity: 0;
		width: 95%;
		margin-left: auto;
		margin-right: auto;
		max-width: 1485px;
		z-index: -1;
		box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.08);

		.menu {
			position: relative;
			height: 70vh;
			margin: calc(50px + 15px) 40px;

			@include break(small-screen) {
				height: 74vh;
				margin: 40px 5% 60px 5%;
			}
		}

		.menu_tab {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			overflow-y: scroll;
			overflow-x: hidden;

			&::-webkit-scrollbar {
				width: 0 !important;
			}

			overflow: -moz-scrollbars-none;
			-ms-overflow-style: none;
			display: none;
			justify-content: space-between;
			width: 100%;

			&.page {
				.col_content {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					width: 100%;

					.links-content {
						display: grid;
						grid-template-columns: repeat(2, 1fr);
						width: 100%;
						height: fit-content;
					}

					.link-pages {
						@include rem(28px);
						line-height: 150%;
						padding: 22px 25px;
						border-radius: 10px;

						&:hover {
							background-color: $secondary-light-grey;
							color: $primary-orange;
						}
					}
				}

				.col_title {
					justify-content: flex-start;
					padding-right: 0;
					padding-left: 40px;
					margin-right: 0;
					margin-left: 5%;
					border-right: 0;
					border-left: 1px solid $secondary-grey;

					&.article {
						border-left: 0;
					}
				}

				.title-h3 {
					color: $primary-yellow;
					@include rem(26px);
					line-height: 140%;
					margin-bottom: 23px;
				}

				.product-highlight {
					display: flex;
					align-items: center;
					gap: 22px;
					padding: 15px 25px;
					border-radius: 20px;
					width: 100%;

					&:hover {
						background-color: $secondary-light-grey;
						color: $primary-orange;
					}

					picture {
						max-height: 45px;
						display: flex;

						.img {
							max-width: 68px;
							object-fit: cover;
						}
					}

					.title-h4 {
						@include font-black;
						@include rem(20px);
						line-height: 120%;
					}
				}
			}
		}

		.col_title {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: space-between;
			width: 397px;
			padding-right: 40px;
			flex-shrink: 0;
			border-right: 1px solid $secondary-grey;
			margin-right: 5%;

			@include break(small-screen) {
				width: 300px;
				padding-right: 20px;
				margin-right: 2.5%;
			}

			.category-item {
				display: flex;
				align-items: center;
				gap: 20px;
				padding: 18px 6px;
				border-radius: 10px;
				cursor: pointer;
				transition: 0.25s all ease-out;

				&.active,
				&:hover {
					background-color: $secondary-light-grey;

					.category-title {
						color: $primary-orange;
					}
				}
			}

			& > ul {
				width: 100%;
				padding-left: 0;
			}

			.category-title {
				@include rem(28px);
				@include font-medium;
				line-height: 150%;
				margin-bottom: 0;
				transition: 0.25s all ease-out;
			}

			.icon {
				padding: 11px 0;
				width: 100px;
				display: flex;
				justify-content: flex-end;

				img {
					max-width: 100px;
				}
			}

			.txt {
				font-size: 16px;
				margin-top: 8px;
			}

			.btn {
				margin-top: 32px;
			}
		}

		.col_content {
			.content-item {
				flex-direction: column;
				.content-description {
					margin-bottom: $pad-small;

					.title-h3 {
						color: $primary-orange;
						margin-bottom: 10px;

						a {
							display: flex;
							align-items: center;
							gap: 6px;
						}

						.icon {
							display: flex;
							align-items: center;
						}
					}
					.description {
						@include rem(18px);
						line-height: 140%;
					}
				}

				.product-list {
					display: grid;
					grid-template-columns: repeat(4, 1fr);
					gap: 20px;

					&.list-full {
						grid-template-columns: repeat(6, 1fr);

						.product-item {
							grid-column: span 2;

							.product-image {
								height: 200px;

								.shadow {
									width: 75%;
									top: 75%;
								}
							}
						}
					}

					.product-image {
						position: relative;
						display: flex;
						align-items: center;
						justify-content: center;
						height: 282px;
						padding: 85px 12px;
						margin-bottom: 16px;
						border-radius: 40px;
						background-color: $secondary-light-grey;

						.shadow {
							display: block;
							width: 70%;
							height: 20px;
							position: absolute;
							top: 63%;
							left: 50%;
							transform: translate(-50%, -50%);
							border-radius: 50%;
							background-color: $dark;
							-webkit-filter: blur(8px);
							-moz-filter: blur(8px);
							-o-filter: blur(8px);
							-ms-filter: blur(8px);
							filter: blur(8px);
							opacity: 0.3;

							@include break(tablet) {
								top: 41%;
							}
						}
					}

					.title-h4 {
						text-align: center;
						@include rem(20px);
						@include font-black;
						line-height: 120%;
					}
				}
			}

			.btn-link-menu {
				justify-content: flex-start;
			}
		}

		.btn-link-menu {
			border: none;
			color: $primary-orange;
			text-decoration: underline;
			padding: 17px 0;
			@include rem(20px);
			@include font-bold;
			line-height: 150%;
		}

		.article-highlight {
			border-radius: 40px;
			overflow: hidden;

			.wrapper-media {
				height: 100%;
				max-height: 265px;
				@include break(mobile) {
					max-height: 250px;
				}

				img {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}

			.wrapper-text {
				position: relative;
				padding: 30px 28px;
				height: auto;

				.title-h3 {
					color: $dark;
					@include rem(22px);
					line-height: 140%;
					margin-bottom: 0;
				}

				.icon {
					padding: 0;
				}

				.btn-link {
					margin-top: 28px;
					margin-bottom: 25px;
					border: none;

					.icon {
						width: 18px;
					}
				}
			}

			.category {
				position: absolute;
				top: -20px;
				left: 15px;
				padding: 12px 10px;
				@include rem(16px);
				@include font-bold;
				border-radius: 50px;
				text-transform: capitalize;
				line-height: 100%;
			}

			&.cat-guides,
			&.cat-how {
				.wrapper-text,
				.category {
					background-color: $secondary-pink;
				}
			}

			&.cat-products,
			&.cat-produits,
			&.cat-produkte {
				.wrapper-text,
				.category {
					background-color: $primary-yellow;
				}
			}

			&.cat-community,
			&.cat-communauté {
				.wrapper-text,
				.category {
					background-color: $secondary-pastel-green;
				}
			}
		}

		.top {
			background-color: $white;
			top: -20px;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			z-index: 2;

			@include break(small-screen) {
				top: 0;
			}

			.btn_close {
				display: flex;
				align-items: center;
				border-radius: 50px;
				cursor: pointer;
				transition: all 0.4s;

				svg path {
					transition: all 0.4s;
				}

				&:hover {
					background-color: rgba($primary-orange, 1);

					path {
						fill: $white;
					}
				}
			}
		}

		.menu_tab-search {
			overflow: initial;

			.seach_container {
				width: 100%;
				max-width: 970px;
				margin-left: auto;
				margin-right: auto;
				margin-top: 40px;

				h4 {
					margin-bottom: 0;
					padding-bottom: 24px;
				}

				.search-bar {
					margin-top: 24px;
					margin-bottom: 50px;
					width: 100%;
				}

				form > div {
					display: flex;
					align-items: baseline;
					width: 100%;
					position: relative;

					input[type="text"] {
						height: 74px;
						width: 100%;
						padding-left: 32px;
						padding-right: 220px;
						margin-top: 0;
						margin-bottom: 0;
						color: $dark;
						background-color: $secondary-light-grey;
						border: 1px solid $secondary-grey;
						border-radius: 60px;

						&::placeholder {
							color: $dark;
							@include rem(26px);
						}

						@include break(mobile) {
							padding-left: 20px;
							padding-right: 20px;
							margin-right: 0;
						}
					}

					#searchsubmit {
						height: 62px;
						width: auto;
						position: absolute;
						top: 50%;
						margin: 0;
						right: 6px;
						text-align: left;
						padding-right: 60px;
						transform: translateY(-50%);
						color: $white;
						background-color: $primary-orange;
						@include font-bold;
						@include rem(20px);
						z-index: 1000;

						@include break(mobile) {
							top: 5px;
							color: $primary-orange;
							padding: 0;
							width: 58px;
						}

						&[disabled] {
							background-color: $secondary-grey;
							cursor: not-allowed;
							opacity: 0.6;
						}
					}

					.relevanssi-live-search-results-showing {
						top: 0 !important;
						left: 20px !important;
						right: 20px !important;
						border-color: $primary-orange !important;
						width: auto !important;
						margin-top: -5px;

						a {
							text-decoration: none;
							color: $primary-orange;

							&:hover {
								color: $primary-orange;
							}
						}
					}

					.relevanssi-live-search-result-status {
						display: none !important;
					}
				}

				.form_wrap {
					position: relative;

					.icon {
						position: absolute;
						right: 35px;
						top: 50%;
						transform: translateY(-50%);
						z-index: 1000;
						pointer-events: none;

						@include break(mobile) {
							top: 50%;
							right: 16px;
						}
					}
				}

				.tags-container {
					max-width: 30%;
				}

				.title-h4 {
					padding-top: $pad-section;
					padding-bottom: 18px;
					@include font-medium;
					@include rem(16px);
					line-height: 140%;
				}

				.results-container {
					.title-h4 {
						display: none;

						&.show {
							display: block;
						}
					}
				}

				.tag_wrap {
					display: flex;
					flex-direction: column;
					gap: 10px;

					.tag {
						padding: 5px 14px;
						border-radius: 30px;
						border: 1px solid $secondary-grey;
						background: $white;
						@include rem(18px);
						line-height: 140%;
						text-decoration: none;
						width: fit-content;
						transition: all 0.25s ease-out;

						&:hover {
							background: $secondary-light-grey;
							border: 1px solid $secondary-light-grey;
							color: $primary-orange;
						}
					}
				}

				.results-wrap {
					display: grid;
					grid-template-columns: repeat(5, 1fr);
					gap: 20px;
				}
			}
		}
	}

	// NAV MOBILE
	.nav_mobile-btn {
		display: none;
		position: relative;
		width: 40px;
		height: 40px;
		cursor: pointer;

		@include break(small-screen) {
			display: block;
		}

		&.active {
			.burger {
				span {
					&:first-of-type {
						transform: translateY(-50%) rotate(45deg);
						top: 50%;
					}

					&:nth-child(2) {
						opacity: 0;
					}

					&:last-of-type {
						transform: translateY(-50%) rotate(-45deg);
						top: 50%;
						bottom: auto;
					}
				}
			}
		}

		.burger {
			margin: auto;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			width: 25px;
			height: 15px;

			span {
				position: absolute;
				display: block;
				width: 100%;
				height: 2px;
				left: 0;
				background-color: $white;
				transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);

				&:first-of-type {
					top: 0;
				}

				&:nth-child(2) {
					opacity: 1;
					top: 50%;
					transform: translateY(-50%);
				}

				&:last-of-type {
					bottom: 0;
				}
			}
		}
	}

	.nav_mobile {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: calc(80dvh - env(safe-area-inset-bottom));
		min-height: calc(80dvh - env(safe-area-inset-bottom));
		margin-top: -20px;
		z-index: -1;

		.btn-close-nav-mobile {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0;
			border: none;
			background: none;
			margin-top: -5px;
		}

		.nav-mobile {
			padding: calc(34px + 15px) 16px 34px 16px;

			&.active {
				height: calc(100dvh - env(safe-area-inset-bottom));
				min-height: calc(100dvh - env(safe-area-inset-bottom));
			}

			.wrapper-title {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
			}

			.primary-menu {
				gap: 28px;
				width: 100%;
				margin-left: initial;
				margin-right: initial;
				max-width: 100%;
			}

			.top-title {
				@include rem(22px);
				line-height: 150%;

				&-link {
					height: 100%;
					display: flex;
					align-items: center;
					gap: 4px;
				}

				.icon {
					display: flex;
					align-items: center;
					height: 100%;

					svg {
						height: 100%;
						width: 100%;
					}
				}
			}

			.btn-language {
				@include rem(14px);
				margin-right: 10px;
				padding: 30px 0;
				position: sticky;
				position: -webkit-sticky;
				bottom: 0;
				top: 100%;
				left: 0;

				&::before {
					display: none;
				}

				&::after {
					content: "";
					position: absolute;
					left: -5%;
					top: 0;
					height: 1px;
					width: 115%;
					display: block;
					background-color: $secondary-grey;
				}

				.wpml-ls-legacy-dropdown {
					width: 100%;
					pointer-events: none;

					a {
						color: $dark;
						border: none;
						background-color: transparent;
						padding: 0;
						@include rem(18px);
						line-height: 150%;
						text-align: left;

						&.wpml-ls-item-toggle {
							&:after {
								content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.8781 6.76923L8.46875 12.1786L3.05938 6.76923L3.78063 6.04798L8.46875 10.7361L13.1569 6.04798L13.8781 6.76923Z' fill='black'/%3E%3C/svg%3E");
								right: 0;
								top: 0;
								border: 0;
							}
						}
					}

					.wpml-ls-sub-menu {
						display: none;
					}
				}
			}
		}

		.menu_tab {
			scroll-behavior: smooth;
			padding: calc(34px + 15px) 16px 34px 16px;
			flex-direction: column;
			background-color: $white;
			height: calc(100% - env(safe-area-inset-bottom));
			min-height: calc(100dvh - env(safe-area-inset-bottom));
			border-radius: 0 0 12px 12px;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			display: none;
			overflow-y: auto;
			overflow-x: hidden;
			z-index: -1;
			scrollbar-width: none;
			-ms-overflow-style: none;

			&::-webkit-scrollbar {
				display: none;
			}

			.top {
				flex-shrink: 0;
				display: flex;
				align-items: center;

				.wrapper-title {
					display: flex;
					justify-content: space-between;
					align-items: flex-start;
					width: 100%;
				}
			}

			.btn-link-menu {
				position: fixed;
				bottom: 40px;
				left: 0;
				width: 100%;
				@include rem(18px);
				text-decoration: underline;
				color: $primary-orange;
				background-color: $secondary-light-grey;
				border-radius: 0;
				border: none;

				&-sub {
					position: sticky;
					position: -webkit-sticky;
					bottom: 0;

					&::after {
						content: "";
						position: absolute;
						top: 0;
						left: -5%;
						display: block;
						width: 110%;
						height: 100%;
						background-color: $secondary-light-grey;
						z-index: -1;
					}
				}
			}

			.back {
				display: flex;
				align-items: center;

				svg {
					margin-right: 8px;
				}
			}

			.title-h3 {
				display: block;
				@include rem(26px);
				@include font-black;
				line-height: 100%;
				text-decoration: none;
				display: flex;
				align-items: center;

				svg {
					margin-left: 12px;
				}
			}

			.btn_close {
				margin-left: auto;
			}

			.content {
				padding-top: $pad-section;

				@include break(tablet) {
					height: fit-content;
					display: block;
				}

				&.content_sub {
					margin-top: $pad-med_mobile;

					.content-description {
						margin-bottom: $pad-med_mobile;
					}
				}

				.col_title {
					ul {
						margin: 0;
						padding: 0;
						display: flex;
						flex-direction: column;
						gap: 28px;
					}

					.category-item {
						display: flex;
						align-items: center;
						gap: 30px;
						border-radius: 10px;
						cursor: pointer;
						transition: 0.25s all ease-out;

						&.active,
						&:hover {
							background-color: $secondary-light-grey;

							.category-title {
								color: $primary-orange;
							}
						}

						.category-title {
							display: flex;
							align-items: center;
							gap: 4px;
							justify-content: space-between;
							width: 100%;

							.icon {
								padding: 0;
								display: flex;
								align-items: center;
							}
						}
					}

					.category-title {
						@include rem(22px);
						@include font-medium;
						line-height: 150%;
						margin-bottom: 0;
						transition: 0.25s all ease-out;
					}

					.icon {
						padding: 11px 0;
						width: 90px;
						min-width: 90px;
						display: flex;
						justify-content: flex-end;
						align-items: center;

						img {
							max-width: 85px;
						}
					}

					.txt {
						font-size: 16px;
						margin-top: 8px;
					}

					.btn {
						margin-top: 32px;
					}
				}

				.product-list {
					display: flex;
					flex-direction: column;
					gap: 12px;

					.product-item {
						display: flex;
						align-items: center;
						gap: 20px;
					}

					.product-image {
						position: relative;
						display: flex;
						align-items: center;
						justify-content: center;
						height: 89px;
						width: 89px;
						padding: 20px 7px;
						border-radius: 20px;
						background-color: $secondary-light-grey;

						.shadow {
							display: block;
							width: 70%;
							height: 20px;
							position: absolute;
							top: 70%;
							left: 50%;
							transform: translate(-50%, -50%);
							border-radius: 50%;
							background-color: $dark;
							-webkit-filter: blur(8px);
							-moz-filter: blur(8px);
							-o-filter: blur(8px);
							-ms-filter: blur(8px);
							filter: blur(8px);
							opacity: 0.2;
						}

						img {
							height: 100%;
							width: 100%;
							object-fit: cover;
						}
					}

					.title-h4 {
						text-align: center;
						@include rem(20px);
						@include font-black;
						line-height: 120%;
					}
				}
			}

			.sub_menu {
				background-color: white;
				z-index: 1;
				height: 100%;

				.product-list {
					padding-bottom: 100px;
				}
			}

			.col_content {
				.links-content {
					display: flex;
					flex-direction: column;
					gap: 28px;
				}

				.link-pages {
					@include rem(22px);
					line-height: 150%;
				}

				.btn-link-menu {
					@include break(tablet) {
						display: none;
					}
				}
			}

			.col_highlight {
				display: block;
				margin-top: $pad-med_mobile;
				padding-top: $pad-med_mobile;
				border-top: 1px solid $secondary-grey;

				.title-h3 {
					color: $primary-yellow;
					@include rem(20px);
					@include font-bold;
					line-height: 140%;
					margin-bottom: 20px;
				}

				.product-highlight {
					padding: 5px 25px;
					display: flex;
					align-items: center;
					gap: 22px;

					&:last-child {
						padding-bottom: $pad-med_mobile;
					}

					img {
						max-width: 68px;
					}

					.title-h4 {
						@include rem(20px);
						line-height: 120%;
					}
				}

				.article-highlight {
					display: block;
					padding-bottom: $pad-med_mobile;

					.wrapper-media {
						height: 100%;
						max-height: 265px;
						border-radius: 40px 40px 0 0;
						overflow: hidden;
						@include break(mobile) {
							max-height: 250px;
						}

						img {
							object-fit: cover;
							width: 100%;
							height: 100%;
						}
					}

					.wrapper-text {
						position: relative;
						border-radius: 0 0 40px 40px;
						padding: 30px 28px;
						height: 100%;

						.title-h3 {
							color: $dark;
							@include rem(22px);
							line-height: 140%;
							margin-bottom: 0;
						}

						.icon {
							padding: 0;
						}

						.btn-link {
							margin-top: 28px;
							border: none;
						}
					}

					.category {
						position: absolute;
						top: -20px;
						left: 15px;
						padding: 12px 10px;
						@include rem(16px);
						@include font-bold;
						border-radius: 50px;
						text-transform: capitalize;
						line-height: 100%;
					}

					&.cat-guides,
					&.cat-how {
						.wrapper-text,
						.category {
							background-color: $secondary-pink;
						}
					}

					&.cat-products,
					&.cat-produits,
					&.cat-produkte {
						.wrapper-text,
						.category {
							background-color: $primary-yellow;
						}
					}

					&.cat-community,
					&.cat-communauté {
						.wrapper-text,
						.category {
							background-color: $secondary-pastel-green;
						}
					}
				}
			}

			&.menu_tab1 {
				.content {
					height: 100%;
				}
				.btn-link-menu-sub {
					top: 100%;
				}
			}
		}

		.menu_tab-search {
			.btn_close_search {
				margin-left: auto;
			}
			.seach_container {
				width: 100%;
				max-width: 970px;
				margin-left: auto;
				margin-right: auto;
				margin-top: 40px;

				h4 {
					margin-bottom: 0;
					padding-bottom: 24px;
				}

				.search-bar {
					margin-top: 24px;
					margin-bottom: 50px;
					width: 100%;
				}

				form > div {
					display: flex;
					align-items: baseline;
					width: 100%;
					position: relative;

					input[type="text"] {
						height: 74px;
						width: 100%;
						padding-left: 32px;
						padding-right: 220px;
						margin-top: 0;
						margin-bottom: 0;
						color: $dark;
						background-color: $secondary-light-grey;
						border: 1px solid $secondary-grey;
						border-radius: 60px;

						&::placeholder {
							color: $dark;
						}

						@include break(mobile) {
							height: 64px;
							padding-left: 20px;
							padding-right: 20px;
							margin-right: 0;
						}
					}

					#searchsubmit {
						height: 62px;
						width: auto;
						position: absolute;
						top: 50%;
						margin: 0;
						right: 6px;
						text-align: left;
						padding-right: 60px;
						transform: translateY(-50%);
						color: $white;
						background-color: $primary-orange;
						@include font-bold;
						@include rem(20px);

						@include break(mobile) {
							color: $primary-orange;
							padding: 0;
							height: 54px;
							width: 62px;
						}
					}

					.relevanssi-live-search-results-showing {
						top: 0 !important;
						left: 20px !important;
						right: 20px !important;
						border-color: $primary-orange !important;
						width: auto !important;
						margin-top: -5px;

						a {
							text-decoration: none;
							color: $primary-orange;

							&:hover {
								color: $primary-orange;
							}
						}
					}

					.relevanssi-live-search-result-status {
						display: none !important;
					}
				}

				.form_wrap {
					position: relative;

					.icon {
						position: absolute;
						right: 35px;
						top: 50%;
						transform: translateY(-50%);
						z-index: 3;
						pointer-events: none;

						@include break(mobile) {
							top: 50%;
							right: 25px;
						}
					}
				}

				.results-container {
					@include break(tablet) {
						margin-top: $pad-med_mobile;

						.title-h4 {
							display: none;
						}
					}
				}

				.title-h4 {
					padding-top: $pad-section;
					padding-bottom: 18px;
					@include font-medium;
					@include rem(16px);
					line-height: 140%;
				}

				.tag_wrap {
					display: flex;
					flex-direction: column;
					gap: 12px;

					.tag {
						padding: 5px 14px;
						border-radius: 30px;
						border: 1px solid $secondary-grey;
						background: $white;
						@include rem(16px);
						line-height: 140%;
						text-decoration: none;
						width: fit-content;
						transition: all 0.25s ease-out;

						&:hover {
							background: $secondary-light-grey;
							border: 1px solid $secondary-light-grey;
							color: $primary-orange;
						}
					}
				}

				.results-wrap {
					display: flex;
					flex-direction: column;
					gap: 12px;
				}
			}
		}
	}
}

.header {
	background-color: $primary-orange;
	padding: $pad-spacing * 2 0;

	.logo {
		@include flex-center;
	}
}
