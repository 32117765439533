.container-gift-cards {
    .woocommerce-MyAccount-Giftcards-balance-amount {
        margin-top: $pad-med_mobile;
        margin-bottom: 18px;

        .amount {
            @include rem(52px);
            line-height: 140%;
        }
    }

    .title-h3 {
        @include rem(22px);
        line-height: 140%;
        margin-bottom: 20px;

        @include break(tablet) {
            @include rem(20px);
            margin-bottom: 18px;
        }
    }

    .woocommerce-Giftcards {

        &.woocommerce-MyAccount-Giftcards-form {
            margin-bottom: $pad-small;
            gap: 25px;

            input {
                margin: 0;
            }
        }

        .btn-primary {
            display: inline-flex;
            padding: 10px 28px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            @include font-bold;
            @include rem(20px);
            line-height: 100%;
            color: $white;
            border-radius: 40px;
            text-wrap: nowrap;

            @include break(tablet) {
                @include rem(18px);
                padding: 15px 36px;
            }


            &:hover {
                color: $primary-orange;
                background-color: $white;
            }
        }
    }

    .woocommerce-orders-table {
        border-radius: 12px;
        padding: 14px 20px;

        &.my_account_orders {
            td {
                padding: 10px;
            }
        }
    }
}