.mini-cart-wrapper {
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	visibility: hidden;
	z-index: 10000;
	pointer-events: none; // Permet de cliquer à travers quand fermé

	&:not(.hidden) {
		visibility: visible;
		pointer-events: auto;
	}

	.mini-cart {
		position: fixed;
		right: 0;
		top: 0;
		width: 543px;
		height: 100%;
		padding: 36px 40px;
		background-color: $white;
		z-index: 9999;

		flex-direction: column;
		justify-content: space-between;
		display: flex;

		transform: translateX(100%);
		will-change: transform;

		@include break(tablet) {
			width: 100%;
			padding: 22px 16px;
		}

		&.hidden {
			visibility: hidden;
		}

		.close-mini-cart {
			position: absolute;
			top: 30px;
			right: 12px;
		}

		.mini-cart-header {
			h3 {
				color: $dark;
				@include rem(22px);
				line-height: 140%;
				margin-bottom: 40px;
			}
		}

		.mini-cart-items {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.wrapper-items {
				flex: 1;
				display: flex;
				flex-direction: column;
				gap: 16px;
				overflow-y: auto;
				max-height: 54vh;

				@include break(tablet) {
					max-height: 40vh;
					gap: 24px;
				}
			}

			.mini-cart-item {
				max-height: 400px;
				display: flex;
				gap: 14px;

				.mini-cart-thumbnail {
					width: 114px;
					height: 114px;
					border-radius: 24px;
					overflow: hidden;

					@include break(tablet) {
						width: 90px;
						height: 90px;
					}

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				.mini-cart-info {
					flex: 1;

					@include break(tablet) {
						display: flex;
						flex-direction: column;
						justify-content: space-between;
					}

					.wrapper-title {
						display: flex;
						justify-content: space-between;

						@include break(tablet) {
							flex-direction: column;
						}
					}

					.mini-cart-item_title {
						@include rem(18px);
						@include font-black;
						line-height: 140%;

						@include break(tablet) {
							@include rem(16px);
						}
					}

					.mini-cart-quantity {
						display: flex;
						align-items: center;
						gap: 16px;
						margin-top: 15px;

						@include break(tablet) {
							@include rem(16px);
						}
					}

					.custom-quantity {
						display: flex;
						border: 1px solid $dark;
						border-radius: 40px;
						padding: 5px 11px;
						max-width: 130px;
						min-width: 130px;

						input[type="number"] {
							color: $dark;
							padding: 0;
							margin: 0;
							text-align: center;
							border-radius: 0;
							-moz-appearance: textfield;
							appearance: none;
							border: none;
							width: 100%;
							transition: all 0.4s;

							@include break(tablet) {
							}

							&::-webkit-outer-spin-button,
							&::-webkit-inner-spin-button {
								-webkit-appearance: none;
								margin: 0;
							}
						}

						button.decrement,
						button.increment {
							background: transparent;
							width: 50%;
							max-height: 26px;
							border: none;

							.icon {
								display: flex;
								align-items: center;
								justify-content: center;
								position: relative;
							}

							span {
								position: absolute;
								top: 50%;
								left: 50%;
								height: 2px;
								width: 16px;
								background-color: $dark;
								display: block;

								transform: translate(-50%, -50%);

								&:nth-child(2) {
									transform: translate(-50%, -50%) rotate(90deg);
								}
							}
						}
					}

					.product-remove {
						width: fit-content;

						a.remove {
							width: 100%;
							height: 100%;
							color: $dark !important;
							@include font-medium;
							@include rem(13px);
							line-height: 140%;
							position: relative;

							&::after {
								content: "";
								display: block;
								width: 0;
								height: 1px;
								position: absolute;
								bottom: 0;
								left: 0;
								background-color: $dark;
								transition: width 0.25s ease-out;
							}

							&:hover {
								background: transparent;

								&::after {
									width: 100%;
								}
							}
						}
					}

					.mini-cart-remove {
						@include rem(13px);
						line-height: 140%;
					}
				}
			}
		}

		.mini-cart-spotlights {
			position: relative;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 20px 0;
			text-align: center;

			@include break(tablet) {
				padding: 12px 0;
			}

			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: -40px;
				display: block;
				width: calc(100% + 80px);
				height: 100%;
				border-top: 1px solid $secondary-grey;
				border-bottom: 1px solid $secondary-grey;
				z-index: -1;
				background-color: $secondary-light-grey;
			}

			.spotlight {
				@include rem(13px);
				line-height: 140%;
				max-width: 25%;
				
				@include break(tablet) {
					max-width: 30%;
				}
			}
		}

		.mini-cart-subtotal {
			display: flex;
			justify-content: space-between;
			padding-top: 17px;
			margin-bottom: 12px;

			.subtotal {
				@include font-bold;
				@include rem(22px);
				line-height: 140%;

				@include break(tablet) {
					@include rem(18px);
				}
			}
		}

		.mini-cart-buttons {
			display: flex;
			flex-direction: column;
			gap: 10px;

			.disabled {
				cursor: not-allowed;
				color: $dark;
				border: $secondary-grey;
				background-color: $secondary-grey;
			}
			
			.btn-secondary {
				color: $primary-orange;
				
				&:hover {
					color: $white;
					border: 3px solid $primary-orange;
					background-color: $primary-orange;
				}
			}

			.asterisk {
				margin-top: 22px;
				text-align: center;
				@include rem(13px);
				line-height: 140%;
			}
		}

		.close-mini-cart {
			cursor: pointer;
			font-size: 20px;
			border: none;
			background: none;
		}
	}

	.wrapper-recommended-products {
		position: fixed;
		right: 543px;
		top: 0;
		width: 190px;
		height: 100%;
		padding: 40px 33px;
		background-color: $primary-yellow;
		z-index: 9999;
		display: block;
		transform: translateX(200%);
		opacity: 0;
		will-change: transform, opacity;

		@include break(tablet) {
			display: none;
			padding: 16px 0;
			padding-left: 25px;
		}

		&.hidden {
			visibility: hidden;
		}

		.title-h5 {
			text-align: center;
			@include font-medium;
			@include rem(16px);
			margin-top: 0;
			margin-bottom: $pad-med_mobile;

			@include break(tablet) {
				display: flex;
				text-align: left;
				margin-bottom: 12px;
			}
		}

		.card-recommended-products {
			padding: 10px;
			width: 100%;
			height: auto;
			display: flex;
			flex-direction: column;
			border-radius: 18px;
			background-color: $white;

			@include break(tablet) {
				flex-direction: row;
				min-width: 200px;
				max-height: 84px;
			}

			&:not(:last-child) {
				margin-bottom: $pad-xsmall;
			}

			.thumbnail {
				border-radius: 12px;
				overflow: hidden;

				@include break(tablet) {
					max-width: 64px;
					max-height: 64px;
				}

				img {
					object-fit: cover;
				}
			}

			.content {
				margin-top: 8px;

				@include break(tablet) {
					margin-top: 0;
					margin-left: 16px;
				}
			}

			.title-h4 {
				text-align: center;
				@include font-black;
				@include rem(14px);
				line-height: 140%;

				@include break(tablet) {
					text-align: left;
				}
			}

			.price {
				text-align: center;
				@include rem(14px);
				line-height: 120%;
				display: flex;
				flex-direction: column;

				@include break(tablet) {
					text-align: left;
					line-height: 100%;
				}
			}
		}
	}

	.filter {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.6);
		opacity: 0;
		will-change: opacity;
		transition: opacity 0.4s ease;
	}
}

// Spinner
.loading-spinner {
	width: 50px;
	height: 50px;
	border: 3px solid #f3f3f3;
	border-top: 3px solid $primary-orange;
	border-radius: 50%;
	animation: spin 1s linear infinite;
	margin: 20px auto;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.mini-cart-loading {
	opacity: 0.6;
	pointer-events: none;
}

.mini-cart-entering {
	visibility: visible;
}

.mini-cart-leaving {
	pointer-events: none;
}
