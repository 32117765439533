a {
	color: inherit;
	text-decoration: none;
	transition: all 0.3s;

	// &:hover {
	//   color: $primary-orange;
	// }
}

h1,
h2,
h3,
h4 {
	a {
		color: inherit;
		text-decoration: none;
	}
}

.ghost {
	height: 0 !important;
	padding: 0 !important;
	margin: 0 !important;
	border: none !important;
}

h1,
.title-h1 {
	@include font-black;
	@include rem(96px);
	line-height: 90%;
	margin: 0;

	@include break(small-screen) {
		@include rem(70px);
	}

	@include break(mobile) {
		@include rem(52px);
	}
}

h2,
.title-h2 {
	@include font-black;
	@include rem(56px);
	line-height: 100%;

	@include break(tablet) {
		@include rem(36px);
	}
}

h3,
.title-h3 {
	@include font-bold;
	@include rem(36px);
	line-height: 100%;

	@include break(tablet) {
		@include rem(26px);
	}
}

h4,
.title-h4 {
	@include font-bold;
	@include rem(26px);
	line-height: 140%;

	@include break(tablet) {
		@include rem(22px);
	}
}

h5,
.title-h5 {
	@include font-bold;
	@include rem(22px);
	line-height: 140%;

	@include break(tablet) {
		@include rem(20px);
	}
}

h6,
.title-h6 {
	@include font-bold;
	@include rem(20px);
	line-height: 140%;

	@include break(tablet) {
		@include rem(18px);
	}
}

.subtitle {
	@include font-bold;
	@include rem(36px);
	line-height: 140%;

	@include break(tablet) {
		@include rem(26px);
	}
}

.surtitle {
	width: fit-content;
	padding: 9px 10px;
	@include font-bold;
	@include rem(13px);
	line-height: 150%;
	text-transform: uppercase;
	color: $primary-orange;
	background-color: $primary-yellow;
	border-radius: 30px;
	margin-bottom: 20px;

	@include break(tablet) {
		margin-bottom: 18px;
	}
}

/* ==== Edito ==== */
.wp-edito {
	color: $dark;

	h3,
	h4,
	h5,
	h6 {
		margin-top: $pad-med;
		margin-bottom: 18px;

		@include break(tablet) {
			margin-top: $pad-med_mobile;
			margin-bottom: 16px;
		}
	}
}

p {
	a {
		text-decoration: underline;
		color: $primary-orange;
	}

	.paragraphe-1,
	.paragraphe-2,
	.paragraphe-3,
	.paragraphe-4,
	.paragraphe-5,
	.paragraphe-6 {
		color: $dark;
	}

	.paragraphe-1 {
        @include rem(36px);
		line-height: 140%;
        
        @include break(tablet) {
            @include rem(24px);
        }
	}
	.paragraphe-2 {
        @include rem(26px);
		line-height: 140%;

        @include break(tablet) {
            @include rem(20px);
        }
	}
	.paragraphe-3 {
        @include rem(20px);
		line-height: 140%;

        @include break(tablet) {
            @include rem(18px);
        }
	}
	.paragraphe-4 {
        @include rem(18px);
		line-height: 140%;

        @include break(tablet) {
            @include rem(16px);
        }
	}
	.paragraphe-5 {
        @include rem(16px);
        line-height: 140%;

        @include break(tablet) {
            @include rem(14px);
        }
	}
	.paragraphe-6 {
        @include rem(13px);
		line-height: 140%;
	}
}

b, strong {
	@include font-bold;
}