.module-19-key-features {
	&.type-tabs {
		height: 100%;

		.wrapper-texte {
			min-height: 820px;

			@include break(tablet) {
				min-height: auto;
			}
		}

		.wrapper-media {
			max-height: 820px;
		}
	}

	&.layout {
		&-imgTxt {
			.wrapper-media {
				order: 1;
			}

			.wrapper-texte {
				order: 2;
			}
		}

		&-txtImg {
			.wrapper-media {
				order: 2;

				@include break(tablet) {
					order: 1;
				}
			}

			.wrapper-texte {
				order: 1;
			}
		}
	}

	.wrapper-media {
		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}

	.wrapper-texte {
		padding: 80px;

		@include break(tablet) {
			padding: $pad-med_mobile 5%;
		}
	}

	.title-h2 {
		color: $primary-orange;
		margin-bottom: 70px;

		@include break(tablet) {
			margin-bottom: $pad-med_mobile;
		}
	}

	.wrapper-tabs {
		.key-tabs {
			padding: 24px 0;
			border-bottom: 1px solid $secondary-grey;
		}

		.btn-toggle {
			color: $dark;
			position: relative;
			@include font-bold;
			@include rem(22px);
			text-align: left;
			padding-left: 56px;
			line-height: 140%;
			background: none;
			border: none;
			cursor: pointer;
			transition: all 0.25s ease-out;

			@include break(tablet) {
				@include rem(20px);
				padding-left: 50px;
			}

			.icon,
			.text {
				cursor: pointer;
			}

			.icon {
				position: absolute;
				top: 0;
				left: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 36px;
				width: 36px;
				transition: transform 0.25s ease-out;

				@include break(tablet) {
					height: 30px;
					width: 30px;
				}
			}

			&.open {
				color: $primary-orange;
			}

			@include break(desktop-only) {
				&:hover {
					color: $primary-orange;
				}
			}
		}

		.toggle-answer {
			height: 0;
			opacity: 0;
			overflow: hidden;
			padding-top: 0;
			padding-left: 56px;
			transform: translateY(-10px);
			transition: transform 0.25s ease-out 0.25s, opacity 0.25s ease-out 0.25s;

			@include break(tablet) {
				padding-left: 50px;
			}

			> * {
				margin-top: 0;
			}
		}

		.toggle-answer.show {
			height: 100%;
			opacity: 1;
			padding-top: 12px;
			transform: translateY(0);
		}
	}
}
