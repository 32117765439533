.module-12-dealer-locator {
    padding: 60px 0;

    @include break(tablet) {
        padding: 35px 0;
    }

    .title-h2 {
        color: $primary-orange;
        margin-bottom: $pad-xsmall;

        @include break(tablet) {
            margin-bottom: 16px;
        }

        .name-det {
            display: none;
            margin: 0 15px;
            
            @include break(tablet) {
                margin: 0 10px;
            }
        }
    }

    .wrapper-texte {
        margin-bottom: $pad-section;

        @include break(tablet) {
            margin-bottom: $pad-med_mobile;
        }
    }

    .store {
        width: 100%;
    }

    #storepoint-container {
        border-top: 0 !important;

        #storepoint-search {
            @include break(tablet) {
                padding: 15px 0;
            }
        }

        #storepoint-search-bar-container {
            width: 25%;

            @include break(tablet) {
                width: 100%;
            }

            #storepoint-clear-search {
                z-index: 2;
            }
        }

        .mapboxgl-ctrl-geocoder{
            border-radius: 10px;
            border: 3px solid $primary-orange;
            background: $white;
            margin-bottom: 5px;
            width: 100%;
        }

        .mapboxgl-ctrl-geocoder input[type=text] {
            border: 0 !important;
        }

        .storepoint-btn,
        .storepoint-popup-website-btn {
            display: none;
        }
    }
}