.popup {
	&-localisation {
		position: fixed;
		bottom: 12px;
		left: 12px;
		display: flex;
		flex-direction: column;
		gap: 20px;
		align-items: center;
		width: calc(100% - 24px);
		padding: 28px 30px 28px 30px;
		background-color: $white;
		border-radius: 4px;
		opacity: 0;
		visibility: hidden;
		transform: translateY(40px);
		box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.12);
		z-index: 10;
		transition: all 0.25s ease-out;

		@include break(tablet) {
			bottom: 16px;
			left: 16px;
			width: calc(100% - 32px);
			padding: 24px 20px 15px 20px;
		}

		&.open {
			opacity: 1;
			visibility: visible;
			transform: translateY(0);

			@include break(tablet) {
				z-index: 1002;
			}
		}
		
		.wrapper-text {
			display: flex;
			align-items: center;

			.hide-desktop {
				@include break(desktop-only) {
					display: none;
				}
			}

			@include break(tablet) {
				flex-direction: column;
				align-items: flex-start;
				width: 100%;
				@include rem(20px);
				line-height: 160%;
			}

			.btn {
				height: 48px;
				padding: 10px 28px 8px 28px;
				border: 3px solid $primary-orange;
				margin-left: $pad-small;

				@include break(tablet) {
					height: 45px;
					padding: 8px 28px;
					margin-top: 26px;
					margin-left: 0;
					width: 100%;
				}
			}
		}

		.wrapper-text-2 {
			display: flex;
			margin-left: 5px;

			@include break(tablet) {
				margin: 14px 0;
			}
		}
		.wrapper-text-3 {
			display: flex;
		}

		.select_filter {
			display: inline;
			width: fit-content;
			position: relative;
			margin: 0 2px;
			border-bottom: 2px solid $primary-orange;

			.select_arrow {
				position: absolute;
				top: 50%;
				right: 0;
				-webkit-transform: translateY(-50%);
				transform: translateY(-50%);
				pointer-events: none;
			}
		}

		.styled-select {
			min-width: 143px;
			border: none;
			background-color: transparent;
			color: $primary-orange;
			cursor: pointer;
			padding: 0;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			position: relative;

			&::-ms-expand {
				display: none;
			}

			&::-ms-expand {
	        display: none;
	    }

	    // Ajout explicite pour le texte sélectionné
	    option {
	        color: black; // Couleur des options dans la liste
	    }

	    // Ajoutez cette règle pour cibler la couleur du texte de l'option sélectionnée
	    &:focus, &:hover, &:active, &:checked {
	        color: $primary-orange; // Couleur du texte sélectionné
	    }
		}

		.asterisk {
			@include font-light;
			@include rem(13px);
			line-height: 140%;
			text-align: center;
		}
		.btn-close {
			position: absolute;
			top: 0;
			right: 0;
			padding: 8px;
			border: none;
			background-color: transparent;

			svg {
				width: 22px;
				height: 22px;
			}
		}
	}

	&-alert {
		position: fixed;
		top: 0;
		left: 0;
		height: 33px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		padding: 6px 0;
		background-color: $primary-yellow;
		opacity: 0;
		display: none;
		z-index: 1000;
		transition: opacity 0.25s ease-out;

		@include break(tablet) {
			height: 45px;
			padding: 9px 60px;
		}

		&.open {
			opacity: 1;
			display: block;
		}

		.wrapper-text {
			text-align: center;
			@include rem(14px);
			line-height: 150%;
			text-transform: uppercase;

			@include break(tablet) {
				@include rem(12px);
				line-height: 120%;
			}

		}

		.btn-close {
			position: absolute;
			top: 50%;
			right: 14px;
			padding: 0;
			max-width: 19px;
			max-height: 19px;
			transform: translateY(-50%);
			border: none;
			background-color: transparent;

			@include break(tablet) {
				@include rem(12px);
				line-height: 120%;
			}

			svg {
				max-width: 19px;
				max-height: 19px;
				width: 19px;
				height: 19px;
			}
		}
	}
}
