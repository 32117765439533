.module-15-contact-form {
    padding: 60px 0;

    @include break(tablet) {
        padding: 35px 0;
    }

    .row {
        justify-content: center;
    }

    .wrapper-texte {
        margin-bottom: 48px;
    }

    .title-h2 {
        margin-bottom: 12px;
    }
}