.template-account {
    .col-media {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .col-content {
        padding: 210px 5% 80px 75px;

        @include break(tablet) {
            padding: $pad-large_mobile 5%;
        }

        .title-h1 {
            @include font-bold;
            @include rem(36px);
            line-height: 100%;
            margin-bottom: 12px;
        }

        .description {
            @include rem(16px);
            line-height: 140%;
            margin-bottom: 36px;
        }
    }

    .woocommerce {
        width: 100%;
    }

    .woocommerce-notices-wrapper {
        bottom: 5vh;

        .woocommerce-error {
            position: relative;
            display: flex;
            flex-direction: column;
            padding: 10px 45px;
            border-top: none;
            background-color: $white;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
            border-radius: 8px;
            @include rem(18px);
    
            &::before {
                top: 10px;
                left: 15px;
                color: $primary-orange;
            }

            a {
                text-decoration: underline;
            }
        }
    }

    .woocommerce-password-container {
        .btn.return {
            margin-top: $pad-med;
        }
    }
}